export const colors = {
  white: "#FFFFFF",
  black: "#000",
  baby_black:"rgba(84, 90, 98, 1)",
  light_black: "#1E1E1E",
  dark_black:"#000814",
  blue: "#2D317F",
  dark_blue: "#081F70",
  navy_blue: "#040921",
  light_grey: "#ebebed",
  dark_grey: "#EBEBED",
  v_dark_grey: "#4D4D4D",
  c_dark_grey: "#545A62",
  orange: "#F18222",
  yellow: "#FCB62E",
  violet: "#47184E",
  petroli: "#18374E",
  dark_red: "#AF3F3E",
  dark_violet: "#754F9B",
  green: "#ABB560",
  light_green: "#A0CE64",
  dark_green: "#669578",
  lemon_green: "#008635",
  dark_petroli: "#5687A7",
  light_petroli: "#69B8BE",
  dark_brown: "#B87F5B",
  light_brown: "#D07E5A",
  ratty: "#6B6B6B",
  manhattan_blue: "#404456",
  white_smoke: "#F5F5F6",
  bg_grey: "#f8f8f8",
  dark_charcoal: "#333",
  bastille: "#323234",
  light_biege: " rgba(254, 131, 38, 0.05)",
  light_baby_grey: " #545A62",
  light_baby_white: " #FAFAFA",
  dark_white:"#F8FAFC",
  baby_white: "#EFF0F5",
};
export const APIS = {
  baseURL: process.env.NEXT_PUBLIC_BE_BASEURL,
};
export const EnvKey = {
  secretKey: process.env.NEXT_PUBLIC_SECRET_KEY_Token,
};
//
export const navigation = {
  router: null,
};
