import { cifAe, cifEg } from "@coreui/icons";
import { colors } from "../src/utils/const";
import { userAgent } from "@/utils/generalUrils";
import { Box } from "@mui/material";
export default {
  terms: "https://contact.eg/terms-and-conditions-en.pdf",
  offer_expiry_date: "Expiry Date",
  offer_details: "Details",
  contact: "Contact",
  offers: "Offers",
  // ** CONTACT
  brand_description:
    "Contact Financial Holding SAE (CNFN.CA) is the largest non-bank financial service provider in Egypt, changing the way people and corporations access finance and insurance. Operating since 2001, Contact adopts innovative approaches in extending its services, offering quality services with simple procedures and reaching a wide client base through its various subsidiaries, affiliates, and partners. Contact’s financing division offers market-leading services including consumer financing for new and used passenger and commercial vehicles and an array of consumer durables through Contact Credit and Contact CrediTech, Mortgage finance through Contact Mortgage, as well as commercial finance through Contact Leasing and Contact Factoring. Contact operates in insurance through Sarwa Insurance and Sarwa Life Insurance. Contact also offers an array of corporate financing services including securitization, structured debt, and debt investment management. Contact Financial Holding SAE is authorized and regulated by the Financial Regulatory Authority (FRA).",
  contact_supervision:
    "Under the supervision of the Financial Regulatory Authority (FRA) license number 756, Date 18/04/2018",
  // ** form page

  partner_types: [
    { id: "1", title: "ContactNow Merchant" },
    { id: "2", title: " Medical Supplier" },
    { id: "3", title: "Vehicles & Transportation Vendor" },
    { id: "4", title: " Other Entities" },
  ],
  //  Categories
  CategoriesForm1: [
    "Appliances & Electronics",
    "Travel & Entertainment",
    "Automotive",
    "Appliances",
    "Mobiles and Tables",
    "Home & Furniture",
    "Fashion",
    // "Beauty",
    "Jewelry",
    "Health",
    "Beauty",
    "Family & Pets",
    "Education & Learning",
    "Services",
    "Sports & Well-Being",
    "Supermarkets & Hypermarkets",
    "Food & Beverage",
    "Home",
    "Department Stores & Marketplaces",
    "Gifts",
    "Businesses",
  ],
  POSform1: [
    "Paymob",
    "Paytabs",
    "Fawry",
    "Geidea",
    "Kashier",
    "Network International (NI)",
    "Other",
  ],
  form_title1: "ContactNow Merchant",
  form_sub1: "More Customers, Faster Turnarounds",
  form_des1:
    " With an intimate understanding of the Egyptian market and a professional, experienced and hardworking team, we’ll help you unlock your business’s true potential and provide you with access to ready, willing,and vetted customers. And our simple process and comprehensive plans will help you close more deals and move more of your products to the market.",
  form_title2: "Medical Supplier",
  form_sub2: "",
  form_des2:
    "Join our medical equipment supplies network and provide your customers with flexibility of payment through the easiest and fastest financing plans from Contact.",
  form_title3: "Vehicles and Transportation Vendor",
  form_sub3: "",
  form_des3:
    "Be part of our transportation vendors for passenger vehicles, commercial vehicles (light, medium, heavy trucks) busses, and vans. Attract more customers by offering flexible and convenient payment plans with Contact. ",
  form_title4: "Other Entities",
  form_sub4: "",
  form_des4:
    "List your business with Contact — If you are offering any other products or services and you want to offer your customers and potential buyers the ease and convenience of payment plans and financing options from Contact, please fill the form and a representative will contact you. ",
  yes: "Yes",
  no: "No",
  have_record: "Do you have commercial register and tax id?",
  company_nameForm1: "Company / Organization name",
  company_nameForm3: "Business name",
  company_nameForm2: " Business name",
  full_name: "Full name of contact person",
  // client_name: "Client Name",
  business_name: "Business name",
  specialist_name: "Contact person name",
  phone: "Phone number",
  email: "Email",
  categories: "Categories",
  url: " Site or Facebook link",
  address: "Detailed Address",
  governorate: "Governorate",
  branchesNumber: "Number of branches ",
  employees_number: "Number of employee ",
  field: "Business industry",
  digital: "Ecommerce",
  POS: "Point of sale (P.O.S)",
  send: "Submit",
  // ** ----- extra -----
  plans: "Installment Plans",
  popup: (
    <>
      <p style={{ marginBottom: "6px" }}>Go beyond the borders with Contact!</p>
      <p>Buy in Egypt, and pay from UAE</p>
    </>
  ),
  contact_now_btn: "Your installments and points",
  last_offers: "Latest Offers",
  exclusive: "Exclusive For You",
  // ** ---------
  join_us: "More Customers, Faster Turnarounds",
  // more_clients: "More customers, faster turnarounds",
  join_us_des:
    "With an intimate understanding of the Egyptian market and a professional, experienced, and hardworking team, we’ll help you unlock your business true potentials. Our simple process and comprehensive plans will help you close deals and access more customer segments.",
  join_us_btn: "join us now",
  // ** ---------
  fatorty_title: "Fatorty",
  fatorty_des:
    "Installment for any recent bill you have. All you will need is to download the application and then choose the appropriate payment periods for you, because my bill offers you the best flexible payment methods for your bills.",
  fatorty_btn: "Get Instant Limit !",
  // ** ---------
  //^ income management
  incomeManagement: {
    fisrt_section: {
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/cash-mangement.png",
      title: "Fixed Income Portfolio Management",
      subTitle: "Your Trusted Partner in Egypt's Debt Capital Markets (DCM)",
      pg: `Contact has a long and successful history in Egypt's Debt Capital Markets (DCM), making us the leading player in the industry. We leverage our extensive experience to offer a comprehensive suite of structured financing solutions, helping businesses thrive and achieve their financial goals.  
      
Our DCM services include securitization, Sukuk issuance, promotion and underwriting. Throughout our DCM services, we prioritize portfolio value protection against excessive market fluctuations, ensure adequate liquidity levels, diversify instrument structure, debt originator, and duration, and achieve competitive, optimized returns through active management strategies.`,
    },
    second_section: {
      img: "/images/benefits.png",
      title: "Milestones",
      pg: `Contact has been a pioneer and a market leader in the DCM space in Egypt since 2005. We have arranged and issued more than EGP 26 billion in debt issuances, including the very first securitization bond offering of its kind on the domestic market. We are also the first licensed sukuk issuer in the Egyptian market, offering Sharia-compliant financing solutions.
     
     Contact has also issued the first securitization bond backed by real-estate receivables ¬in the market in 2015, managed to be the leading issuer of Sukuk al-Mudarabah in 2021. Contact also issued the first Consumer Finance bond in 2022 with the highest portfolio rating in the market.`,
    },
    third_section: {
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/3.png",
      title:
        "By Choosing Contact As Your DCM Financial Partner, You Will Benefit From",
      list: [
        "Our extensive experience and expertise in the DCM field.",
        "Our strong relationships with regulators, rating agencies, investors, and other market participants.",
        "Our innovative and customized solutions that suit your unique needs.",
        "Our competitive pricing and flexible payment options.",
        "Our professional and dedicated team that will guide you throughout the entire process.",
      ],
    },
    outro:
      "Contact us today to find out how we can help you with your DCM inquiries and needs.  ",
  },

  //^ income trading
  incomeTrading: {
    fisrt_section: {
      title: "Fixed Income Instrument",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/1.png",
    },
    second_section: {
      first_title: "Securitization",
      fisrt_subtitle: "Experience Matters",
      fisrt_pg: `Securitization is one of the widely commonly structured debt security issues with the purpose of transferring receivables to an SPV as collateral for third party investment. Choosing securitization as a structure has multiple advantages such as enhancing credit rating of underlying asset, Tax savings, Lower cost of capital, off-balance sheet financing and Source of liquidity.

Experience Matters: Since 2005, Contact has been a trusted partner in securitization in Egypt, guiding partners and clients through every step of the process. With extensive experience and a deep understanding of the market, Contact tailors solutions to your specific needs and navigates the complexities of securitization to achieve your financial goals.`,

      second_title: "Sukuk",
      second_subtitle: "Compliant with Shariah Principles",
      second_pg: `Sukuk are an asset-based security that represent aggregate and undivided shares of ownership and/or rights in a tangible asset as it relates to a specific project or a specific investment activity with Off-balance sheet advantage via SPV and Long duration. 

Hybrid instruments that share features that can be more debt-like or equity-like, depending on the structure.

Potential for a large and diverse investor base with highly flexible structures including: (Mudarabah, Musharaka, Ijara and Murabaha)

We offer comprehensive Sukuk Issuance Services in Egypt to help you navigate the process to ensure your needs are met from start to finish.`,

      //       second_pg: `Sukuk are an asset-based security that represent aggregate and undivided shares of ownership and/or rights in a tangible asset as it relates to a specific project or a specific investment activity with Off-balance sheet advantage via SPV and Long duration.

      // Hybrid instruments that share features that can be more debt-like or equity-like, depending on the structure.

      // Potential for a large and diverse investor base with highly flexible structures including: (Mudarabah, Musharaka, Ijara and Murabaha)

      // We offer comprehensive Sukuk Issuance Services in Egypt to help you navigate the process to ensure your needs are met from start to finish.`,
    },
  },

  t_s1_img:
    "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/1.png",
  t_s1_t: "Fixed Income Instrument",
  t_s2_t_1: "Sukuk",
  t_s2_b_1: `Sukuk are an asset-based security that represent aggregate and undivided shares of ownership and/or rights in a tangible asset as it relates to a specific project or a specific investment activity with Off-balance sheet advantage via SPV and Long duration. 

Hybrid instruments that share features that can be more debt-like or equity-like, depending on the structure.

Potential for a large and diverse investor base with highly flexible structures including: (Mudarabah, Musharaka, Ijara and Murabaha)`,
  t_s2_t_2: "Securitization",
  t_s2_b_2: `Securitization is one of the widely commonly structured debt security issues with the purpose of transferring receivables to an SPV as collateral for third party investment. Choosing securitization as a structure has multiple advantages such as enhancing credit rating of underlying asset, Tax savings, Lower cost of capital, off-balance sheet financing and Source of liquidity.`,

  //^ investment fund
  KolYom: {
    fisrt_section: {
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/investment-fund.png",
      title: "Contact Kol Yom",
      subtitle: "Your easy and safe investment",
      pg: `Kol Youm is a savings fund from Contact that offers your business a unique opportunity to achieve consistent and secure financial growth. We provide a simple, accessible, and rewarding investment platform designed to empower your financial aspirations.

Kol Yom leverages a diversified investment portfolio, ensuring steady returns while safeguarding your capital and liquidity. We offer a comprehensive solution, combining effortless enrollment, complete transparency with zero fees, unparalleled flexibility for deposits and withdrawals. Additionally, our dedicated support team is always available to assist you with any questions, ensuring a smooth and successful investment experience.`,
    },
    second_section: {
      title: "Benefits Of Kol Yom",
      list: [
        "Cumulative daily returns",
        "Easy and secure investment",
        "Without any fees",
        "Daily Deposit and withdrawal",
        "Bank account is not a must",
        "Fastest and easiest procedures",
        "Easy and diversified transaction methods",
        "A call center team for any help",
      ],
    },
    outro: `By partnering with Kol Yom, you invest in your business's future growth and stability. Join many other entities experiencing the power of daily financial and start your journey to financial success today.`,
  },
  f_s1_img:
    "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/investment-fund.png",
  f_s1_t: "Contact Kol Yom saving and investment Fund.",
  f_s1_sb: "Grow your wealth.",
  f_s1_b:
    "Kol Yom Money Market Mutual Fund is the perfect way to grow your wealth. Get access to a variety of investment options with small capital and lucrative returns. It is the perfect way to diversify your investment portfolio and achieve your financial goals.",
  f_s2_img: "/images/benefits.png",
  f_s2_t: "Benefits",
  f_s2_b: [
    "Cumulative daily returns",
    "Easy and secure investment",
    "Without any fees",
    "Daily Deposit and withdrawal",
    "Bank account is not a must",
    "Fastest and easiest procedures only with your National ID",
    "Easy and diversified transaction methods",
    "A call center team for any help",
  ],
  // ** ---------

  download: "Download the app",
  direction: "ltr",
  textAlign: "left",
  contact_us_title: "We Would Like To Hear From You!",
  formMsg: "Form Submitted Successfully",
  formMsgFail: "Form submission Failed",
  clipboardMsg: "Link copied successfully",
  subscribeMsg: "Subscription done successfully",
  subscribeFailMsg: "Subscription failed",
  insurance_companies_title: "Corporate Insurance Products Sectors",
  insurance_individual_title: "Insurance Services for individuals",
  insuranceFeatures_companies: [
    "Motor and fleet insurance",
    "Liability and causality",
    "property insurance",
    "Engineering insurance",
    "Marine & inland cargo insurance",
    "Medical insurance",
    "Saving and investment",
  ],
  insuranceFeatures_individual: [
    "Credit risk insurance",
    "Personal accidents insurance",
    "Cash in transit insurance (armed robbery)",
    "Contractors’ all-risks insurance",
    "Hull insurance",
    "Contractors’ inland equipment insurance",
    "Installation all risks insurance",
    "Food spoilage insurance",
    "Glass breakage insurance",
    "Loss of income insurance",
    "Liability insurance",
    "Transport insurance",
    "Livestock (cattle and poultry) insurance",
    "Trade credit insurance",
    "Cash in transit and fidelity guaranty insurance",
    "Customs surety insurance",
    "Fire and allied perils insurance",
    "Electronic equipment and devices malfunction insurance",
    "Other customized coverages according to your business",
  ],
  calcTitle: "Calculate Your Installments",
  purpose: "Financing Purpose",

  calc: {
    monthlyAmount: "The monthly amount",
    priceCar: "Financing Amount ",
    preAmount: "Down-payment",
    plan: "Plan",
    purpose: "Financing Purpose",
    egyptianPound: "EGP",
    duration: "Duration",
    month6: "6 month",
    year1: "1 Year",
    year2: "2 Year",
    year3: "3 Year",
    year4: "4 Year",
    year5: "5 Year",
    year6: "6 Year",
    year7: "7 Year",
    year8: "8 Year",
    year9: "9 Year",
    year10: "10 Year",
    plan1: "Economic Programme",
    plan2: "Flexible Program",
  },
  calc_packages: {
    auto: [
      { packageId: 268, title: "Flexible PLUS" },
      { packageId: 114, title: "Economy" },
      { packageId: 262, title: "Economy plus" },
      { packageId: 144, title: "Used" },
    ],
    trucks: [
      { packageId: 247, title: "Flexible" },
      { packageId: 234, title: "Standard" },
    ],
    consumer_finance: [{ packageId: 408, title: "Creditech" }],
    mortgage: [
      { packageId: 179, title: "Home Equity  - Economy" },
      { packageId: 180, title: "Home Equity  - Flexible" },
      { packageId: 336, title: "Home Finance" },
    ],
    clubs: [{ packageId: 452, title: "Clubs Membership" }],
    education: [{ packageId: 363, title: "Education" }],
    insurance: [{ packageId: 402, title: "Insurance" }],
    wedding: [{ packageId: 437, title: "Wedding" }],
    maintenance: [{ packageId: 439, title: "Home Maintenance" }],
    green_finance: [
      {
        packageId: 459,
        title: "Green Finance B (Quarterly)",
        packageId: 458,
        title: "Green Finance A (Monthly)",
      },
    ],
    home_interior: [{ packageId: 354, title: "Home Interior" }],
    watercrafts: [{ packageId: 465, title: "Watercraft " }],
    motorcycles: [{ packageId: 469, title: "Motorcycles" }],
    home_furniture: [{ packageId: 460, title: "Home Furniture" }],
    manasik: [
      {
        packageId: process.env.NEXT_PUBLIC_MODE === "STAGING" ? 501 : 526,
        title: "Umrah",
      },
      {
        packageId: process.env.NEXT_PUBLIC_MODE === "STAGING" ? 500 : 525,
        title: "Hajj",
      },
    ],
  },
  // our_platforms: "Our Company",
  about: {
    members: {
      directors: "Board of Directors",
      mangers: "Executive Management",
      title: "Board of directors ",
      subtitle:
        "We are always looking for enthusiastic, dynamic and talented individuals.",
    },
    aboutTitle: "About",
    aboutText:
      "Contact Financial Holding is a leading provider of finance solutions, including auto credit, insurance, consumer, and corporate finance, and is the largest player in Egypt’s nascent debt capital markets, helping structure, arrange, and underwriting debt transactions for companies that need to access debt capital markets to fund growth, manage risk, improve financial standing and raise liquidity.",
    aboutCard: {
      heading1: "Our Expertise",
      text1:
        "At Contact Financial Holding, we use our expertise in deploying funds and generating liquidity to develop financial solutions for our clients, seeking out and leveraging opportunities in underdeveloped financial segments, resulting in increased financial inclusion. We offer a range of consumer- and corporate-focused products and services under our brands in addition to producing white-labeled solutions for major Egyptian corporations.",
      heading2: "Work Team",
      text2:
        "Our talented team of more than 2,500 employees is a key pillar to Contact’s success. To maintain our position at the leading edge, we constantly invest in our people, trusting that our development as a company comes hand in hand with their own.",
      heading3: "Financing Solutions",
      text3:
        "Contact works based on a collaborative business model, engaging with business partners at various levels to deliver market-leading products and services. We always strive to provide clients and business partners with the highest quality service and efficiency, making the financing process seamless and achieving the best possible results. In addition to Contact’s 75+ locations nationwide, our growing network includes more than 10,000 points of sale covering car dealers, consumer durables merchants, and sporting clubs.",
    },
    title: "Emphasize what is important to your company.",
    text: "We always seek to employ our extensive experience in managing financial investments and providing financing in developing advanced financial solutions that suit the needs of our customers.",

    card: {
      vision_mission: [
        {
          id: "1",
          title: "Our Vision",
          text: [
            "Our vision is to be the most innovative financial services provider of choice. We provide our customers with access to the latest fintech, products and services. Our goal is to make sure that everyone can take advantage of our innovative solutions and maximize their financial potential. We strive for excellence in everything we do and are committed to providing a first-class customer experience.",
          ],
          img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/eye.png",
        },
        {
          id: "2",
          title: "Our Mission",
          text: [
            "We provide innovative digital financial services through a customer-centric approach, capitalizing on our strong relationship and commitment with our customers. Through our highly skilled experts, we offer a one-stop-shop customer experience by adopting the latest top-notch technologies and providing exceptional customer service. Our high-end programs not only serve individuals but also corporates, aiming to fulfill all their financial goals.",
          ],
          img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/mission.png",
        },
        {
          id: "3",
          title: "Our Values",
          text: [
            "Customer Satisfaction: Our long-term customer relationship is where our core values lie. An approach that ensures outstanding customer experience through a smooth customer journey.",
            "Integrity: We conduct ourselves with uncompromising integrity and honesty as individuals, teams, and Companies. We strive to earn enduring credibility with others, which we believe is essential to long term personnel and business relationships.",
            "Loyalty: The turnover rate is largely due to the group members’ feeling of belonging to a work team that values humans among individuals. In addition to continuous professional support, we do everything possible to help and support each other on a personal & professional level, and our feeling of belonging to the group is reflected in the consolidation of loyalty among our customers.",
            "Empowering Dreams: We inspire our employees and customers to dream and mobilize themselves towards those dreams, to be able to develop themselves and change the world.",
            "Accountability: We believe that everybody is accountable for their own welfare and that of others, and for resources.",
          ],
          img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/value.png",
        },
      ],
    },
    statistic: [
      { id: "1", sufTheous: " K", text: "Customers", count: 150000 },
      { id: "2", sufTheous: " K", text: "Transactions", count: 360000 },
      { id: "3", sufTheous: "", text: "Employees", count: 2500 },
    ],
  },
  sustainability: {
    sustainabilityTitle: "Sustainability in",
    sustainabilityText:
      "At Contact, sustainability is one of our main concerns; we allocate all the available resources to protect our environment, sustainable development, and social solidarity, such as low-income support programs, women empowerment, and enabling people with disabilities. The urge to support sustainability comes from our responsibility towards our community, as most firms and financial corporations prioritize short-term gains over long-term sustainability.",
    sustainabilityText2:
      "We focus on integrating sustainability in our decision-making processes through various ways such as green finance, green bonds, sustainable investment practices, corporate social responsibility, and following governmental policies that stimulate sustainable development investments, economic efficiency, and environmental protection.",
    sustainabilityList: [
      "Cooperation agreement to provide support for women fighting breast cancer.",
      "Celebrating Children's Day by visiting two branches of Orman Foundation for Childcare.",
      "Contact celebrates Sanaweya Ama top-ranking students.",
      "Celebrating International Women's Day and supporting women empowerment.",
      "Packing Ramadan boxes in cooperation with Misr El Kheir Foundation.",
    ],
  },
  investments: {
    investmentsTitle1: "Contact Kol",
    investmentsTitle2: "Yom saving and investment fund.",
    investmentsText: `Your easy and safe investment
    If you are looking for a safe and easy investment, Contact Kol Yom saving and investment mutual fund is offering you daily subscriptions and redemptions with the best returns. 
    `,
    investmentsList_title: "Benefits",
    investmentsList: [
      {
        pointTitle: "Daily Dividends:",
        pointDetails: " Watch your money flourish with daily interest accrual ",
      },
      {
        pointTitle: "Effortless Investment:",
        pointDetails:
          " Simple procedures, only with your National ID, no bank account needed! ",
      },
      {
        pointTitle: "Zero Fees:",
        pointDetails: " Transparent, fee-free investing ",
      },
      {
        pointTitle: "Unmatched Flexibility:",
        pointDetails:
          " Deposit and withdraw funds anytime, without restrictions ",
      },
      {
        pointTitle: "Seamless Convenience:",
        pointDetails: " Manage your investments easily ",
      },
      {
        pointTitle: "Experts Support:",
        pointDetails:
          " Our dedicated team is always ready to help with any questions through 16177 and 75 branches all over Egypt ",
      },
    ],
  },
  rewards: {
    heading: "Customer Referral Program Points",
    subHeading:
      "In the event that an existing customer nominates a new customer, points will be calculated",
    title: "How Points Are Calculated",
    note: "Note: ",
    noteText:
      "Policy amount must be more than 5000 EGP to start calculating the points",
    pointsCard: [
      {
        id: "1",
        title: "Contact Shopping",
        headingKey: "Transaction type",
        headingVal: "Points	",
        subTitle:
          "You are awarded points for each [Egyptian Pound] you spend in purchases, rounded down to the nearest point.",
        purchaseKey: "Purchase",
        purchaseVal: "1 point for every 1 EGP	",
        firstTreatmentKey:
          "1st Purchase transaction within 2 months of subscription",
        firstTreatmentSubKey: "*Minimum transaction amount 10000 EGP",
        firstTreatmentVal: "3000 Points	",
        lastTreatmentKey: "5th Purchase transaction",
        lastTreatmentSubKey: "*Minimum transaction amount 10000 EGP",
        lastTreatmentVal: "2000 Points",
      },
      {
        id: "2",
        title: "Contact Finishing",
        headingKey: "Transaction type",
        headingVal: "Points	",
        subTitle:
          "You are awarded points for each [Egyptian Pound] you spend in purchases, rounded down to the nearest point.",
        purchaseKey: "Purchase",
        purchaseVal: "0.5 Points for every 1 EGP	",
        firstTreatmentKey:
          "1st Purchase transaction within 2 months of subscription",
        firstTreatmentSubKey: "*Minimum transaction amount 20000 EGP",
        firstTreatmentVal: "3000 Points",
        lastTreatmentKey: "5th Purchase transaction",
        lastTreatmentSubKey: "*Minimum transaction amount 20000 EGP",
        lastTreatmentVal: "2000 Points",
      },
    ],
    card: [
      { title: "Contact Auto Finance", count: 40000 },
      { title: "Contact Transportation Finance", count: 30000 },
      { title: "Real Estate Shopping Contact", count: 25000 },
      { title: "Contact Finishing Financing", count: 20000 },
      { title: "Real Estate Financing Contact", count: 20000 },
    ],
  },
  general: {
    gifs: { hi: "/gif/hi-en.gif", calculationg: "/gif/calculating-en.gif" },
    popup: "/popup-en.png",
    menu: {
      about_us: "About Us",
      media: "Media and News",
      careers: "Careers",
      contact_uae: "Contact UAE",
      our_brand: "Our Brands",
      contact_us: "Contact Us",
      investor_relations: "Investor Relations",
      sustainability: "Sustainability",
      why_contact: "Why Contact ?",
    },
    reviewTitle: "Customers Review",
    reviewSubTitle: "What do customers say about us?",
    reviews_individuals: [
      {
        id: "1",
        name: "Mohamed Diab",
        // role: "30 December 2022",
        avatar: "/male-avatar.svg",
        // rating: 5,
        // review: "Excellent application and very useful and easy to use.",
        review: 5,
        rating: "Excellent application and very useful and easy to use.",
      },
      {
        id: "2",
        name: "Martina Ibrahem",
        // role: "3 January 2023",
        avatar: "/female-avatar.png",
        review: 5,
        rating: "Excellent application and very easy to use.",
      },
      {
        id: "3",
        name: "Mena Alber",
        // role: "15 January 2023",
        avatar: "/male-avatar.svg",
        review: 5,
        rating: "Useful application, easy and fast.",
      },
      {
        id: "4",
        name: "Aziz Mohamed Salah",
        // role: "31 December 2022",
        avatar: "/male-avatar.svg",
        review: 5,
        rating:
          "All new app, all new features, and easy to use. Thanks for the great effort.",
      },
      {
        id: "5",
        name: "Mo Abwahab",
        // role: "30 December 2022",
        avatar: "/male-avatar.svg",
        review: 5,
        rating:
          "Useful. It gives us a great experience. Thanks for the new features.",
      },
    ],
    directors: [
      {
        id: 1,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mr.Hazem-Mousa.jpg",
        name: "Hazem Moussa",
        nameWithMr: "Mr. Hazem Moussa",
        role: "Chairman of Contact Financial Holding",
        details: `Hazem Moussa is the co-founder and Chairman of Contact Financial Holding, Egypt’s leading non-bank financial services provider.

        With a strong vision and dedicated leadership, Hazem has played an instrumental role in building out the company from its beginnings in 2001 as a one-product consumer lending business into a thriving business encompassing a suite of consumer and commercial financial services delivered physically and digitally to a wide range of Egyptians.
        
        In addition to being the largest consumer financing business in Egypt, Contact also has pioneering businesses in insurance, technology, and the debt capital markets.  Leading a strong team of dedicated Egyptian professionals with a range of experience, Hazem led the business as General Manager then Chief Executive Officer for 18 years before focusing on strategy & development as Chairman.

        Hazem holds a bachelor’s degree in economics from Hamilton University, New York.`,
      },
      {
        id: 2,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mr.Said-Zater.jpg",
        name: "Said Zater",
        nameWithMr: "Mr. Said Zater",
        role: "MD of Contact Financial Holding & Group CEO",
        details: `Said Zater is a distinguished business leader boasting a significant corporate journey in the realm of non-banking financial services since 2001. 
        
        Presently serving as the Managing Director and Chief Executive Officer of Contact Financial Holding, he concurrently holds the esteemed role of Chairman of the Egyptian Federation of Consumer Finance. 
        
        With an illustrious background including strategic leadership positions within the Ministry of Interior, Zater's non-banking financial services career commenced at Contact, where his impactful contributions span strategic planning, operations, cost analysis, and the implementation of management consultancy approaches to enhance company performance, market reach, and revenue. 
        
        Notably, Zater has successfully guided the company to heightened operational efficiency and brand expansion through an IPO. What adds a remarkable dimension to Zater's leadership is the noteworthy recognition Contact has received under his guidance from Forbes Middle East itself. 
        
        The company secured its position and distinction by being listed among the top 50 listed companies in Egypt for two consecutive years, a testament to its consistent growth and significant presence in the industry. The company's outstanding achievements under his leadership were also honored by The Global Economics Magazine, bestowing accolades for Fastest Growing Finance Company and Best Digital Transformation FINTECH Company. 
        
        The seasoned operations management expert's extensive expertise encompasses strategic and risk management, growth strategies, crisis handling, and government relations.`,
      },
      {
        id: 3,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mr-Amr-Lamei.jpg",
        name: "Amr Lamei",
        role: "Deputy Chairman",
      },
      {
        id: 4,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ashraf-Zaki.jpg",
        name: "Ashraf Zaki",
        role: "Board Member",
      },
      {
        id: 5,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mohamed-Abdel-Halim.jpg",
        name: "Mohamed Abdel Halim",
        role: "Board Member",
      },
      {
        id: 6,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mohamed-Sadek.jpg",
        name: "Mohamed Sadek",
        role: "Board Member",
      },
      {
        id: 7,
        img: "/directors/man.jpeg",
        name: "Nils Backtler",
        role: "Board Member",
      },
      {
        id: 8,
        img: "/directors/man.jpeg",
        name: "Amr Esmat Abaza",
        role: "Board Member",
      },
      {
        id: 9,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Karim-Hussein.jpg",
        name: "Karim Hussein",
        role: "Independent Board Member",
      },
      {
        id: 10,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Karim-Zein.jpg",
        name: "Karim Zein",
        role: "Independent Board Member",
      },
      {
        id: 11,
        img: "/managers/safaya.jpg",
        name: "Safaya Borhan",
        role: "Board Member",
        details: `Safeya Borhan is a distinguished figure in the field of Capital Markets, specializing in Corporate Affairs and Governance. With a wealth of experience and a strong foundation in good governance principles spanning diverse sectors, Safeya has accumulated over 17 years of expertise in the non-banking financial services industry. Presently, she holds the position of Director of Corporate Affairs, Governance, and Sustainability at Contact Financial Holding, where she also serves as the Chief of the Sustainability Development Committee.
       
        In 2021, Safeya achieved a significant milestone by being promoted to the executive board member role at Contact Financial Holding, Sarwa Insurance, and Sarwa Life Insurance Companies. Her impactful contributions extend to her role as a key initiator of the Egyptian Consumer Finance Law submitted to the Financial Regulatory Authority in Egypt from 2018 to 2020. During this time, she played a pivotal role in the establishment of the Egyptian Consumer Finance Federation, where she served as the Federation Secretary General.
        
        Safeya's accomplishments further include securing and managing licenses for over 20 corporate entities, including Contact Credit (2020), Sarwa Sukuk (2019), Sarwa Capital Holding (2018), Sarwa Insurance (2018), Sarwa Life Insurance (2018), Contact Factoring (2016), Contact Mortgage Finance (2015), Contact Insurance Brokerage (2015), Sarwa Promotion and Underwriting (2015), and Sarwa Asset Management (2013).
        
        One of her notable achievements includes orchestrating the successful launch of Sarwa Capital's IPO through meticulous strategic planning and execution in 2018. Safeya possesses a versatile set of interpersonal skills, encompassing self-development, self-awareness, and adaptability. She has a proven track record of nurturing the professional growth of staff members by providing comprehensive support and inspirational leadership in an open and motivating work environment.
        
        Safeya holds a DBA (Doctor of Business Administration) and an MBA (Master of Business Administration) from ESLSCA University in Egypt, in addition to other certifications in Corporate Governance.`,
      },
      {
        id: 12,
        img: "/directors/woman.png",
        name: "Zeinab Hashem",
        role: "Independent Board Member",
      },
    ],
    mangers: [
      {
        id: 13,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mr.Hazem-Mousa.jpg",
        name: "Hazem Moussa",
        nameWithMr: "Mr. Hazem Moussa",
        role: "Chairman of Contact Financial Holding",
        details: `Hazem Moussa is the co-founder and Chairman of Contact Financial Holding, Egypt’s leading non-bank financial services provider.

        With a strong vision and dedicated leadership, Hazem has played an instrumental role in building out the company from its beginnings in 2001 as a one-product consumer lending business into a thriving business encompassing a suite of consumer and commercial financial services delivered physically and digitally to a wide range of Egyptians.
        
        In addition to being the largest consumer financing business in Egypt, Contact also has pioneering businesses in insurance, technology, and the debt capital markets.  Leading a strong team of dedicated Egyptian professionals with a range of experience, Hazem led the business as General Manager then Chief Executive Officer for 18 years before focusing on strategy & development as Chairman.

        Hazem holds a bachelor’s degree in economics from Hamilton University, New York.`,
      },
      {
        id: 14,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mr.Said-Zater.jpg",
        name: "Said Zater",
        nameWithMr: "Mr. Said Zater",
        role: "MD of Contact Financial Holding & Group CEO",
        details: `Said Zater is a distinguished business leader boasting a significant corporate journey in the realm of non-banking financial services since 2001. 
        
        Presently serving as the Managing Director and Chief Executive Officer of Contact Financial Holding, he concurrently holds the esteemed role of Chairman of the Egyptian Federation of Consumer Finance. 
        
        With an illustrious background including strategic leadership positions within the Ministry of Interior, Zater's non-banking financial services career commenced at Contact, where his impactful contributions span strategic planning, operations, cost analysis, and the implementation of management consultancy approaches to enhance company performance, market reach, and revenue. 
        
        Notably, Zater has successfully guided the company to heightened operational efficiency and brand expansion through an IPO. What adds a remarkable dimension to Zater's leadership is the noteworthy recognition Contact has received under his guidance from Forbes Middle East itself. 
        
        The company secured its position and distinction by being listed among the top 50 listed companies in Egypt for two consecutive years, a testament to its consistent growth and significant presence in the industry. The company's outstanding achievements under his leadership were also honored by The Global Economics Magazine, bestowing accolades for Fastest Growing Finance Company and Best Digital Transformation FINTECH Company. 
        
        The seasoned operations management expert's extensive expertise encompasses strategic and risk management, growth strategies, crisis handling, and government relations.`,
      },
      // {
      //   id: 15,
      //   img: "/managers/03.jpg",
      //   name: "Ayman El-Sawy",
      //   role: "Group Financial Consultant",
      // },
      {
        id: 15,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Seif-ElBassiouni.jpg",
        name: "Seif El-Bassiouni",
        role: "Chief Financial Officer",
      },
      {
        id: 16,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Amr-Refaat.jpg",
        name: "Amr Refaat",
        role: "Vice Chairman of Contact Credit",
      },
      {
        id: 17,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Dr.%20Alaa-Awad.jpg",
        name: "Alaa Awad",
        role: "Group GM for HR, Governance, and Institutional Development",
      },
      {
        id: 18,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Tamer-Samir.jpg",
        name: "Tamer Samir",
        role: "MD of Contact Credit",
      },
      {
        id: 19,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mohamed-Salama.jpg",
        name: "Mohamed Salama",
        role: "Group GM for Legal Affairs",
      },
      {
        id: 20,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Dr-Haidy.jpg",
        name: "Haidy Elmasry",
        role: "Group Director of HR & Organizational Development",
      },
      {
        id: 21,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ismail-Samir.jpg",
        name: "Ismail Samir",
        role: "MD of Contact Leasing",
      },
      {
        id: 22,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed-Khalifa.jpg",
        name: "Ahmed Khalifa",
        role: "MD of Sarwa Insurance",
      },
      {
        id: 23,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Rimah-Asaad.jpg",
        name: "Rimah Asaad",
        role: "MD of Sarwa Life",
      },
      {
        id: 24,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/_91A0073.jpg",
        name: "Ahmed Hassanen",
        role: "Credit & Risk Director",
      },
      {
        id: 25,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed-Ezz.jpg",
        name: "Ahmed Ezz",
        role: "Chief Risk Officer",
      },
      {
        id: 26,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Sherif-Galal.jpg",
        name: "Sherif Galal",
        role: "Group GM for Managerial Control",
      },
      // {
      //   id: 27,
      //   img: "/managers/safaya.jpg",
      //   name: "Safeya Borhan",
      //   role: "Director of Corporate Affairs & Governance",
      //   details: `Safeya Borhan is a distinguished figure in the field of Capital Markets, specializing in Corporate Affairs and Governance. With a wealth of experience and a strong foundation in good governance principles spanning diverse sectors, Safeya has accumulated over 17 years of expertise in the non-banking financial services industry. Presently, she holds the position of Director of Corporate Affairs, Governance, and Sustainability at Contact Financial Holding, where she also serves as the Chief of the Sustainability Development Committee.

      //   In 2021, Safeya achieved a significant milestone by being promoted to the executive board member role at Contact Financial Holding, Sarwa Insurance, and Sarwa Life Insurance Companies. Her impactful contributions extend to her role as a key initiator of the Egyptian Consumer Finance Law submitted to the Financial Regulatory Authority in Egypt from 2018 to 2020. During this time, she played a pivotal role in the establishment of the Egyptian Consumer Finance Federation, where she served as the Federation Secretary General.

      //   Safeya's accomplishments further include securing and managing licenses for over 20 corporate entities, including Contact Credit (2020), Sarwa Sukuk (2019), Sarwa Capital Holding (2018), Sarwa Insurance (2018), Sarwa Life Insurance (2018), Contact Factoring (2016), Contact Mortgage Finance (2015), Contact Insurance Brokerage (2015), Sarwa Promotion and Underwriting (2015), and Sarwa Asset Management (2013).

      //   One of her notable achievements includes orchestrating the successful launch of Sarwa Capital's IPO through meticulous strategic planning and execution in 2018. Safeya possesses a versatile set of interpersonal skills, encompassing self-development, self-awareness, and adaptability. She has a proven track record of nurturing the professional growth of staff members by providing comprehensive support and inspirational leadership in an open and motivating work environment.

      //   Safeya holds a DBA (Doctor of Business Administration) and an MBA (Master of Business Administration) from ESLSCA University in Egypt, in addition to other certifications in Corporate Governance.`,
      // },
      {
        id: 28,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Nehal-Break.jpg",
        name: "Nehal Break",
        role: "MD of Contact Insurance Brokerage",
      },
      {
        id: 29,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Waleed-Kamel.jpg",
        name: "Walid Kamel",
        role: "Contact Cars.com Executive Director",
      },
      {
        id: 30,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed-A.%20Hakim.jpg",
        name: "Ahmed A. Hakim",
        role: "Group IT Director",
      },
      {
        id: 31,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Omar-ElFiky.jpg",
        name: "Omar El Fiky",
        role: "MD of Contact CrediTech",
      },
      {
        id: 32,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Hany-Omar.jpg",
        name: "Hany Omar",
        role: "MD of Contact Factoring",
      },
      {
        id: 33,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ayman-Halim.jpg",
        name: "Ayman Halim",
        role: "MD of Contact Mortgage",
      },
      {
        id: 34,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Marwan-Adel.jpg",
        name: "Marwan Adel",
        role: "Group Marketing Director",
      },
      {
        id: 35,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ghada-Tayel.jpg",
        name: "Ghada Tayel",
        role: "Group Business Development Manager",
      },
      {
        id: 36,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Mohamed-Samir.jpg",
        name: "Mohamed Samir",
        role: "Group  Administrative Affairs Director",
      },
      {
        id: 37,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Moursy-Mansour.jpg",
        name: "Moursy Mansour",
        role: "Group Internal Auditor",
      },
      {
        id: 38,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed-Ewiess.jpg",
        name: "Ahmed Ewiess",
        role: "Group Market & Business Analysis Manager",
      },
    ],
    icon_title: {
      products_auto_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "10 million",
          sub_title: "Financing up to 10 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
        {
          id: 5,
          img: "/approve.svg",
          title: "Full service",
          sub_title:
            "Additional services such as registration, insurance, and license renewal ",
        },
        {
          id: 6,
          img: "/no-insurance.png",
          title: "Optional Insurance",
          // sub_title: "Applicable with and without insurance.",
        },
      ],
      products_trucks_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "8 million",
          sub_title: "Financing up to 8 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
      ],
      products_maintainance_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "24 months",
          sub_title: "Payment plans up to 24 months.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "1.5 million",
          sub_title: "Financing up to 1.5 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
      ],
      products_shopping_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "1 million",
          sub_title:
            "Financing up to 1 million EGP for any product or service you need.",
        },
        {
          id: 3,
          img: "/payment.png",
          title: "No down payment",
          // sub_title: "Down-payments starting from 0.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title:
            "Easy and fast transactions. only with vour National ID and mobile number.",
        },
        {
          id: 5,
          img: "/bank.png",
          title: "Wide network",
          sub_title:
            "A wide network of retail outlets, merchants, and megastores that cover all categories and brands.",
        },
        {
          id: 6,
          img: "/without-complications.svg",
          title: "No hidden fees",
          sub_title: "No hidden fees or charges.",
        },
        {
          id: 7,
          img: "/approve.svg",
          title: "Flexibility",
          sub_title: "Flexible and convenient payment options.",
        },
        {
          id: 8,
          img: "/customer-services.svg",
          title: "Service 24/7",
          sub_title:
            "Professional and friendly customer service at your service 24/7.",
        },
      ],
      products_clubs_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "1.5 million",
          sub_title: "Financing up to 1.5 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "No down-payment.",
          sub_title: "",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
        {
          id: 5,
          img: "/membership.svg",
          title: "Instant Membership Activation.",
        },
      ],
      products_events_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "3 years",
          sub_title: "Payment plans up to 3 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "2 million",
          sub_title: "Financing up to 2 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
        // {
        //   id: 4,
        //   img: "/rate.svg",
        //   title: "Competitive interest rates",
        //   sub_title: "",
        // },
        // {
        //   id: 5,
        //   img: "/approve.svg",
        //   title: "Easy eligibility criteria",
        //   sub_title: "",
        // },
        // {
        //   id: 6,
        //   img: "/bank.png",
        //   title: "Wide network of partner vendors",
        //   sub_title: "",
        // },
      ],
      products_green_finance_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "3 million",
          sub_title: "Financing up to 3 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "30% down payment",
          sub_title: "Down-payments starting from 30%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Fastest and easiest procedures.",
        },
      ],
      products_factoring_page: [
        {
          id: 1,
          img: "/speed1.png",
          title: "Easier and faster approval process.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "Immediate access to cash.",
        },
        {
          id: 3,
          img: "/approve.svg",
          title: "Covering a diversity of industries",
        },
        {
          id: 4,
          img: "/payment1.png",
          title: "Professional service and support",
        },
      ],
      products_education_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "12 months",
          sub_title:
            "Payment plans up to 12 months for schools, universities, and professional courses.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "2 million",
          sub_title: "Financing up to 2 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "No down-payment required",
        },
        // {
        //   id: 4,
        //   img: "/speed1.png",
        //   title: "Speed",
        //   sub_title: "Simple and fast procedures.",
        // },
        {
          id: 4,
          img: "/graduation.png",
          title: "Payment plans up to 3 years for masters",
        },
        {
          id: 5,
          img: "/PhD.svg",
          title: "Payment plans up to 5 years for PhD programs",
        },
      ],
      products_leasing_page: [
        {
          id: 1,
          img: "/coins.png",
          title: "Down Payment",
          sub_title: "Down payment starting from 10%.",
        },
        {
          id: 2,
          img: "/calender.png",
          title: "Up to 5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 3,
          img: "/suppliers.png",
          title: "Supplier Network",
          sub_title: "Wide suppliers’ network.",
        },
        {
          id: 4,
          img: "/payment.png",
          title: "Different Payment Plans",
          sub_title: "Customized plans.",
        },
        // {
        //   id: 5,
        //   img: "/without-complications.svg",
        //   title: "Without Complications",
        //   sub_title: "Efficient and fast process",
        // },
        {
          id: 6,
          img: "/furniture.png",
          title: "Includes Equipment",
          sub_title: "Medical equipment and devices financing.",
        },
        {
          id: 7,
          img: "/transport.png",
          title: "Includes Transportation",
          sub_title: "Transportation financing.",
        },
      ],
      products_watercraft_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "4 million",
          sub_title: "Financing up to 4 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
        {
          id: 5,
          img: "/financing-maintenance.svg",
          title: "Financing maintenance and repair expenses",
        },
      ],
      products_motorcycle_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "3 million",
          sub_title: "Financing up to 3 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
        // {
        //   id: 5,
        //   img: "/bank.png",
        //   title: "Insurance is optional ",
        // },
        {
          id: 5,
          img: "/motor.svg",
          title:
            "Financing all types of motorcycles (two, three, or four wheels)",
        },
      ],
      products_furniture_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "3 million",
          sub_title: "Financing up to 3 million EGP.",
        },
        {
          id: 3,
          img: "/payment.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },

        {
          id: 5,
          img: "/offers.svg",
          title:
            "The ability to combine different quotations in the same request",
        },
      ],
      products_home_interior_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "4 million",
          sub_title: "Financing up to 4 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down-payments starting from 20%.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures.",
        },
      ],
      mortgage_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "10 years",
          sub_title: "Payment plans up to 10 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "10 million",
          sub_title: "Financing up to 10 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "25% down payment",
          sub_title: "Only pay 25% down-payment.",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title:
            "The easiest and fastest procedures, with minimal paperwork and hassle.",
        },
        {
          id: 5,
          img: "/repayment-periods.svg",
          title: "Financing all types of Residential units ",
          sub_title: "standalone and compound units.",
        },
      ],
      home_equity: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title: "Payment plans up to 5 years.",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "10 million",
          sub_title: "Financing options of up to 10 million EGP.",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "No down-payment",
          sub_title: "",
        },
        {
          id: 4,
          img: "/repayment-periods.svg",
          title: "Financing against all types of residential units  ",
          sub_title: "inside or outside compounds in Egypt.",
        },
      ],
      products_manasik_page: [
        {
          id: 1,
          img: "/calender.png",
          title: "5 years",
          sub_title:
            "Payment plans up to 5 years for Hajj and 12 months for Umrah",
        },
        {
          id: 2,
          img: "/cash1.png",
          title: "3 million",
          sub_title: "Financing up to 3 million EGP",
        },
        {
          id: 3,
          img: "/payment1.png",
          title: "20% down payment",
          sub_title: "Down payment starting from 20%",
        },
        {
          id: 4,
          img: "/speed1.png",
          title: "Speed",
          sub_title: "Simple and fast procedures",
        },
        {
          id: 5,
          img: "/sharia_islamia.svg",
          title: "Sharia-compliant transactions ",
        },
      ],
    },
  },
  logo: "/logo-en.png",

  main_nav: {
    individuals: "Individuals",
    companies: "Business",
    investors: "Investors",
    lang: "عربي",
  },
  secondary_nav: {
    financial_services: "Financing Services",
    insurance_services: "Insurance Services",
    contact_uae: "Contact UAE",
    contact_now: "ContactNow",
    insurance_services_dropdown:
      //  {
      //   motor: "Motor",
      //   home: "Home",
      //   personal: "Personal Accident & Lifestyle",
      //   property: "Property",
      //   engineering: "Engineering",
      //   motor_fleet: "Motor Fleet",
      //   marine: "Marine & Island Cargo",
      //   liability: "Liability, Casualty, AND Financial",
      //   medical: "Medical",
      //   brokerge: "Insurance Brokerage",
      // },
      [
        {
          url: "/products/insurance",
          color: colors.ratty,
          img: "/services/bro-en.svg",
          title: "Insurance Brokerage",
        },
        {
          url: "/sarwa-life",
          // url: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
          color: colors.ratty,
          img: "/images/life-logo-en.svg",
          title: "Sarwa Life",
        },
        {
          url: "/sarwa-insurance",
          // url: "https://sarwa.insurance/sarwa-insurance/product/retail/?lang=en",
          color: colors.ratty,
          img: "/images/insurance-logo-en.svg",
          title: "Sarwa Insurance",
        },
      ],
    e_payments: "Bill Payments",
    why_contact: "Why Contact ?",
    clients_program: "Contact Programs",
    // investment_and_savings: "Saving and Investment",
    branches_location: "Our Branches",
    login: "Login",
    download_app: "Download App",
  },
  footer: {
    section_a: {
      subscribe_now: "Subscribe Now",
      in_email_service: "In E-mail Service",
      // enter_email: "Enter You E-mail Address",
      enter_email: "Subscribe to our newsletter",

      button: "Subscribe",
    },
    section_b: {
      for_individuals: "For Individuals",
      financial_services: "Financing Services",
      insurance_services: "Insurance Services",
      Terms_and_Conditions: "Terms and Conditions",
      list1: [
        { id: "1", name: "Auto", url: "/products/auto" },
        { id: "2", name: "Trucks", url: "/products/trucks" },
        { id: "3", name: "Shopping", url: "/products/contact-now" },
        { id: "4", name: "Education", url: "/products/education" },
        { id: "5", name: "Clubs", url: "/products/clubs" },
        { id: "6", name: "Maintenance", url: "/products/maintainance" },
        { id: "7", name: "Wedding & Events", url: "/products/events" },
        { id: "8", name: "Mortgage", url: "/products/mortgage" },
        { id: "9", name: "Motorcycles", url: "/products/motorcycles" },
        { id: "10", name: "Green Finance", url: "/products/green-finance" },
        { id: "11", name: "Factoring", url: "/products-bussiness/factoring" },
        { id: "12", name: "Leasing", url: "/products-bussiness/leasing" },
        { id: "13", name: "Home Interior", url: "/products/home-interior" },
        { id: "14", name: "Home Furniture", url: "/products/home-furniture" },
        { id: "15", name: "Watercraft", url: "/products/watercraft" },
        // { id: "16", name: "Manasik", url: "/products/manasik" },
      ],
      list2: [
        { name: "Insurance Brokerage", url: "/products/insurance" },
        {
          name: "Sarwa Life",
          // url: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
          url: "/sarwa-life",
        },
        {
          name: "Sarwa Insurance",
          // url: "https://sarwa.insurance/sarwa-insurance/product/retail/t?lang=en",
          url: "/sarwa-insurance",
        },
      ],
      list3: [
        { name: "Bill Payments", url: "/e-payment" },
        // { name: "Saving & Investment", url: "/investment" },
      ],
      for_business: "For Business",
      for_business_list: [
        { name: "Referral Program", url: "/referral-program" },
      ],
      contact: "About Contact",
      contact_list: [
        { name: "About Us", url: "/about" },
        { name: "Our Brands", url: "/our-brands" },
        { name: "Why Contact ?", url: "/why-contact" },
        { name: "Careers", url: "/careers" },
        { name: "Contact UAE", url: "/contact-uae" },
        {
          name: "Investor Relations",
          url: "https://investorrelations.contact.eg/",
        },
        { name: "Sustainability", url: "/sustainability" },
        { name: "Blogs", url: "/blogs" },
        { name: "Media and News", url: "/media" },
      ],
      contact_us: "Contact Us",
      contact_us_list: [
        // { name: "Contact Us", url: "/contact-us" },

        {
          name: "Our Brochure",
          url: "https://contact-app-prod.s3.us-east-2.amazonaws.com/contact.eg/Contact-Brochure.pdf",
        },
        { name: "Our Branches", url: "/our-branches" },
        { name: "Our Hotline: 16177", url: "" },
      ],
      privacy: "Privacy",
      privacy_list: [{ name: "Privacy Policy", url: "/privacy-policy.pdf" }],
      protection_guide: "Customer Protection Guide",
      protection_guide_list: [
        {
          name: "Guide 1",
          url: "https://fra.gov.eg/%D8%AF%D9%84%D9%8A%D9%84-%D8%AD%D9%85%D8%A7%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D8%B9%D8%A7%D9%85%D9%84%D9%8A%D9%86-%D9%81%D9%8A-%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9-%D8%A7%D9%84%D9%85%D8%A7%D9%84/",
        },
        {
          name: "Guide 2",
          url: `${process.env.NEXT_PUBLIC_SITE_URL}كتاب دورى رقم (4) .pdf`,
        },
      ],
    },
    section_c: {
      download_app: "Download Contact App",
      img1: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/google-play-en.png",
      img2: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/app-store-en.png",
      img3: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/huawei-store-en.jpg",
    },
    section_d: {
      copy_right1: "Copyright ",
      copy_right2: " © – All rights reserved to Contact",
    },
  },
  bottom_card: {
    placeholder: "enter email adress",
    send: "send",
  },
  form_labels: {
    title: "Merchant Network",
    full_name: "Full Name",
    last_name: "Last Name",
    email_adress: "Email Address",
    phone_number: "Mobile Number",
    national_id: "National ID",
    governorate: "Governorate",
    g_placeholder: "Choose Governorate",
    category: "Category",
    city: "City",
    country: "Country",
    country_placeholder: "Choose Country",

    case1: "Please Choose at least a Category and a City",
    case2: "No Merchant Available",
    cat_placeholder: "Choose A Category",
    city_placeholder: "Choose A City",
    area_placeholder: "Choose An Area",
    area: "Area",
    a_placeholder: "Choose Area",
    car_type: "Car Type",
    car_placeholder: "Choose Car Condition",
    car_price: "Car Price",
    products: "Products",
    products_placeholder: "Choose A Product",
    mortgage_type: "Mortgage Type",
    POS_Placeholder: "Choose P.O.S",
    submit: "Submit",
    // =======
    subject: "Subject",
    message: "How can we help you?",
    job: "The job applied for",
    resume: "Resume",
    resume_des: "Please upload your CV link",

    passport_copy: "Valid Passport Copy",
    work_permit: "Work Permit",
    hr_letter: "Stamped HR Letter",
    bank_statement: "Bank Statement",
    EgyptAddress: "Residence Address within Egypt",
    noFileChosen: " No File Chosen",
    chooseFile: "choose File",
    terms:
      " By checking this box, I give Contact Credit the authority and consent to disclose the data and information relating to me or the credit facility and the obligations in relation there-to and any default if any to the Egyptian Credit Bureau Company (I-Score). This includes checking my I-score report, which may be required for the request to be approved. Also, I accept any inquiry conducted about me by Contact Credit’s employees without objection from me.",
    enterPhoneFirst: "Please enter your phone number first",
    Disclaimer:
      "Uploading more documents can help expedite your approval process.",
    apply: "Apply for job",
    showmore: "Show more",
    optinol: "Optional",
  },
  governorates: [
    {
      id: "1",
      governorate_name: "Cairo",
    },
    { id: "2", governorate_name: "Giza" },
    {
      id: "3",
      governorate_name: "Alexandria",
    },
    {
      id: "4",
      governorate_name: "Dakahlia",
    },
    {
      id: "5",
      governorate_name: "Red Sea",
    },
    {
      id: "6",
      governorate_name: "Beheira",
    },
    {
      id: "7",
      governorate_name: "Fayoum",
    },
    {
      id: "8",
      governorate_name: "Gharbiya",
    },
    {
      id: "9",
      governorate_name: "Ismailia",
    },
    {
      id: "10",
      governorate_name: "Menofia",
    },
    {
      id: "11",
      governorate_name: "Minya",
    },
    {
      id: "12",
      governorate_name: "Qaliubiya",
    },
    {
      id: "13",
      governorate_name: "New Valley",
    },
    {
      id: "14",
      governorate_name: "Suez",
    },
    {
      id: "15",
      governorate_name: "Aswan",
    },
    {
      id: "16",
      governorate_name: "Assiut",
    },
    {
      id: "17",
      governorate_name: "Beni Suef",
    },
    {
      id: "18",
      governorate_name: "Port Said",
    },
    {
      id: "19",
      governorate_name: "Damietta",
    },
    {
      id: "20",
      governorate_name: "Sharkia",
    },
    {
      id: "21",
      governorate_name: "South Sinai",
    },
    {
      id: "22",
      governorate_name: "Kafr Al sheikh",
    },
    {
      id: "23",
      governorate_name: "Matrouh",
    },
    {
      id: "24",
      governorate_name: "Luxor",
    },
    { id: "25", governorate_name: "Qena" },
    {
      id: "26",
      governorate_name: "North Sinai",
    },
    {
      id: "27",
      governorate_name: "Sohag",
    },
  ],
  country: [
    {
      id: "Egypt",
      country_name: "Egypt",
      phoneCode: "+20",
      flag: "EG",
      icon: cifEg,
    },
    {
      id: "United Arab Emirates",
      country_name: "United Arab Emirates",
      phoneCode: "+971",
      flag: "AE",

      icon: cifAe,
    },
  ],

  car_type: [
    {
      id: "1",
      value: "New",
    },
    { id: "2", value: "Used" },
  ],
  mortgage_type: [
    {
      id: "1",
      value: "Home Finance",
    },
    { id: "2", value: "Home Equity" },
  ],
  home_page: {
    slides: [
      {
        bgImage:"/orange-video-bg.jpeg",
        banner_type:"video",
        desktopSrc:"https://contact-app-prod.s3.us-east-2.amazonaws.com/contact.eg/Helping+You+Achieve+Your+Dreams+(1920+x+1080+px)+(2000+x+700+px).mp4",
        mobileSrc:"https://contact-app-prod.s3.us-east-2.amazonaws.com/contact.eg/Helping+You+Achieve+Your+Dreams.mp4"
      },
      {
        bgColor: "#2D317F",
        bgImage: "/bgs/02-en.svg",
        logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact.png",
        text: "Finance Hajj and Umrah now with Contact",
        textColor: colors.white,
        button: "Learn more",
        img: "/bgs/manasik-home-banner.png",
        alt: "manasik",
        link: "/products/manasik",
      },
      {
        bgColor: "#EAEAEA",
        // bgImage: "/bgs/05-en.svg",
        bgImage: "/bgs/02-en.svg",

        logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-en-white.png",
        text: "One stop shop for your Financial and Insurance solutions",
        // textColor: colors.ratty,
        textColor: colors.white,

        button: "Browse Products",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/cover_img_55.png",
        alt: "finance with contact",
        link: "/products",
      },
      {
        bgColor: "#EAEAEA",
        // bgImage: "/bgs/03-en.svg",
        bgImage: "/bgs/02-en.svg",

        logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-brokerage-en-light.png",
        text: "Download the App & enjoy exclusive insurance benefits",
        // textColor: colors.ratty,
        textColor: colors.white,

        button: "Request service",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/cover_img_33.png        ",
        alt: "insurance brokerage",
        link: "/products/insurance",
        app: {
          google_play_link:
            "https://play.google.com/store/apps/details?id=com.insuranceBrokerage&hl=en",
          app_store_link:
            "https://apps.apple.com/eg/app/insurance-brokerage/id6445921286",
          qr_code:
            // "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/qr-code-brokrage.png",
            // "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/qr-code-light.png",
            "/insurance_brokerage_qr_code.svg",
        },
      },
      {
        bgColor: "#EAEAEA",
        // bgImage: "/bgs/01-en.svg",
        bgImage: "/bgs/02-en.svg",
        logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-pay-logo.png",
        text: "Your Gateway to the easiest payment way",
        // textColor: colors.ratty,
        textColor: colors.white,

        button: "Read More",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-pay-banner-orange.png",
        alt: "contact pay",
        link: "/e-payment",
        app: {
          google_play_link:
            "https://play.google.com/store/apps/details?id=eg.contact",
          app_store_link:
            "https://apps.apple.com/us/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088",
          // qr_code:
          //   "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/qr-code-light.png",
        },
      },
      {
        bgColor: "#2D317F",
        bgImage: "/bgs/02-en.svg",
        logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact.png",
        text: "Everywhere around you",
        textColor: colors.white,
        button: "Pick Your Branch",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/cover_img_22-en.png",
        alt: "contact branches",
        link: "/our-branches",
      },
    ],
    services_title: "Our Services",
    products_title: "Our Products",
    products_options: [
      { id: "1", title: "Home" },
      { id: "2", title: "Vehicles" },
      { id: "3", title: "Insurance" },
      { id: "4", title: "Lifestyle" },
      { id: "5", title: "Education" },
      { id: "6", title: "Green Finance" },
      { id: "7", title: "Manasik" },
    ],
    products: [
      {
        optionId: "1",
        // title1: "Financing",
        title2: "Home Furniture",
        img: "/pages/furniture-cover.png",
        small_img: "/pages/furniture-cover-small.png",
        bgcolor: "#fff",
        url: "/products/home-furniture",
        value: "HomeFurniture",
        alt: "furniture background",
      },
      {
        optionId: "1",
        title2: "Maintenance",
        img: "/pages/maintainance-cover.png",
        small_img: "/pages/maintainance-cover-small.png",
        bgcolor: "#fff",
        url: "/products/maintainance",
        value: "Maintenance",
        alt: "maintenance background",
      },
      {
        optionId: "1",
        title2: "Mortgage",
        img: "/pages/home-cover.png",
        small_img: "/pages/home-cover-small.png",
        bgcolor: "#fff",
        url: "/products/mortgage",
        value: "Mortgage",
        alt: "mortgage background",
      },
      {
        optionId: "1",
        title2: "Home Interior",
        img: "/pages/finishing-cover.png",
        small_img: "/pages/finishing-cover-small.png",
        bgcolor: "#fff",
        url: "/products/home-interior",
        value: "Homes",
        alt: "home interior background",
      },
      {
        optionId: "2",
        title1: "",
        title2: "Auto",
        img: "/pages/cars-cover.png",
        small_img: "/pages/cars-cover-small.png",
        bgcolor: "#fff",
        url: "/products/auto",
        value: "Auto",
        alt: "auto background",
      },
      {
        optionId: "2",
        title2: "Trucks",
        img: "/pages/trucks-cover.png",
        small_img: "/pages/trucks-cover-small.png",
        bgcolor: "#fff",
        url: "/products/trucks",
        value: "Trucks",
        alt: "trucks background",
      },
      {
        optionId: "2",
        title2: "Watercraft",
        img: "/pages/watercraft-cover.png",
        small_img: "/pages/watercraft-cover-small.png",
        bgcolor: "#fff",
        url: "/products/watercraft",
        value: "Watercrafts",
        alt: "watercraft background",
      },
      {
        optionId: "2",
        title2: "Motorcycles",
        img: "/pages/motorcycle-cover.png",
        small_img: "/pages/motorcycle-cover-small.png",
        bgcolor: "#fff",
        url: "/products/motorcycles",
        value: "Motorcycles",
        alt: "motorcycles background",
      },
      {
        optionId: "3",
        title2: "Insurance Brokerage",
        img: "/pages/insurance-cover.png",
        small_img: "/pages/insurance-cover-small-en.png",
        bgcolor: "#fff",
        url: "/products/insurance",
        value: "Brokerage",
        alt: "insurance background",
      },
      {
        optionId: "3",
        title2: "Sarwa Life",
        img: "/our-brands/sarwa-life.png",
        small_img: "/pages/sarwa-life-small-en.png",
        bgcolor: "#fff",
        url: "/sarwa-life",
        value: "Life",
        alt: "insurance background",
      },
      {
        optionId: "3",
        title2: "Sarwa Insurance",
        img: "/our-brands/sarwa-insurance.png",
        small_img: "/pages/sarwa-insurance-small-en.png",
        bgcolor: "#fff",
        url: "/sarwa-insurance",
        value: "Insurance",
        alt: "insurance background",
      },
      {
        optionId: "4",
        title2: "Shopping",
        img: "/pages/cart.png",
        small_img: "/pages/cart-small.png",
        bgcolor: "#fff",
        url: "/products/contact-now",
        value: "shopping",
        alt: "shopping background",
      },
      {
        optionId: "4",
        title2: "Wedding & Events",
        img: "/pages/event-cover-en.png",
        small_img: "/pages/events-cover-small.png",
        bgcolor: "#fff",
        url: "/products/events",
        value: "Wedding",
        alt: "wedding and events background",
      },
      {
        optionId: "4",
        title2: "Clubs",
        img: "/pages/clubs-cover.png",
        small_img: "/pages/clubs-cover-small.png",
        bgcolor: "#fff",
        url: "/products/clubs",
        value: "Clubs",
        alt: "clubs background",
      },
      {
        optionId: "4",
        title2: "Education",
        img: "/pages/schools.png",
        small_img: "/pages/schools-small.png",
        bgcolor: "#fff",
        url: "/products/education",
        value: "Education",
        alt: "education background",
      },
      {
        optionId: "5",
        title2: "Nursery",
        img: "/pages/nursery.png",
        small_img: "/pages/nursery-small.png",
        bgcolor: "#fff",
        url: "/products/education",
        value: "Education",
        alt: "education background",
      },
      {
        optionId: "5",
        title2: "Schools",
        img: "/pages/schools.png",
        small_img: "/pages/schools-small.png",
        bgcolor: "#fff",
        url: "/products/education",
        value: "Education",
        alt: "education background",
      },
      {
        optionId: "5",
        title2: "Universities",
        img: "/pages/universities.png",
        small_img: "/pages/universities-small.png",
        bgcolor: "#fff",
        url: "/products/education",
        value: "Education",
        alt: "education background",
      },
      {
        optionId: "5",
        title2: "Post Grad",
        img: "/pages/postgraduate.png",
        small_img: "/pages/postgraduate-small.png",
        bgcolor: "#fff",
        url: "/products/education",
        value: "Education",
        alt: "education background",
      },
      {
        optionId: "6",
        title2: "Green Car",
        img: "/pages/green-finance-cover.png",
        small_img: "/pages/green-car-small.png",
        bgcolor: "#fff",
        url: "/products/auto",
        value: "GreenFinance",
        alt: "green finance background",
      },
      {
        optionId: "6",
        title2: "Solar Panels",
        img: "/pages/green-finance-cover.png",
        small_img: "/pages/green-energy-small.png",
        bgcolor: "#fff",
        url: "/products/green-finance",
        value: "GreenFinance",
        alt: "green finance background",
      },
      {
        optionId: "6",
        title2: "Greenhouses",
        img: "/pages/green-finance-cover.png",
        small_img: "/pages/green-house-small.png",
        bgcolor: "#fff",
        url: "/products/green-finance",
        value: "GreenFinance",
        alt: "green finance background",
      },
      {
        optionId: "6",
        title2: "Irrigation systems",
        img: "/pages/green-finance-cover.png",
        small_img: "/pages/green-watering-small.png",
        bgcolor: "#fff",
        url: "/products/green-finance",
        value: "GreenFinance",
        alt: "green finance background",
      },
      {
        optionId: "7",
        title2: "Manasik",
        img: "/pages/manasik-card.png",
        small_img: "/pages/manasik-card.png",
        bgcolor: "#fff",
        url: "/products/manasik",
        value: "Manasik",
        alt: "manasik",
      },
      // *********
      {
        title2: "Factoring",
        img: "/pages/factoring-cover.png",
        bgcolor: "#bf6340",
        url: "/products-bussiness/factoring",
        value: "Factoring",
        alt: "factoring background",
      },
      {
        title2: "Leasing",
        img: "/pages/leasing-cover.png",
        bgcolor: "#4aa2a6",
        url: "/products-bussiness/leasing",
        value: "Leasing",
        alt: "leasing background",
      },
    ],
    bos: {
      title1: "Bill Payments",
      // title2: "Payments",
      subtitle: "Your All-in-One Payment Solution",
      body1:
        "Now with Contact Pay, you can easily and securely pay your mobile, electricity, gas, water bills and many other services at one place, through ContactNow mobile application or Contact branches all over Egypt.",

      body2: [
        "Schools and universities tuition fees",
        "Electricity bills",
        "Gas bills",
        "Water bills",
        "Landline bills",
        "Mobile recharging and bills",
        "Internet subscription",
        "Transportation tickets",
        "TV subscriptions",
        "Insurance installments",
        "Donations and NGOs",
        "Clubs membership",
      ],
      WithContactPay_list: {
        title: "What can you do with Contact Pay?",
        list: [
          "Electricity bills",
          "Gas bills",
          "Water bills",
          "Landline bills",
          "Mobile recharging and bills",
          "Internet subscription",
          "Transportation tickets",
          "TV subscriptions",
          "Insurance installments",
          "Donations and NGOs",
          "Clubs membership",
          "Schools and universities tuition fees",
        ],
      },
      benefits: {
        title: "Your Convenience Is Our Priority",
        list: [
          "Speed and ease: Say goodbye to time-consuming bill payments. Contact Pay makes it quick and simple to settle your dues.",
          "Security you can trust: Rest assured; your transactions are protected by Contact's advanced security measures.",
          "One-stop solution: Ditch juggling multiple payment platforms. Contact Pay lets you manage all your bills seamlessly in one place.",
        ],
      },
      outro: (
        <p>
          Contact Pay is your answer to a simpler, more efficient way to manage
          your finances.Start enjoying the benefits today! Contact us or visit
          any of our branches to learn more and experience the convenience of
          Contact Pay. Together, let's pay smarter, not harder.{" "}
          <p style={{ paddingTop: "16px" }}>
            {" "}
            <a
              target="_blank"
              style={{ color: "#F18222", fontWeight: "600" }}
              href={userAgent(
                "https://apps.apple.com/us/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088",
                "https://play.google.com/store/apps/details?id=eg.contact"
              )}
            >
              Download the App now!{" "}
            </a>{" "}
          </p>
        </p>
      ),
      // subOutro: "Download the App now!",
      // link: userAgent(
      //   "https://apps.apple.com/us/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088",
      //   "https://play.google.com/store/apps/details?id=eg.contact"
      // ),
    },
    services: {
      A: [
        {
          id: "1",
          url: "/products/auto",
          color: colors.blue,
          img: "/services/car.svg",
          title: "Auto",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "7",
          url: "/products/motorcycles",
          color: "#DD6F5A",
          img: "/services/motorcycle.svg",
          title: "Motorcycles",
        },
        {
          id: "2",
          url: "/products/trucks",
          color: colors.dark_red,
          img: "/services/lorry.svg",
          title: "Trucks",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "3",
          url: "/products/contact-now",
          color: colors.dark_violet,
          img: "/services/cart.svg",
          title: "Shopping",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "4",
          url: "/products/education",
          color: colors.green,
          img: "/services/education.svg",
          title: "Education",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "5",
          url: "/products/clubs",
          color: colors.dark_petroli,
          img: "/services/clubs.svg",
          title: "Clubs",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },

        {
          id: "6",
          url: "/products/maintainance",
          color: "#DB8539",
          img: "/services/maintainance.svg",
          title: "Maintenance",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
      ],
      B: [
        {
          id: "16",
          url: "/products/manasik",
          color: "#27A9E1",
          img: "/services/manasik.svg",
          title: "Manasik",
        },
        {
          id: "8",
          url: "/products/events",
          color: colors.dark_green,
          img: "/services/ring.svg",
          title: "Wedding & Events",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "9",
          url: "/products/mortgage",
          color: colors.dark_petroli,
          img: "/services/house.svg",
          title: "Mortgage",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "10",
          url: "/products-bussiness/factoring",
          color: colors.dark_brown,
          img: "/services/discount.svg",
          title: "Factoring",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "11",
          url: "/products-bussiness/leasing",
          color: colors.light_petroli,
          img: "/services/rent.svg",
          title: "Leasing",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "12",
          url: "/products/green-finance",
          color: colors.light_green,
          img: "/services/green-finance.svg",
          title: "Green Finance",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "13",
          url: "/products/home-furniture",
          color: "#5E7EB5",
          img: "/services/home-furniture.svg",
          title: "Home Furniture",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "14",
          url: "/products/home-interior",
          color: colors.dark_brown,
          img: "/services/design.svg",
          title: "Home Interior",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "15",
          url: "/products/watercraft",
          color: "#27A9E1",
          img: "/services/watercraft.svg",
          title: "Watercraft",
        },
      ],
      All: [
        {
          id: "1",
          url: "/products/auto",
          color: colors.blue,
          img: "/services/car.svg",
          title: "Auto",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "7",
          url: "/products/motorcycles",
          color: "#DD6F5A",
          img: "/services/motorcycle.svg",
          title: "Motorcycles",
        },
        {
          url: "/products/trucks",
          id: "2",
          color: colors.dark_red,
          img: "/services/lorry.svg",
          title: "Trucks",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/contact-now",
          id: "3",
          color: colors.dark_violet,
          img: "/services/cart.svg",
          title: "Shopping",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/education",
          id: "4",
          color: colors.green,
          img: "/services/education.svg",
          title: "Education",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/clubs",
          id: "5",
          color: colors.dark_petroli,
          img: "/services/clubs.svg",
          title: "Clubs",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/maintainance",
          id: "6",

          color: "#DB8539",
          img: "/services/maintainance.svg",
          title: "Maintenance",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },

        {
          id: "16",
          url: "/products/manasik",
          color: "#27A9E1",
          img: "/services/manasik.svg",
          title: "Manasik",
        },
        {
          id: "8",
          url: "/products/events",
          color: colors.dark_green,
          img: "/services/ring.svg",
          title: "Wedding & Events",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/mortgage",
          id: "9",
          color: colors.dark_petroli,
          img: "/services/house.svg",
          title: "Mortgage",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },

        {
          url: "/products-bussiness/factoring",
          id: "10",
          color: colors.dark_brown,
          img: "/services/discount.svg",
          title: "Factoring",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products-bussiness/leasing",
          id: "11",
          color: colors.light_petroli,
          img: "/services/rent.svg",
          title: "Leasing",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/green-finance",
          id: "12",
          color: colors.light_green,
          img: "/services/green-finance.svg",
          title: "Green Finance",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/home-furniture",
          id: "13",

          color: "#5E7EB5",
          img: "/services/home-furniture.svg",
          title: "Home Furniture",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          url: "/products/home-interior",
          id: "14",
          color: colors.dark_brown,
          img: "/services/design.svg",
          title: "Home Interior",
          description:
            "If you need to develop your agricultural investment, Contact believes that agriculture development.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        {
          id: "15",
          url: "/products/watercraft",
          color: "#27A9E1",
          img: "/services/watercraft.svg",
          title: "Watercraft",
        },
      ],
      C: [
        {
          url: "/referral-program",
          color: colors.blue,
          img: "/services/clients.svg",
          title: "Referral Program",
          description: "If you need to develop your agricultural investment.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
        // {
        //   url: "/contact-homes",
        //   color: colors.blue,
        //   img: "/services/homes.svg",
        //   title: "Contact Homes",
        //   description: "If you need to develop your agricultural investment.",
        //   full_description:
        //     "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        // },
        {
          url: "/rewards",
          color: colors.blue,
          img: "/services/loyalty.svg",
          title: "Rewards Program",
          description: "If you need to develop your agricultural investment.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
      ],
      D: [
        {
          url: "/rewards",
          color: colors.blue,
          img: "/services/clients.svg",
          title: "Referral Program",
          description: "If you need to develop your agricultural investment.",
          full_description:
            "Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. ",
        },
      ],
    },
    news_title: "Our News",
    news_button1: "Read More",
    news_button2: "More",
    news: [
      {
        id: "1",
        img: "/news/news1.png",
        title: "كونتكت تطلق منتج رقمي لتأمين السيارات",
        description:
          "كونتكت المالية القابضة تعلن اليوم عن إطلاق منتج رقمي بالكامل للتأمين التكميلى للسيارات في مصر. يتوفر المنتج من خلال منصة كونتكت كارز دوت كوم وشركة ثروة للتأمين وشركة كونتكت ...",
      },
      {
        id: "2",
        img: "/news/news2.png",
        title:
          "«شطبلي» توقع اتفاقية شراكة مع «كونتكت» لإتاحة برامج تمويل مبتكرة",
        description:
          "وقّعت شركة «شطبلي»، صاحبة أول منصة إلكترونية متخصصة في أعمال تصميم وتنفيذ أعمال الديكورات والتشطيبات، اتفاقية شراكة مع شركة كونتكت للتمويل ...",
      },
      {
        id: "3",
        img: "/news/news3.png",
        title: "كونتكت توقع برنامج تمويل فريد من نوعه في مصر مع كابيتر",
        description:
          "وقعت كونتكت المالية القابضة (CNFN)، الشركة الرائدة في مجال الخدمات المالية غير مصرفية في مصر، من خلال شركة كونتكت للتخصيم، أحد ...",
      },
    ],
  },
  products_shopiing_page: {
    cover: {
      title1: "Consumer Finance",
      title2: "We Make Your Life Easier",
      description: `Contact makes your life easier anytime you want to buy anything via financial solutions without any troubles. Contact makes your life easier anytime you want to buy anything via financial solutions without any troubles`,
    },
    outro:
      "Contact is more than just a financing company. We are your trusted partner in fulfilling your shopping needs. We enable you to own what you dream of, without any hassle or stress. Contact us today, establish your account, and start enjoying the easiest shopping experience ever. ",
    section_a: {
      title1: "Payment Period",
      title2: "36 Months",
      body: "Enjoy your purchasing experience with our hassle-free financial solutions, fast approval process, and the widest merchant network for consumer products & services. Choose your installment plan from 6 to 60 months and pay at your convenience through the most diverse payment and collection network across Egypt.",
    },
    section_b: {
      title: "How Does It Work? ",
      description:
        "It's simple. When you already have an open account with Contact, you can just ",
      list: [
        "Choose your merchant from our network",
        "Go to the store with the national ID and mobile registered in the service",
        "Choose your payment plan ",
        "You will receive an OTP code via SMS to provide to the cashier",
        "An electronic invoice will be sent via SMS",
      ],
    },
  },
  products_contact_now_page: {
    cover: {
      title1: `Shopping Has Never Been Easier`,
      title2:
        "Whether you want to buy a new laptop, a smartphone, a TV, or any other consumer product, we have the perfect solution for you. Buy what you need, and Contact will facilitate it through a hassle-free shopping experience with the easiest and fastest approval and the widest merchant network, covering all your needs from products and services all over Egypt. With our Consumer Finance service, you can buy all what you want today and pay later in easy installments. ",
      description: ``,
    },
    section_a: {
      title1: "Payment Period",
      title2: "36 Months",
      body: "Enjoy your purchasing experience with our hassle-free financial solutions, fast approval process, and the widest merchant network for consumer products & services. Choose your installment plan from 6 to 60 months and pay at your convenience through the most diverse payment and collection network across Egypt.",
    },
    section_b: [
      "Choose your merchant from our network",
      "Go to the store with the national ID and mobile registered in the service",
      "Choose your payment plan ",
      "You will receive an OTP code via SMS to provide to the cashier",
      "An electronic invoice will be sent via SMS",
    ],
  },
  /** Home Interior تمويل التصميم والتشطيب*/
  products_finishing: {
    cover: {
      img: "/pages/finishing-cover.png",
      color: "#95664B",
      title1: "Home Interior",
      title2: "Turn your dream home style into reality with Contact",
      description: `Are you looking for a way to transform your home into a stylish and comfortable space that reflects your personality and taste? Do you want to hire the best interior design companies in Egypt to create your dream home? Do you need a flexible and affordable financing solution to help you achieve your goals? Home Interior and finishing financing from Contact is your best option.  `,
    },
    outro:
      "Home Interior and Finishing Financing is a partnership with a shared purpose, reaching your dreams and goals. Pick the interior design company of your choice and Contact will finance your home renovation. Don't let your favorite home design remain a dream. Make it a reality with Home Interior and finishing financing from Contact. Apply now, establish your account with Contact, and get ready to enjoy your new home style. ",
    section_a: {
      title: "4,000,000 EGP",
      body: `lorem ipsum dolor sit amet, consectetur adip non pro id, sed diam nonum vulputate velit sed diam nonum. 
    Ut enim ad minim veniam, quis nostr arranged eu fugiat null a ante et d.`,
    },
    section_b: {
      img: "/pages/without-advance.png",
      title: "Without Advance",
      body: `lorem ipsum dolor sit amet, consectetur adip non pro id, sed diam nonum vulputate velit sed diam nonum. 
    Ut enim ad minim veniam, quis nostr arranged eu fugiat null a ante et d.`,
    },
  },
  products_home_furniture: {
    cover: {
      img: "/pages/home-furniture.png",
      color: "#5E7EB5",
      title1: "Home Furniture",
      title2: "Refresh your home style with Contact",
      description: `Envision your living space transformed with stylish new furniture. Imagine enjoying the comfort and elegance of modern furnishings without financial strain. With Contact's Home Furniture Financing, this dream can become a reality.`,
    },
    outro:
      "If you’re looking to purchase furniture in Egypt, Contact offers a variety of financing services and programs that can help you achieve your goals. With Contact’s Home Furniture Financing service, you can easily and affordably refresh your home style. ",
  },
  products_events: {
    cover: {
      img: "/pages/event-cover-en.png",
      color: "#547865",
      title1: "Weddings and Events",
      title2: "Your gateway to happiness",
      description: `Start planning for your special event, whether it is a wedding, engagement, graduation, or birthday and we will help you make it a memorable and hassle-free celebration without worrying about the costs. Contact offers flexible and convenient financing solutions for various types of events. Whether you want a lavish wedding or a simple graduation party, Contact can help you finance your event through simple and fast procedures.`,
    },
    outro:
      "Contact is your gateway to happiness. Don't let financial constraints stop you from celebrating your life's milestones. Apply now and enjoy a memorable celebration with Contact.",
  },
  products_manasik: {
    cover: {
      img: "/pages/manasik-cover.png",
      color: "#547865",
      title1: "Manasik: Your Gateway to Hajj and Umrah",
      title2: "Begin Your Sacred Journey to the Holy Land with Ease",
      description: `Your dream of going for Hajj or Umrah is closer than ever with Manasik by Contact. We
understand the immense spiritual and emotional significance of this journey and the financial
challenges that may come with it. That’s why we are committed to making your pilgrimage
smooth and stress-free.With Manasik, you’ll have access to Shariah-compliant financing solutions specifically
designed for pilgrims.`,
    },
    section_2: {
      img: "/manasik_section_2.png",
      description:
        "Our flexible payment plans for both Hajj and Umrah are tailored to your needs, ensuring that you can focus on fulfilling this sacred duty without worrying about upfront expenses. Whether it’s your first time or a cherished return visit, Contact is here to support you every step of the way",
    },
    section_3: {
      title: "With Manasik you get:",
      img: "/manasik_section_3.png",
      list: [
        "Shariah Compliance:Financing solutions aligned with Islamic principles.",
        "Flexible Payment Plans:Spread the cost of your journey over manageable installments.",
        "Quick Approvals:Start your preparation immediately with our hassle-free process.",
        "Dedicated Support:Our team is here to guide you through financing and planning your trip.",
      ],
    },
    section_4: {
      description:
        "Don’t let financial concerns delay your journey. With Contact, your path to the Holy Land is just a step away. Embrace the opportunity to perform Hajj or Umrah with peace of mind and a full heart. Start your sacred journey today with Manasik by Contact.",
    },
  },
  products_factoring: {
    cover: {
      img: "/pages/factoring-cover.png",
      color: "#BF6340",
      title1: "Factoring",
      title2: "The easiest cash solution",
      description: `Factoring is a simple and convenient way to access working capital without taking on debt or giving up equity. You can use the cash to pay your suppliers, employees, and taxes, or invest in new projects. Factoring also helps you reduce your credit risk, as Contact assumes the responsibility of collecting the payments from your customers.Contact is a leading provider of Factoring services in Egypt, with a proven track record of empowering businesses across various industries. Whether you are a small or medium enterprise, a large corporation, or a multinational company, Contact can offer you customized and flexible solutions that suit your needs and goals.`,
    },
    outro:
      "If you need to know more about how Contact can help you grow your business with Factoring, contact us today.",
  },
  investment: {
    cover: {
      img: "/investement.png",
      color: "#5686A3",
      title1: "Contact Kol Yom saving and investment fund.",
      title2: "Your Everyday Investment Partner",
      description: `Contact Kol Yom offers a simple, secure, and rewarding way to grow your money daily. It is a secure and accessible investment tool that fits your financial aspirations. It leverages a diversified portfolio, ensuring steady, attractive returns while safeguarding your capital and liquidity.`,
    },
    outro: `Contact Kol Yom is more than just an investment, it's a commitment to your financial future. Join us and start experiencing the power of daily financial growth. Start your journey today!
    `,
    subOutro: " Don't wait, invest with Contact Kol Yom!",
  },
  products_clubs: {
    cover: {
      img: "/pages/clubs-cover.png",
      color: "#5686A3",
      title1: "Clubs",
      title2: "Energize Your Life, Motivate Your Children",
      description: `Energize your life, develop your children's talents, and help them practice their favorite sport! With Contact, the leading financing company in Egypt, you can finance the membership of your aspired club in Egypt with the easiest and fastest procedures, and convenient payment plans. Whether you want to join a sports club, a social club, or a cultural club, Contact has you covered. `,
    },
    outro:
      "Don't miss the opportunity to join your favorite club and achieve your dreams and happiness. Apply now with Contact and get approved in minutes. ",
    clubs_title: "Subscribed Clubs",
    clubs: [
      ["Wadi Degla Club", "/pages/club1.png"],
      ["Matrix Club", "/pages/club2.png"],
      ["Zohor Club", "/pages/club3.png"],
      ["Island Club", "/pages/club4.png"],
      ["Hawar Club", "/pages/club5.png"],
      ["Platinum Club", "/pages/club6.png"],
    ],
  },

  products_maintainance: {
    cover: {
      img: "/pages/maintainance-cover.png",
      color: "#DB8539",
      title1: "Maintenance",
      title2: "Facilitating every detail!",
      description: `Whether you need to fix your car, renovate your home, or renovate your property, you know that maintenance can be costly and time-consuming. That's why Contact is here to help you with your maintenance needs. Contact offers innovative financing solutions for any maintenance project through Contact Maintenance Financing. You can hire the best maintenance professionals in Egypt to do the job and pay later with easy and convenient payment plans.`,
    },
    outro:
      "If you are looking for a reliable and affordable way to finance your maintenance projects, look no further than Contact. Establish your account today with Contact and enjoy exclusive benefits and programs.",
  },
  products_trucks: {
    cover: {
      img: "/pages/trucks-cover.png",
      color: "#AA4445",
      title1: "Trucks",
      title2: "Drive your fleet forward with Contact!",
      description: `At Contact, we offer financing plans for all types of heavy transport and trucks; small, mid sized, heavy trucks. semi-trailers, flatbeds, step decks trucks, and more. Our procedures are simple and fast, and we offer flexible payment plans for all types of new, used, and imported trucks.`,
    },
    outro:
      "If you are involved in the Construction Industry, Manufacturing and Industrial Sector, or Shipping and Logistics in Egypt and deal with heavy cargo looking for a reliable financing option for your heavy equipment and trucks, Contact is here to help. We offer financing plans for all types of new, used, and imported trucks with easy and flexible procedures. ",
    plans_title: "Trucks Installments Plans",
    plans: [
      {
        name: "New Trucks - Standard",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "New Trucks - Flexible",
        role: [
          "20% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Imported Trucks – Standard",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "Imported Trucks – Economy",
        role: [
          "50% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "Imported Trucks – Economy Plus",
        role: [
          "50% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Imported Trucks – Flexible",
        role: [
          "20% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Used Trucks – Economy",
        role: [
          "50% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Used Trucks – Economy Plus",
        role: [
          "50% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
    ],
    business_plans_title:
      "Installment Plans for Professionals & Business Activities (Commercial register & tax card)",
    business_plans: [
      {
        name: "New Trucks - Standard",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "New Trucks - Flexible",
        role: [
          "30% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Imported Trucks – Standard",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "Imported Trucks – Economy",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 1 month and half",
        ],
      },
      {
        name: "Imported Trucks – Economy Plus",
        role: [
          "30% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Imported Trucks – Flexible",
        role: [
          "30% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Used Trucks – Economy",
        role: [
          "30% down payment",
          "Payment plans up to 4 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
      {
        name: "Used Trucks – Economy Plus",
        role: [
          "30% down payment",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Insurance is not required",
          "First installment after 2 months and half",
        ],
      },
    ],
  },
  products_cars: {
    cover: {
      img: "/pages/cars-cover.png",
      color: "#282780",
      title1: "Auto",
      title2: "Own Your Dream Car with Contact!",
      description: `If you’re looking to buy a new or used car, electric or fuel-powered engine, Contact can help you with car financing solutions tailored to your needs. We offer flexible procedures and many auto installment plans for all brands and models. With a nationwide presence at the majority of car showrooms, Contact is your go-to choice for auto financing in Egypt, providing tailored financial solutions for both new and used cars.`,
    },
    outro:
      "Our auto financing solutions are designed to help you own your dream car with ease. We understand that buying a car can be a big investment, and nowadays it is challenging more than ever, which is why we offer flexible payment options that suit your budget. Whether you’re looking for a new or used car, we have the right financing solution for you.",
    plans_title: "Installments Plans",
    plans: [
      {
        name: "Electric Vehicles",
        role: [
          "20% down payment",
          "Insurance is not required",
          "Payment plans up to 5 years",
          "Full or partial prepayment after 1 year",
        ],
      },
      {
        name: "New Plus",
        role: [
          "20% down payment",
          "Insurance required",
          "Payment plans up to 5 years",
          "First installment after 3 months",
        ],
      },
      {
        name: "Economy",
        role: [
          "20% down payment",
          "Insurance is required",
          "Payment plans up to 3 years",
        ],
      },
      {
        name: "Economy Plus",
        role: [
          "20% down payment",
          "Insurance is required",
          "Payment plans up to 5 years",
          "Full or partial prepayment after 1 year",
        ],
      },
      {
        name: "Flexible",
        role: [
          "20% down payment",
          "Insurance is required",
          "Payment plans from 3 to 5 years",
          "Full or partial prepayment after 1 year",
          "Partial deferral for down-payment",
        ],
      },
      {
        name: "Flexible Plus",
        role: [
          "20% down payment",
          "Insurance is not required",
          "Payment plans up to 5 years",
          "Full or partial prepayment options",
          "Partial deferral for down-payment",
        ],
      },
      {
        name: "Used Cars Packages",
        role: [
          "20% down payment",
          "Insurance is not required",
          "Payment plans up to 5 years",
          // "Prepayment option after 6 installments",
        ],
      },
      {
        name: "Corporate Packages",
        role: [
          "10% down payment",
          "Insurance is required",
          "Payment plans from 2 to 5 years",
          "Full prepayment option after one year",
        ],
      },
    ],
  },
  products_education: {
    cover: {
      img: "/pages/education-cover.png",
      color: "#72813B",
      title1: "Education",
      title2: "Achieve Your Educational Goals",
      description: `Education is one of the most important investments you can make for yourself and your family. It can open new opportunities, enhance your skills, and increase your chances of success in life. But education can also be expensive, especially if you want to pursue a high educational level. That's why Contact offers you a range of financing solutions to help you access the best education possible in Egypt through all stages of local and international programs and courses.`,
    },
    outro:
      "At Contact, we believe in building futures. We are committed to supporting you and your family in achieving your educational goals and dreams. With our financing solutions, you can access the best education possible without compromising on quality or affordability. Don't let financial barriers stop you from pursuing your educational goals. Apply for Contact's financing programs today and start building your future.",
  },
  products_home: {
    cover: {
      img: "/pages/home-cover.png",
      color: "#53908A",
      title1: "Mortgage",
      title2: "Make the right move with Contact!",
      description: `If you’re looking to purchase a home in Egypt, Contact offers you financing services and programs that can help you achieve your goals. With Contact Mortgage, you can enjoy the benefits of flexible Home Financing options and own your property without any complicated procedures.`,
    },
    // plans_title: "Mortgage Installments Plans",
    // plans: [
    // {
    //   title: "Economy",
    //   bodyList: [
    //     "No down payment",
    //     "Financing up to 6 million EGP.",
    //     "Payment plans from 1 to 5 years",
    //     "The possibility of full and partial settlement after one year",
    //   ],
    // },
    // {
    //   title: "Flexible",
    //   bodyList: [
    //     "No down payment",
    //     "Financing up to 6 million EGP.",
    //     "Payment plans from 1 to 5 years",
    //     "The possibility of full or partial prepayment expenses",
    //   ],
    // },
    // ],
    section_a: {
      img: "/pages/without-advance.png",
      title: "Advantages",
      body: [
        "25 Down payment",
        "Financing up to 6 million EGP.",
        "Payment plans up to 10 years",
        "Financing all types of Residential units (standalone and compound units)",
      ],
    },
    section_b: {
      img: "/pages/without-advance.png",
      title: "Mortgage Benefits",
      body: [
        "25 Down payment",
        "Financing up to 6 million EGP.",
        "Payment plans up to 10 years",
        "Financing all types of Residential units (standalone and compound units)",
      ],
    },
    card_section_title: "Programs",
    cards: [
      {
        name: "Economy",
        role: [
          "No down payment",
          "Financing up to 6 million EGP.",
          "Payment plans from 1 to 5 years",
          // "The possibility of full and partial settlement after one year",
        ],
      },
      {
        name: "Flexible",
        role: [
          "No down payment",
          "Financing up to 6 million EGP.",
          "Payment plans from 1 to 5 years",
          "The possibility of full or partial prepayment expenses",
        ],
      },
    ],
    home_equity: {
      title: "Home Equity",
      description:
        "If you are looking for a flexible and convenient way to access funds for your personal or business needs, you may want to consider the Home Equity program from Contact. This program finances up to 50% of the current market value of your home, using your home as collateral. You can use the funds for your home improvement, debt consolidation, education, or investment. ",
      outro: {
        img: "/pages/without-advance.png",
        content:
          "The Home Equity program from Contact is a smart and convenient way to leverage the value of your home and achieve your financial objectives. Contact us today to find out more and get started.",
      },
    },
    home_finance: {
      title: "Home Finance",
      description:
        "Home Finance is more than just a mortgage program. It's a way to make your dreams come true. Imagine living in your own home, in the location of your choice, with all the amenities and facilities you need, having the peace of mind that comes with knowing that you have made a smart investment for your future.",
      outro: {
        img: "/images/mortgage.png",
        content:
          "Don't wait any longer. Contact us today and find out how to finance your dream home with the easiest and fastest procedures, from Contact Mortgage.",
      },
    },
  },
  products_leasing: {
    cover: {
      img: "/pages/leasing-cover.png",
      color: "#4AA2A6",
      title1: "Leasing",
      title2: "Fuel Your Growth. Lease with Contact!",
      description: `Contact is a leading company in Egypt that offers financing programs for leasing various assets, from medical equipment to transportation vehicles. Whether you want to upgrade your hospital, clinic, or medical center with the latest devices and equipment, or you want to expand your fleet of passenger or commercial vehicles, Contact has the perfect plan for you.  `,
    },
    section_a: {
      img: "/pages/without-advance.png",
      title: "Contact Leasing Benefits",
      body: [
        "Efficient and transparent process",
        "Comprehensive and wide supplier network",
        "Access to world-class manufacturers",
        "Straightforward plans structured to suit your needs",
        "Positive impact on the company’s financial indicators",
        "Payment Plans from 1 year up to 5 years",
        "Down payment starting from 10% according to the asset type",
      ],
    },
    section_b: {
      img: "/pages/without-advance.png",
      title: "How It Works",
      description: `We design our solutions around the market value of the assets that your business needs to acquire, and nothing else.`,
      list: [
        [
          "Find the right asset for your business",
          "Our Partner Network covers medical and transportation asset sectors, whether new, used, or refurbished.",
          "1",
        ],
        [
          "Complete our simple application process",
          "We’ve worked hard on simplifying our process so that you know well in advance what documentation you’ll need, and we’ll give you a quick and committed response date.",
          "2",
        ],
        [
          "Choose a payment plan that suits you",
          "We will then provide you with payment plans based on the market value of your chosen asset. The plans include everything from the down-payment you’ll need to our administrative fees, without hidden charges and personal discounts.",
          "3",
        ],
      ],
    },
    outro:
      "Contact is more than just a financing company. We are your trusted ally that understand your business goals and help you achieve them. Contact us today and let us help you grow your business potential.",
  },
  products_green_finance: {
    cover: {
      img: "/pages/green-finance-cover.png",
      color: "#92B338",
      title1: "Green Finance",
      title2: "Together For a Better Future",
      description: `Are you looking for a way to grow your agricultural or renewable energy investments? Green Finance from Contact will support your investments with flexible and affordable plans. `,
    },
    outro:
      "At Contact, we believe that agriculture development and renewable energy are the future of our planet and we work hard to support them through our innovative financing programs. If you are interested in Green Finance, please contact us today. We will be happy to provide you with more information and guide you through process.",
    section_a: {
      img: "/pages/without-advance.png",
      title1: "Through Green finance,  you can",
      title2: "get access to finance the following:",
      body: [
        "Solar Panels",
        "The Development of Irrigation Systems",
        "Greenhouses",
        "Developing And Equipping Projects of Trench Plantings And Plastic Sheets For Tunnels And Greenhouses",
        "Fishery Ponds and Equipment",
        "Poultry Farms and Equipment",
        "Livestock Farms and Equipment",
      ],
    },
  },
  products_insurance_page: {
    cover: {
      img: "/pages/insurance-cover.png",
      color: "#282780",
      title1: "Insurance Brokerage",
      title2: "Stay secured, live insured.",
      description_indiv: `No more juggling between multiple insurance companies and policies. Contact Insurance Brokerage is a One-stop-shop for all your insurance needs whether you need to insure your project or company, with Contact you can choose the insurance benefits that suit all your needs, for both retail and corporates, through the best insurance companies and at the best packages in the Egyptian market.`,
      description_comp: `One-stop-shop for all your insurance needs whether you need to insure your project or company, with Contact you can choose the insurance benefits that suit all your needs, for organizations and corporates, through the best insurance companies and at the best packages in the Egyptian market.`,
    },
    subTitle: "Retail Insurance Products",

    section_a: [
      {
        img: "/family_insurance.svg",
        title: "Family Insurance",
        points: [
          "Medical Insurance",
          "Life Insurance",
          "Personal Accident Insurance",
          "Medical Discounts Card",
        ],
      },
      {
        img: "/home_insurance.svg",
        title: "Home Insurance",
        points: [
          "Home  Insurance",
          "Electronic  Insurance",
          "Extended Warranty for Electronics and Appliances ",
          "Key protection Insurance",
        ],
      },
      {
        img: "/car_insurance.svg",
        title: "Car Insurance",
        points: [
          "Motor Insurance",
          "Total Car Loss Insurance ",
          "Road Assist",
          "Key Replacement",
        ],
      },
      {
        img: "/travel_insurance.svg",
        title: "Business Insurance",
        points: ["SMEs Insurance"],
      },
    ],
  },
  products_watercraft: {
    cover: {
      img: "/pages/watercraft-cover.png",
      color: "#27A9E1",
      title1: "Watercraft",
      title2: "Live the luxury!",
      description: `Looking for a joyful and luxurious experience and enjoying the thrill of the waves? Whether you want to Cruise on a yacht, speed on a jet ski, Rafting, Kayaking, Kitesurfing gear, Water skiing, Parasailing, Diving gear, and many more, Contact can help you finance your watercraft of choice.`,
    },
    outro:
      "Contact us today and apply for your watercraft financing. We are ready to assist you and make your water adventure come true.",
  },
  products_motorcycle: {
    cover: {
      img: "/pages/motorcycle-cover.png",
      color: "#DD6F5A",
      title1: "Motorcycles",
      title2: "Get Ready for The Adventure! ",
      description: `Enjoy the freedom of the open road, avoid the hassle of traffic, and explore new places! If you are interested in owning a motorcycle, scooter, golf cart, or beach buggy, you can now enjoy different financing solutions from Contact for all kinds of vehicles, including motorcycles and their accessories. Whether you want a two-wheeler, a three-wheeler, or a four-wheeler, Contact can help you get it with the best terms and conditions. `,
    },
    outro:
      "Contact offers a convenient and hassle-free way to own your dream motorcycle, scooter, golf cart, or beach buggy and all its accessories. Contact provides the largest financing amount and the easiest procedures to help you own your motorcycle in no time. ",
  },
  our_brand_page: {
    title1: "Our Companies",
    title2: "Our Digital Platforms",
    Contactfinancialholding: "Contact Financial Holding",
    body: [
      {
        id: 1,
        img: "/our-brands/contact-en.jpg",
        link1: null,
        link2: null,
        link3: null,
        heading: "First and Biggest Financing Company in Egypt",
        content:
          "Founded in 2001, Contact pioneered the consumer finance landscape in Egypt. As the first independent, management, and employee-led initiative consumer finance company in the sector in Egypt, Contact has consistently secured diverse equity financing from banks, private equity firms, and individual investors.This innovative approach has fueled Contact's growth and expansion, solidifying its position as a leading provider of tailored financial solutions for Egyptian consumers.Contact has been expanding its services in Egypt to cover a wide range of services and solutions where customers can buy now and pay later for almost anything; from electronics and consumer goods to motorcycles, watercraft, private and commercial vehicles, trucks, education fees to even interior design, finishing, furniture, maintenance, club memberships, mortgage and corporate financing such as leasing and factoring.",
        supervision:
          "Under the supervision of the Financial Regulatory Authority (FRA) license number 1, Date 16/04/2020",
      },
      {
        id: 2,
        // img: "/our-brands/c-bro-en.jpg",
        img: "/our-brands/c-bro-en.jpg",
        link1: null,
        link2: null,
        link3: null,
        heading: "Your Trusted Partner for Comprehensive Insurance Coverage",
        content:
          "Contact Insurance Brokerage simplifies insurance for individuals and businesses across Egypt. As your one-stop shop, we help you navigate the complexities of insurance, ensuring you choose the right coverage and insurance product for your home, family, car, or business. Whether you're a retail customer or a corporate entity, our insurance brokerage arm partners with leading insurance providers to deliver tailored packages that meet your specific needs and budget.Through our dedicated team of insurance professionals working closely with you to understand your unique risk profile and goals, we can translate complex insurance jargon into clear explanations, helping you confidently choose the plan that best fits your budget and requirements.",
        supervision:
          "Under the supervision of the Financial Regulatory Authority (FRA) license number 54, Date 19/04/2015",
      },
      {
        id: 3,
        img: "/our-brands/sarwa-insurance.png",
        link1: "https://sarwa.insurance/sarwa-insurance?lang=en",
        link2: null,
        link3: null,
        heading: "Changing How You Think Insurance.",
        content:
          "Sarwa Insurance S.A.E is a fast-growing and dynamic insurer that offers a wide range of Personal and Commercial lines insurance solutions. Our Core focus is to provide exemplary services and value. We hand-picked some of the best insurance practitioners in their fields to ensure that we are fully equipped to service the growing risk transfer and insurance needs of our clients. Our extensive experience as individuals working on truly international standards ensures that we can provide the most effective and economical solutions to our clients. At SARWA we are all committed to building confidence and creating certainty in our clients’ business.",
        supervision:
          "Under the supervision of the Financial Regulatory Authority (FRA) license number 40, Date 03/03/2019",
      },

      {
        id: 4,
        img: "/our-brands/sarwa-life.png",
        link1: "https://sarwa.insurance/sarwa-Life?lang=en",
        link2: null,
        link3: null,
        heading: "Your Pathway to a Healthy and Wealthy Life",
        content:
          "Sarwa Life, a leading life insurance provider, empowers individuals and businesses with a seamless digital experience. We go beyond traditional insurance, crafting holistic financial solutions tailored to your unique life journey. Imagine: a one-stop-shop where you can address your medical, investment, and retirement needs effortlessly, all through our state-of-the-art platform.Sarwa Life makes it possible for customers to experience unparalleled convenience and control. Manage policies, access personalized recommendations, and make claims anytime, anywhere, using fast and secure digital tools from Sarwa Life. Whether a private individual or a corporate entity, Sarwa Life simplifies the financial journey, providing distinguished and innovative services built around specific needs. Sarwa Life is your trusted partner on the path to a brighter and healthier future.",
        supervision:
          "Under the supervision of the Financial Regulatory Authority (FRA) license number 39, Date 03/03/2019",
      },
      // {
      //   id: 3,
      //   img: "/our-brands/contact-cars.png",
      //   link: "https://www.contactcars.com/en",
      //   content:
      //     "Contactcars is the dominant automotive portal in Egypt. For over 19 years, contactcars.com has been pioneering the digital transformation of the automotive industry providing consumers a portfolio of products and services that make the buying and selling experience simple and user-friendly. As part of our continuous innovation drive, 2021 marks a milestone in which contactcars.com is re-launching its website and app as well as adding a host of new services for both consumers and dealers bringing the entire automotive ecosystem closer together and further simplifying the process of buying, selling, or owning a vehicle.",
      // },
      {
        id: 5,
        img: "/our-brands/sarwa.png",
        link1: null,
        link2: null,
        link3: "/sarwa-capital",
        heading: "A Leader in Debt Capital Markets",
        content:
          "Sarwa, a prominent player in the Egyptian financial landscape, has distinguished itself by strategically utilizing debt capital markets (DCM) since its inception. This focus has enabled Sarwa to not only diversify funding sources but also successfully refinance operations over time. By leveraging extensive experience in the DCM space, Sarwa has emerged as Egypt's leading provider of structured financing solutions.With an impressive track record of over EGP 26 billion in debt issuances arranged and issued since 2005, Sarwa played a groundbreaking role by introducing the first-ever securitization bond offering in the domestic market. Additionally, Sarwa holds the distinction of being the first licensed sukuk issuer in Egypt, further solidifying its position as a pioneer in innovative financial solutions in Egypt.",
        supervision: null,
      },
      {
        id: 6,
        img: "/our-brands/c-contact-now.png",
        link1: null,
        link2: userAgent(
          "https://apps.apple.com/eg/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088",
          "https://play.google.com/store/apps/details?id=eg.contact&hl=ar"
        ),
        link3: null,
        heading: "Simplified Financing with the Contact Now App!",
        content:
          "Developed to simplify financial management: The Contact Now mobile app offers swift approvals on financing plans from a vast network of merchants in Egypt. A user-friendly platform that extends beyond traditional financing, to cater to a diversified range of needs from products and services all over Egypt.ContactNow gets regular updates to ensure simplicity and clarity—empowering you to make informed financial decisions confidently. Download the 'Contact Now' app today and experience the future of convenient finance.",
        supervision:
          "Contact CrediTech under the supervision of the Financial Regulatory Authority (FRA) license number 33, Date 28/04/2022",
      },

      {
        id: 7,
        img: "/our-brands/wasla.png",
        link1: "https://waslabrowser.com/",
        link2: null,
        link3: null,
        heading: "Your Shopping Ally",
        content:
          "Wasla is the pioneering mobile web browser and desktop extension designed to optimize your online shopping experience in Egypt and beyond.Through our wide network of local and regional e-commerce merchants, we help you discover and unlock the best deals, discounts, and cashback offers. Whether you're browsing your favorite fashion stores, searching for tech bargains, or stocking up on everyday essentials, Wasla empowers you to save money with every click.",
        supervision: null,
      },
      {
        id: 8,
        // img: "/our-brands/contact-cars.png",
        img: "/our-brands/contact-cars.png",
        link1: "https://www.contactcars.com/en",
        link2: userAgent(
          "https://apps.apple.com/eg/app/contactcars/id390158823",
          "https://play.google.com/store/apps/details?id=net.sarmady.contactcarswithtabs&hl=en&gl=US"
        ),
        link3: null,
        heading: "Digitalizing the Egyptian Automotive Market",
        content:
          "Contactcars.com is the premier automotive marketplace in Egypt. Since 2002, we've been at the forefront of digital transformation in the automotive sector. Our dedication to innovation has continuously reshaped how people buy, sell, and maintain vehicles in Egypt. We are a one-stop shop platform of digital products fostering the best user experience for the automotive ecosystem in Egypt, including buyers, sellers, service centers, car dealerships, and importers. In addition to our renowned price catalog and classifieds marketplace, our diverse consumer product range includes digital finance applications, comprehensive motor insurance solutions, a motorcycle e-commerce platform, and innovative vehicle ownership services. Our B2B product offerings include a subscription-based digital showroom solution for importers and car dealerships, a digital auction solution, AI-based car valuation services, display advertising, and content marketing services. We mix cutting-edge technology & AI with 20 years of valuable experience to transform Egypt's automotive market. Our goal is to help individuals and businesses navigate the automotive market confidently.",
        supervision: null,
      },
      {
        id: 9,
        img: "/our-brands/co-bro-logo-en.png",
        link1: null,
        link2: userAgent(
          "https://apps.apple.com/eg/app/insurance-brokerage/id6445921286",
          "https://play.google.com/store/apps/details?id=com.insuranceBrokerage&hl=en"
        ),
        link3: null,
        heading: "One-Stop-Shop for All Your Insurance Needs.",
        content:
          "A one-stop shop for all your insurance needs, accessible through a user-friendly digital platform. Contact Insurance Brokerage mobile app. helps you to browse and apply for individual, family, or even business insurance plans. It goes beyond normal insurance coverage, it allows you to submit medical and car accident claims directly through the app, connect with doctors for consultations, manage your medication schedule, receive reminders, and ensure you stay on top of your health. Download the Contact Insurance Brokerage mobile app. to enjoy our special features that help you reach a better life.",
        supervision: null,
      },
    ],
    link1: "Visit website",
    link2: "Download app",
    link3: "Read more",
  },

  featureSection: [
    {
      id: "1",
      delay: "0",
      img: "/home-services/finance.svg",
      title: "Financing",
      text: "Financing plans that suit all your needs",
    },
    {
      id: "2",
      delay: "150",
      img: "/home-services/insurance.svg",
      title: "Insurance",
      text: "One-stop shop for all your insurance needs",
    },
    {
      id: "3",
      delay: "300",
      img: "/home-services/invetment-new.svg",
      title: "Savings & Investment",
      text: "Your everyday investment partner",
    },
    {
      id: "4",
      delay: "450",
      // img: "/home-services/payment.svg",
      img: "/home-services/payment.svg",
      title: "Payment Solutions",
      text: "Your payments made easy",
    },
  ],

  stepsFeature: [
    {
      id: "1",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/step-1-en.png",
      title: "Instant approval",
      subTitle: "feature",
      text: "Apply for instant credit approval.",
    },
    {
      id: "2",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/step-2-en.png",
      title: "Credit limit increase",
      subTitle: "feature",
      text: "Apply for a credit limit increase.",
    },
    {
      id: "3",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/step-3-en.png",
      title: "Pay your bills",
      subTitle: "feature",
      text: "Pay your installments and bills.",
    },
    {
      id: "4",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/step-4-en.png",
      title: "Latest offers and promotions",
      subTitle: "feature",
      text: "Receive the latest offers and promotions.",
    },
    {
      id: "5",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/step-5-en.png",
      title: "Our merchants",
      subTitle: "feature",
      text: "Explore our merchants’ network.",
    },
  ],
  stepsFeature_business: [
    {
      id: "1",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/1-en.png",
      title: "Medical Consultation",
      subTitle: "feature",
      text: "Immediate medical consultations, as well as benefiting from doctors’ instead in emergency cases through the application.",
    },
    {
      id: "2",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/2-en.png",
      title: "Medication Reminder",
      subTitle: "feature",
      text: "Notifications for your medication's timings and doses.",
    },
    {
      id: "3",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/3-en.png",
      title: "Wellness",
      subTitle: "feature",
      text: "Various health articles that help you adopt a healthy lifestyle and avoid wrong daily habits for a better healthy lifestyle.",
    },
    {
      id: "4",
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/4-en.png",
      title: "Claims",
      subTitle: "feature",
      text: "Quick and easy submission of claims through the application and follow-up to provide a simple submission processs.",
    },
  ],
  map: {
    title: "Our Branches",
    subTitle:
      "We are close to you everywhere in Egypt through more than 75 branches throughout the republic",
    placeholderGovernate: "Governorate",
    placeholderBranch: "Find your nearest branch",
    noOption: "There are no governorates",
  },

  contact_homes: {
    cover: {
      img: "/contact-homes.svg",
      color: "#2D9A8F",
      title1: "Homes",
      title2: "A new beginning for a perfect life",
      description: `Through the partnership between Contact and Coldwell Banker, you can take advantage of an endless world of benefits and offers. Providing you plenty of discounts and special prices`,
    },
    section_a: {
      title: "Program eligibility",
      body: `Coldwell Banker existing customer`,
    },
    section_b: {
      img: "/pages/without-advance.png",
      title: "Exclusive benefits for Contact clients from Coldwell Banker",
      body: [
        "1 Year Home Insurance",
        "Door Key Insurance",
        "Mobile, ipad & TV insurance",
        "50% discount on 2nd home insurance (North cost or Sokhna)",
      ],
    },
    section_c: {
      img: "/pages/without-advance.png",
      title:
        "Enjoy a world of unlimited benefits when you join Coldwell Banker Star club:",
      body: [
        "Free 3D home design sample on either the living room, bedroom, or one partition of the reception",
        "50% discount on 2D & 3D  modelling upon contractual agreement provided by RD design",
        "Up to 5 million EGP Contact Mortgage Loan",
        "Up to 500,000 EGP Contact Shopping loan",
        "Exclusive offers and discounts from Jotun, Ikea, and Carrier",
      ],
    },
    section_d: {
      img: "/pages/without-advance.png",
      title: "",
      body: ["", "", "", ""],
    },
  },
  inistance: {
    Tittle: "Get Inistance credit limit aprovel",
    Tittle2:
      "Up to 50,000 EGP credit can be instanly yours! just follow thes steps",
    Start: "Get Started",
  },
  Login: {
    Next: "Next",
    Login: "Login",
    // PhoneTitle:
    //   " To log into your account, enter your phone number below, you will receive a one-time password (OTP) ",
    PhoneTitle: " To log into your account, enter your phone number ",
    // PhoneLabel: "+2 (010) 052-09153",
    PhoneLabel: "011xxxxxxxx ",
    PhoneError:
      "Phone number must be exactly 11 digits and  start with (010/011/012/015) directly",
    ErrorMessPhone: "There is no account for this number",
    ErrorLabel: "Error",
    PassTitle: "Please enter your password",
    PassLabel: "Password",
    ErrorMessPass:
      "The phone number or password you entered is incorrect. Please try again",
    ResetPassword: "Forgot password?",
    CheckUserExistence: "This user doesn’t exist",
    OTPTitle:
      "A one-time password has been sent to a phone number that ends with the number، please enter it below",
    UpdatePassword: "Update Password",
    ConfirmLabel: "Confirm Password",
    ErrorConFirmPass: "passwords do not match",
    SuccsessUpdatePAss: "Password Changed, Please Login To Access Your App",
    ResendOtp: "Resend OTP",
    OTPNotVerfy: "OTP Not Valid",
    VerificationToken: "An error occurred, please try again",
    resetError: "Please enter your phone number to reset the password",
  },
  user_installements: {
    RemaniningAmounts: {
      title: "The rest",
      amount: "20,000",
      currency: "EGP",
      paymentBtn: "Installment payment",
      totalAmountText: "Total Amount",
    },
    UpComingInstallement: {
      title: "Upcoming Installement",
      paymentBtn: "Pay All",
      UpComingDetails: [
        {
          id: "1",
          payTo: "Tradeline Store",
          payFor: "Macbook M1 Pro",
          totalAmountText: "Total Amount",
          totalAmount: "64,450",
          currency: "EGP",
          whatPaid: "2,686",
          total_number_installments: "24",
          number_paid_installments: "18",
          paymentBtn: "Installment payment",
        },
        {
          id: "2",
          payTo: "Tradeline Store",
          payFor: "Macbook M1 Pro",
          totalAmountText: "Total Amount",
          totalAmount: "64,450",
          currency: "EGP",
          whatPaid: "2,686",
          total_number_installments: "24",
          number_paid_installments: "18",
          paymentBtn: "Installment payment",
        },
        {
          id: "3",
          payTo: "Tradeline Store",
          payFor: "Macbook M1 Pro",
          totalAmountText: "Total Amount",
          totalAmount: "64,450",
          currency: "EGP",
          whatPaid: "2,686",
          total_number_installments: "24",
          number_paid_installments: "18",
          paymentBtn: "Installment payment",
        },
        {
          id: "4",
          payTo: "Tradeline Store",
          payFor: "Macbook M1 Pro",
          totalAmountText: "Total Amount",
          totalAmount: "64,450",
          currency: "EGP",
          whatPaid: "2,686",
          total_number_installments: "24",
          number_paid_installments: "18",
          paymentBtn: "Installment payment",
        },
      ],
    },
    PreviousDealing: {
      title: "Previous Dealing",
      nodataerr: "No data for now please try again later ......",
      PreDealingHead: {
        product: "Product",
        seller: "Seller",
        date: "Date",
        amount: "Amount",
        payMethod: "Payment Method",
      },
    },
  },
  customer_services: {
    title: "To speak to contact customer service",
    whatsappCall: {
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/whatsapp-2.png",
      title: "Talking on WhatsApp",
      subTitle: "To talk through the WhatsApp Quick Response Messaging Service",
      arrow: "../images/next-arrow.png",
    },
    hotLineCall: {
      img: "../images/call.png",
      title1: "Talking Through ",
      title2: "Hotline",
      subTitle: "16177",
    },
    ourLocation: {
      img: "../images/location.png",
      title: "Visit one of our branches",
      arrow: "../images/next-arrow.png",
    },
  },
  profile: {
    title: "Profile",
    img: "../images/userProfile",
    imgBtn: "Change Image",
    email_title: "Email",
    extraInfo_title: "Additional Information",
    editBtn: "Edit",
  },
  userNav: {
    your_installement: "My Installments",
    call_center: "Reach Us",
    profile: "Profile",
    logout: "logout",
    welcome: "Welcome",
    loyaltyPoints: "Loyalty Points",
  },
  sarwa: {
    sarwa_life_individual: {
      logo: "/images/life-logo-en.svg",
      title: "Sarwa Life",
      hyperlink: "Sarwa Life Website",
      paragraph:
        "Sarwa Life aims to protect your quality of life through innovative and comprehensive Individual and Group Medical insurance Plans, Life Insurance Plans, Investment Plans, Retirement Plans, Protection and Saving Coverage Plans in Egypt. Sarwa life is one of the top life insurance players in the Egyptian market, balancing flexibility and responsiveness while adopting international standards and best practices, we provide the best experience to our clients by offering comprehensive services and innovative Life Insurance and Investment programs.",
      list_title: "Retail Products",
      list: [
        {
          title: "Saving & Protection.",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
        {
          title: "Life & Disability Coverage.",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
        {
          title: "Life & Disability Long Term Coverage.",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
        {
          title: "He (Male Cancer).",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
        {
          title: "She (Female Cancer).",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
        {
          title: "Smokers.",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
        },
      ],
      for_details: "For more information visit ",
      // link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
      link: "https://sarwa.insurance/sarwa-Life/?lang=en",
      // link: "/sarwa-life",
      img: "/images/sarwaLife.png",
    },
    sarwa_life_companies: {
      logo: "/images/life-logo-en.svg",
      title: "Sarwa Life",
      sub_title: "Comprehensive Life Insurance and Investment Solutions.",
      hyperlink: "Sarwa Life Website",
      paragraph:
        "Sarwa Life, a leading Egyptian insurance provider, empowers individuals and businesses to secure their financial future and wellness with a diverse portfolio of innovative and comprehensive life insurance and investment solutions, including Individual and Group Medical insurance Plans, Life Insurance Plans, Investment Plans, Retirement Plans, and Protection and Savings Coverage Plans in Egypt. As a trusted partner, we offer various options to safeguard the health and well-being of individuals and groups through professionally managed investment options in the form of various products and limitless options that businesses benefit from every day.",
      list_title: "Corprate Insurance",
      list: [
        {
          title: "Group Life & Disability",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Group Credit Life",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Group Pension",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Group Personal Accidents",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Group Medical",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Small & Medium Enterprises Packages (SME’S)",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
        {
          title: "Voluntary Personal Accidents Plan",
          link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en",
        },
      ],
      for_details: "For more information visit ",
      // link: "https://sarwa.insurance/sarwa-Life/product/retail/?lang=en#reqP",
      link: "https://sarwa.insurance/sarwa-Life/?lang=en",
      // link: "/sarwa-life",
      img: "/images/sarwaLife.png",
    },
    sarwa_insurance_individual: {
      logo: "/images/insurance-logo-en.svg",
      title: "Sarwa Insurance",
      sub_title: "Changing how you think Insurance",
      hyperlink: "Sarwa Insurance Website",
      paragraph:
        "We aim to change your perception about insurance via  building confidence and creating certainty in our clients’ business, from personal coverage plans to small business and commercial insurance. Our team and technology will help meet your needs. Sarwa makes insurance easy and efficient by spending time getting to know you and what you need to protect. We’ve built our business around your needs to deliver the highest and most sincere level of service to every customer with a more customized and personalized approach.",
      list_title: "Retail Products",
      list: [
        {
          title: "Motor Insurance.",
          link: "https://sarwa.insurance/sarwa-insurance/product/motorInsurance/?lang=en",
        },
        {
          title: "Home Insurance Plan.",
          link: "https://sarwa.insurance/sarwa-insurance/product/homeInsurance/?lang=en",
        },
        {
          title: "Personal Accidents & Lifestyle.",
          link: "https://sarwa.insurance/sarwa-insurance/product/personalAccidents/?lang=en",
        },
      ],
      for_details: "For more information visit ",
      // link: "https://sarwa.insurance/sarwa-insurance/product/retail/?lang=en",
      link: "https://sarwa.insurance/sarwa-insurance/?lang=en",
      // link: "/sarwa-insurance",
      img: "/images/sarwaInsurance.png",
    },
    sarwa_insurance_companies: {
      logo: "/images/insurance-logo-en.svg",
      title: "Sarwa Insurance",
      hyperlink: "Sarwa Insurance Website",
      paragraph:
        "Sarwa Insurance, one of the leading insurance companies in Egypt, is committed to transforming societal perceptions of insurance by fostering trust and transparency across all our offerings, encompassing individual coverage plans, micro, small and mid-size business insurance, covering a wide range of commercial insurance solutions in Egypt.Our team of dedicated experts leverages cutting-edge technology to gain a comprehensive understanding of your unique needs and craft tailored insurance plans that effectively safeguard your assets. We prioritize building lasting relationships with our partners, ensuring a seamless and personalized insurance experience from start to finish.",
      list_title: "Corporate Insurance",
      list: [
        {
          title: "Property Insurance",
          link: "https://sarwa.insurance/sarwa-insurance/product/propertyInsurance/?lang=en",
        },
        {
          title: "Engineering Insurance",
          link: "https://sarwa.insurance/sarwa-insurance/product/engineeringInsurance/?lang=en",
        },
        {
          title: "Motor Fleet Insurance",
          link: "https://sarwa.insurance/sarwa-insurance/product/motorFleetInsurance/?lang=en",
        },
        {
          title: "Marine Insurance",
          link: "https://sarwa.insurance/sarwa-insurance/product/marineInsurance/?lang=en",
        },
        {
          title: "Public Liability Insurance (PL)",
          link: "https://sarwa.insurance/sarwa-insurance/product/publicLiabilityInsurance/?lang=en",
        },
        {
          title: "Medical Insurance",
          link: "https://sarwa.insurance/sarwa-insurance/product/medicalInsurance/?lang=en",
        },
      ],
      for_details: "For more information visit ",
      // link: "https://sarwa.insurance/sarwa-insurance/product/retail/?lang=en",
      link: "https://sarwa.insurance/sarwa-insurance/?lang=en",
      // link: "/sarwa-insurance",
      img: "/images/sarwaInsurance.png",
    },
  },
  referral_program: {
    title: "Contact Referral Program",
    sub_title:
      "Nominate your friends and receive free points and exclusive discounts.",
    conditions: {
      title: "Contact referral Program terms and conditions",
      conditions_list: [
        "The candidate must be a contact registered customer.",
        "For existing Contact customers to obtain rewards points, new customers must make a contract for one of Contact products.",
        "For Contact Shopping and Contact Finishing only, nominated customers must complete at least one purchase transaction after credit approval and contracting so that existing customers can get reward points automatically.",
        "Existing Contact customers can filter their knowledge through the website, the Contact mobile application, the forms inside the branches, customer service centers or through the official social media platforms of Contact.",
        "A maximum limit for existing Contact clients is to nominate 10 new clients per month.",
      ],
    },
    program_benefits: "Contact Benefits",
    benefites_list: [
      "Existing Contact customers are rewarded by obtaining rewards points, where the number of points is determined according to the different Contact products.",
      "Rewards points can be used for purchases from Contact Rewards Program partners.",
      "The points can be used to pay Contact installments or administrative expenses.",
    ],
  },
  jobs: {
    title: "Jobs",
    content:
      "With the company's expansions, we are always looking to add bright calibers to our team, which includes more than 2500 employees. The company has a low turnover rate, with many of its managers continuing to work since its inception. We believe in developing our human capital to achieve our desired growth as individuals, teams, and institutions. If you would like to join us, please fill out the form.",
    jobs_categories: ["All", "IT", "Marketing", "Sales", "Accounting"],
  },
  application: {
    job_title: "Software Developer",
    job_desc:
      "We are looking for a distinguished programmer to join our team in developing and maintaining computer programs. He must have previous experience in developing programs and dealing with different programming languages. The programmer will work in a flexible work environment and must have the ability to withstand work pressure and work individually and in a team. ",
    to_apply: "If you would like to join us, please fill out the form.",
    resume_upload: {
      title: "Click or drag the file to this area to upload",
      sub_title:
        " Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.",
    },
  },
  news: {
    title: "Our News",
    minutes: " minutes reading",
    read_more: "Read more",
    share: "Share",
    news_list: [
      {
        id: 34,
        date: "05/29/2023",
        city: "Cairo",
        title: `Contact Financial announces 1Q-2023 results`,
        urlTitle: `كونتكت المالية تعلن نتائج أعمالها لفترة الربع الأول من عام 2023`,
        subTitle: `Contact kicked off the new year building on a strong 2022 to deliver solid financial and operational growth for the first three months of 2023`,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/defult-news-image.png",
        sections: [
          `Contact Financial Holding SAE (CNFN.CA), Egypt’s largest non-bank financial services provider, announced today its consolidated financial results for the three-month period ending 31 March 2023, reporting solid year-on-year operating income growth across its financing and insurance divisions of 28% and 43%, respectively. Meanwhile, financing net income fell by 14% while insurance net income rose by 35%, with consolidated normalized net income  recording EGP 178 million, representing a 3% year-on-year expansion.`,
          {
            img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-earning23.png",
          },
          `During 1Q-2023, Contact’s financing division saw new financing extended rise by 66% year-on-year to record EGP 4.1 billion. Strong year-on-year growth was primarily driven by Contact’s Consumer Finance, Auto, Commercial Trucks, and Working Capital products. Supported by the solid expansion in new financing, the division’s operating income expanded 28% year-on-year to reach EGP 462 million in 1Q-2023. Despite the robust growth in financing operating income, an increase in credit loss provisions booked to hedge against potential increases in credit risk during the ongoing economic turbulence weighed on the division’s net income for the quarter which declined 14% year-on-year to EGP 121 million. Meanwhile at the insurance division, robust results across both Sarwa Life and Sarwa Insurance saw total gross written premiums (GWP) reach EGP 437 million in 1Q-2023, representing a remarkable 73% year-on-year increase. Accordingly, insurance operating income was up 43% year-on-year to EGP 81 million, with the segment’s net income recording EGP 25 million in 1Q-2023, up 35% year-on-year.`,
          `Commenting on the results, management said: “We are pleased to report another strong set of financial and operational results which see us set the tone for the rest of 2023. Building on our impressive 2022 performance, we successfully delivered further growth across both the financing and insurance divisions while simultaneously pushing forward on our longer-term growth and value creation strategy. Our results in the first quarter of 2023 came in the midst of difficult domestic and global macroeconomic environments, further showcasing our company’s resilience and the effectiveness of our mitigation and investment strategies.”`,
          `“Looking at our results in more detail, key highlights from the past quarter include the return to robust growth at our Auto and Truck segments which partially benefitted from an initial recovery of the two markets following the easing of import restrictions earlier in the year. In parallel, we continued to witness noteworthy growth at our Consumer Financing segment, supported by a growing merchant network, a broader product offering, and increased market penetration. On the merchant network front, we were particularly happy to announce that earlier this year we secured multiple key partnerships with a number of leading vendors. In parallel, we continued to effectively ramp up our newly launched ContactNow app, which in the first three months of the year was responsible for 15% of total new lending extended for Contact CrediTech. The new app, which during the quarter more than quintupled the average number of daily downloads, is enabling Contact to reach a larger customer base, boost engagement and retention rates, and deliver an increasingly tailored user experience to new and existing customers. In parallel, our insurance division continued to ramp up at a remarkable pace, achieving impressive growth on the back of increased market penetration and cross-selling, as well as new product launches completed throughout the past twelve months,” management added.`,
          `On a product basis, the Consumer Finance and Auto Loans segments were the top performers during the quarter, delivering year-on-year new financing growth of 179% and 81%, respectively. Growth at the Consumer Finance segment was supported by an expanded direct-to-customer reach, a wider merchant network, and an enhanced digital presence. Building on the numerous new product rollouts conducted over the past year, the Consumer Finance segment has recently introduced financing services for furniture and home maintenance, further developing the segment’s product offering. At the same time, Contact is now present in 97 different physical locations across the country including a network of 79 full-fledged Contact branches and 18 strategically located Contact booths. Although growth at the Contact Auto segment came principally on the back of a general increase in car prices, volumes are also rebounding on a quarterly basis. At the Working Capital segment, which includes Contact’s short-term products under its factoring offering, new financing grew by 23% year-on-year supported by a growing number of new contracts as Contact focuses on serving a wider set of sectors including supply chains, industrial, medical, and transportation. In parallel, Contact Trucks’ new financing expanded by 11% year-on-year on the back of a wider distribution network and an increasingly diverse product offering. On the other hand, Contact’s Mortgage segment saw new financing extended drop by 37% year-on-year due to a slowdown in retail sales.`,
          `On the digital front, ContactNow, a rebranded and reworked version of the Contact App has ramped up strongly during the quarter. ContactNow is the first comprehensive financial platform in the market that provides a best-in-class user experience. The app provides a variety of services that cover the entire customer cycle, fulfilling a wide range of users’ financial needs. In addition to offering instant credit limit to new customers using AI – a revolutionary step in the market and beyond – the app allows users to manage their accounts, purchases, complete loan installment repayments, settle utility and mobile bills, and conduct a variety of other payments. The app will also provide users with savings and investment tools in the near future. Operationally, as of 31 March 2023, the app’s total registered users stood at 384 thousand, with over 80 thousand new registrations completed in 1Q-2023. Meanwhile, ContactNow recorded a 250% year-on-year increase in the number of instant credit approval requests submitted through the app during 1Q-2023.`,
          `“1Q-2023 also saw us successfully issue our 38th overall debt capital market issuance. During the quarter, Sarwa Securitization issued the market’s first ever Consumer Finance and Auto Credit Securitization Bond. The bond, which was issued amid a challenging macroeconomic environment was issued for EGP 1.9 billion, and was awarded a similar credit rating to our previous debt issuances. The success of our latest securitization issuance is a testament to our ability to successfully execute transactions during challenging market conditions enabling us to safeguard the Group’s liquidity requirements regardless of the ongoing macroeconomic challenges,” management continued.`,
          `Securitization in 1Q 2023`,
          `“In light of the strong start to 2023 and the solid strategy in place, our outlook for the financing division remains positive. The Egyptian automotive market is showing initial signs of recovery, with a steady month-on-month improvement in car sales.. At the same time, our increasingly diversified portfolio and wide physical and digital footprint, provide us with multiple effective tools to continue driving sustainable growth throughout 2023 and beyond. On the digital front, we are looking to continue ramping up the ContactNow app, to deliver on the ambitious targets we set for ourselves at the start of the year. We are also excited to explore the potential of our recently launched Lending as a Service (LAAS) initiative, a unique offering that sees Contact once again emerge as an innovator in the Egyptian market,” management commented.`,
          `At the insurance division, Sarwa Life’s GWP increased 101% year-on-year in 1Q-2023, reaching EGP 259 million. Growth was primarily driven by medical insurance, in addition to a growing contribution from the company’s SME life and health products. Meanwhile, Sarwa Insurance saw its GWP rise 44% year-on-year to EGP 178 million in 1Q-2023. The company’s expansion came on the back of an increased reach and market penetration.`,
          `Finally, the Contactcars.com platform continues to deliver on its growth strategy with the average number of monthly users reaching 1.3 million for 1Q-2023 and the number of registered users rising 50% compared to 1Q-2022. During the quarter, the platform’s classifieds segment played a central role in driving growth enabling Contactcars to capitalize on the positive momentum enjoyed by Egypt’s used car market. During the quarter, the classifieds segment underwent multiple product-related updates to simplify the selling process for used cars. Similarly, the portal’s car dealer business witnessed robust growth in 1Q-2023, with the number of subscribed dealers expanding by 245% year-on-year and revenue from subscribed dealers rising by 370% year-on-year during the three-month period.`,
          `“With nearly five months of 2023 now behind us, we remain on track to deliver on both our short- and longer-term targets for the year. Accordingly, our strategic priorities remain unchanged. Expanding and enhancing our digital capabilities continues to be at the top of our list. In parallel, we remain on the lookout for attractive investment opportunities to provide new services to our customers and new growth avenues for the company. In addition to expanding domestically, we have also set our sights on establishing a foothold in new regional markets. On that front, our newly-launched Kenyan affiliate, Almasi, is ramping up nicely, providing us with a solid base from which to expand further into the region and capture the attractive opportunities offered,” management concluded.`,
        ],

        bodyImages: ["", "", ""],
      },
      {
        id: 35,
        date: "02/15/2023",
        city: "Cairo",
        title: `Contact Financial announces FY-2022 results`,
        urlTitle: `تعلن شركة Contact Financial عن نتائج السنة المالية 2022`,
        subTitle:
          "Contact ended FY-2022 on a strong note, delivering solid financial and operational full-year growth in a challenging operating environment faced throughout the last twelve months; normalized net income  for 4Q-2022 stood at EGP 205 million, representing an impressive 38% year-on-year expansion",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/defult-news-image.png",
        sections: [
          `Contact Financial Holding SAE (CNFN.CA), Egypt’s largest non-bank financial services provider, announced today its consolidated financial results for the twelve-month period ending 31 December 2022, reporting strong year-on-year operating income growth across its financing and insurance divisions of 33% and 44%, respectively. Meanwhile, consolidated net income recorded EGP 511 million in FY-2022, up a solid 10% from last year’s figure, while normalized net income reached EGP 609 million, up 21% year-on-year from FY-2021`,
          {
            img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-earning-en.png",
          },
          `On a quarterly basis, in 4Q-2022 Contact’s financing division extended new financing of EGP 3.1 billion, up 61% year-on-year versus 4Q-2021. Strong performances at both the financing and insurance divisions during the final three months of the year saw Contact’s total operating income rise 48% year-on-on to record EGP 505 million in 4Q-2022. Strong growth trickled down to the Company’s bottom-line, with Contact recording normalized net income of EGP 205 million in 4Q-2022, up 38% year-on-year.`,
          `On a full-year basis, at the financing division, Contact extended new financing of EGP 11.4 billion in FY-2022, up 61% year-on-year primarily driven by Contact’s Consumer Finance, Working Capital, Commercial Trucks, and Mortgage products. This saw the financing division report operating income growth of 33% year-on-year to reach EGP 1,445 million in FY-2022. Meanwhile, financing net income recorded a 9% year-on-year expansion as robust growth across all revenue components more than outweighed the increase in credit loss provision booked to hedge against increased credit risk during ongoing economic headwinds. At Contact’s insurance division, total gross written premiums (GWP) achieved a notable 83% year-on-year expansion recording EGP 948 million at the end of FY-2022. As such, insurance operating income expanded by a strong 44% year-on-year to reach EGP 195 million, with insurance net income following suit and growing by 50% year-on-year to record EGP 54 million. Robust performances at both the financing and insurance divisions saw Contact’s total operating income for FY-2022 record EGP 1.6 billion, a 34% year-on-year increase versus FY-2021. Meanwhile, normalized net income  for FY-2022 also recorded impressive growth, expanding 21% year-on-year to reach EGP 609 million.`,
          `Commenting on the results, management said: “We are delighted to have ended the year on a high note, delivering strong growth and profitability across both divisions while simultaneously taking significant strides on our longer-term growth strategy. Our impressive results in the face of a challenging macroeconomic environment at home and across the world are a direct result of the strategies implemented over the last several years which have seen us strengthen the company’s resilience and growth generating capabilities. The success of our efforts was evident in our 2022 results which saw us continue to deliver robust growth at the consumer financing division despite significant challenges at our flagship auto loans segment resulting from macroeconomic challenges in our home market of Egypt. We were particularly pleased to see our continued investments to expand our physical and digital direct-to-customer reach and to broaden our product offering delivering the desired results, enabling us to tap into new segments, boost engagement and retention rates, and deliver an increasingly tailored and top-quality experience to new and existing customers. On a similar note, the ramp up of our insurance division continues to accelerate, generating remarkable growth throughout the year on the back of higher market penetration, increased cross-selling, and new product launches.”`,
          `On a product basis, the Consumer Finance and Working Capital segments were the top performers during the year, delivering year-on-year new financing growth of 328% and 346%, respectively. The Consumer Financing segment’s impressive growth was supported by Contact’s multi-pronged strategy aimed at expanding its direct-to-customer reach by setting up new branches at malls and merchant stores, expanding its merchant network through several strategic partnerships, and leveraging its enhanced digital capabilities to boost customer acquisition and transaction rates. These efforts were completed by a suite of new product roll outs including new tuition payment solutions for schools and universities, club memberships, home finishing solutions, as well as a new green financing product offering. At the Working Capital segment, which includes Contact’s short-term products under its factoring offering, new financing continues to grow supported by management’s efforts to diversify its offering to target a broader range of industries including the industrial and supply chain sectors. Contact’s Mortgage segment reported new financing growth of 88% year-on-year in FY-2022 on the back of a further streamlining of the application process and the continued ramp-up of its long-term, 10-year mortgage product. Contact Trucks saw new financing extended grow by 66% year-on-year on the back of a wider distribution network and a more diverse product offering. Finally, despite the persistent demand- and supply-side challenges faced throughout the entire year by Egypt’s passenger car market, new financing extended by Contact Auto rose 8% year-on-year reflecting increased car prices.`,
          `On the digital front, the Company has recently launched ContactNow, a rebranded and reworked version of the Contact App. ContactNow is the market’s first comprehensive, fully-fledged app offering a full suite of financial solutions. ContactNow provides customers with the ability to access Contact’s wide range of services using a single app, helping the Company to attract, retain and satisfy a growing pool of customers from a wide range of segments and geographic locations. As at year-end 2022, the app’s total registered users stood at 304 thousand, with more than 256 thousand new registrations completed throughout 2022 alone. Today, the app offers credit and bill payment solutions, as well as loyalty programs for customers. In the coming months, ContactNow is aiming to add payment and investment services to its roster, both of which are expected to go live in the second half of 2023. The operations of ContactNow are managed by Contact Creditech, a new, FRA-licensed entity set-up in 2022 to manage the Company’s digital consumer finance products.`,
          `“Our outlook for the consumer financing division in 2023 remains cautiously optimistic. On the one hand, we expect the Central Bank of Egypt’s recent policy changes will support the recovery of both the wider Egyptian economy and the country’s automotive market. On the other hand, we are confident that our robust portfolio, which is increasingly diversifying from auto loans and towards a wider range of products, and our expanded physical and digital footprint, will enable us to continue delivering solid growth and incremental value to our expanding customer base over the coming year. We are also excited to capture the full value-added of the ContactNow app which we expect to begin to materialize throughout 2023 as the app’s complete product offering is launched,” management added.`,
          `At the insurance division, Sarwa life’s GWP rose 162% year-on-year in FY-2022, reaching EGP 431 million. Growth was primarily driven by medical insurance, in addition to a growing contribution from the company’s SME life and health products. In parallel, Sarwa insurance recorded GWP of EGP 517 million in FY-2022, a 47% year-on-year expansion backed by increased market penetration and the roll out of Sarwa Insurance’s new medical insurance product and commercial lines of business.`,
          `Finally, the contactcars.com web portal continues to deliver on its ramp-up strategy with the average number of monthly users reaching 1.3 million for FY-2022 and new users acquired during 2022 increasing 61% compared to 2021. Throughout the year, Contact completed several enhancements to the platform’s financing application process, data collection and processing capabilities, homepage, and search function. Contactcars.com also recently introduced a digital maintenance booking service and further enhanced its AI pricing tool. Finally, contactcars.com has recently entered into a landmark partnership with Toyota Egypt Group which saw the data science team at contactcars.com develop a used cars pricing tool tailored to the specific requirements of Toyota Egypt, allowing the company to tap into the increasingly popular used car segment.`,
          `“While the challenges faced during 2022 are likely to persist throughout the upcoming year, we are confident that we have put in place a strong strategy to help us navigate the difficult operating environment and  continue to deliver strong results. As such, our priorities for 2023 remain unchanged. A key item on our list is the constant enhancement of our digital capabilities. On this front, we are looking to strengthen our digital offerings further with the addition of new features and services. We are particularly excited about the launch of ContactNow in January 2023, which represents a huge stride in our strategy of becoming a digital one-stop-shop covering our customer’s full financial lifecycle. At the same time, we remain on the lookout for attractive investment opportunities to provide new services to our customers and new growth avenues for the company. Alongside our domestic expansion, we are also keen to continue growing our footprint into new regional markets. Today, thanks to our newly launched Kenyan affiliate, Almasi,  and our market-leading Egyptian operations, we have a solid base from which to expand further into the region and capitalize on the attractive opportunities offered. We are also working to introduce several new products to the market, with a particular focus on expanding our green finance portfolio to make green energy solutions more accessible and affordable for our customers. At the same time, we will aim to also expand the product offerings and partnership networks of our more established segments, with a particular focus on building our presence across key, defensive sectors of the Egyptian economy,” management concluded.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 36,
        date: "06/11/2023",
        city: "Cairo",
        title: `Contact participates in African Medical Conference and Exhibition "Africa Health ExCon" to Deliver Innovative Financing Solutions`,
        urlTitle: `تشارك شركة كونتكت في المؤتمر والمعرض الطبي الأفريقي "Africa Health ExCon" لتقديم حلول تمويل مبتكرة`,
        subTitle: `"Remarkable participation from Contact in Africa Health ExCon "`,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/defult-news-image.png",
        sections: [
          `Contact Leasing, a subsidiary of Contact Financial Holding the leading non-banking financial services company, participated in the second edition of African Medical Conference and Exhibition "Africa Health ExCon", which was held o from 6 to 9 June 2023 at the Egypt International Exhibitions Center (EIEC), included various sessions reviewing steps to increase Africa's capacity towards vaccines production and various treatments, in addition to discussing the latest medical technological advancement to the Women health in Africa.`,
          `The participation of Contact Leasing in the exhibition assured its commitment towards providing innovative financing solutions that suit the needs of customers in the medical sector by financing medical equipment of all types and strengthening strategic partnerships with companies and institutions operating in the healthcare sector, aiming to improve the quality of services and enhancing health care in the community.`,
          `Contact Leasing focuses on financing fixed assets in various sectors, and under its umbrella falls multiple and comprehensive financing programs, the most important of which is medical equipment financing such as cosmetics and dental centers and clinics, laboratories, and hospitals in general and specifically as operating rooms, and ICUs, also Contact is taking the lead in financing medical equipment upgrade. `,
          `Contact also specializes in financing transportation for all other commercial activities such as schools, malls, tourism companies, and clubs, by financing passenger vehicles, buses, trucks even concrete mixers, as well as means of distributing and delivering goods such as motorcycles, and tricycles, Contact leasing was especially known for the easy procedures and speed of implementation.`,
          `Mr. Ismail Samir, Managing Director of Contact Leasing, said, "We at Contact Leasing believe in the importance of the customer and that we consider all our customers are our success partners, so we recently introduced the service of financing all types of fixed assets other than medical equipment and transportation, that our customers need to develop their businesses and achieve their potentials, including heavy and agricultural equipment, medical and administrative units, and we also added solar energy to our financing, believing in the importance of sustainability and keeping pace with the social development. We have an edge in the leasing market by having fixed interest rates and not floating throughout the contract period, regardless of the corridor price, as we always strive to meet the needs of our customers and provide optimal financing solutions that are tailored to their unique needs."`,
          `Mr. Said Zater, CEO of Contact Financial Holding, said, "We consider ourselves as true partners to our customers, as we strive to provide various financing services, and thanks to our qualified and specialized team, we are able to provide innovative and easy financing solutions to our customers, including financing all types of fixed assets. Contact Leasing plays a vital role in our group of companies, and we are committed to providing optimal and easy leasing services to our customers with the ease and speed that the Contact customer has always been accustomed to, and I assure you that we always strive to achieve the highest levels of quality and efficiency in everything we do to keep pace with market developments and needs."`,
          `Contact Leasing is one of the finance arms of Contact Financial Group, which joined it in 2015, in order to provide a comprehensive, non-traditional range of financial products to keep pace with the continuous development in the non-banking financial services market, and to meet the needs of diverse corporate customers, especially SMEs such as doctors, pharmacists, and others. The leasing service has many advantages, such as providing the necessary capital to invest in the assets and equipment necessary to succeed in the business And reducing the financial burden on the buyer when they need to purchase the equipment at high prices.`,
          ``,
        ],
        bodyImages: ["", "", ""],
      },

      {
        id: 16,
        date: "02/07/2023",
        city: "Cairo",
        title: `Contact and Global Auto start a consumer finance joint venture to provide exclusive services to BMW and MINI customers`,
        urlTitle: `بدأت شركة Contact وGlobal Auto مشروعًا مشتركًا لتمويل المستهلك لتقديم خدمات حصرية لعملاء BMW وMINI`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/16.jpeg",
        sections: [
          `Contact Financial Holding, the leading non-banking financial services company, and Global  Auto Group, the official dealer for BMW and MINI in Egypt, announced the establishment of a new consumer finance company to provide financing services to BMW and MINI customers in Egypt, as part of Global Auto's new strategy to meet all the needs of its customers and provide all services under one roof.`,
          `In addition, Global Auto Group customers can exclusively benefit from the comprehensive insurance programs and services provided by Contact Insurance Brokerage, at competitive rates and in simple procedures, with the presence of Contact representatives in the nearest Global Auto showrooms and service centers to facilitate the procedures for obtaining various insurance programs for BMW and MINI customers. And meet all their needs and also respond to their inquiries, in addition to the availability of the same services in more than 75 branches of "Contact" throughout Egypt.`,
          `Old BMW and MINI owners will also be able to enjoy the new insurance solutions and programs offered by Global Auto in collaboration with Contact Insurance Brokerage, as long as their vehicles have valid insurance coverage from any other insurance company.`,
          `Mr. Saeed Zaatar, CEO of Contact Financial Holding, said: "We are proud of this strategic partnership with Global Auto Group, which reflects the confidence in our high-quality financing and insurance solutions in the Egyptian automotive market. The new consumer finance company offers exclusive and affordable financial solutions to customers of both luxury brands, which will undoubtedly ensure a hassle-free ownership experience that suits the needs of a wide range of customers, and we are also very pleased to take over all the insurance activities of Global Auto Group, and meet all the insurance needs of BMW and MINI customers immediately and at competitive prices to ensure that they enjoy driving their cars with confidence and trust because there is an insurance partner they can trust and rely on."`,
          `In a related context, Mr. Mohamed Kandil, CEO of Global Auto Group, commented: "I am delighted to announce the new partnership between Global Auto and Contact to provide all comprehensive car financing and insurance services to our loyal customers, which is part of our group's strategy that includes providing all automotive related services to our customers under one roof. Contact presence inside our showrooms and service centers distributed across the country will reflect into a high-end and distinctive ownership experience without hassle that enhances customer loyalty to our BMW and MINI luxury brands."`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 9,
        date: "01/22/2023",
        city: "Cairo",
        title: `JumiaPay partners with Contact Creditech to expand access to financial services in Egypt.`,
        urlTitle: `شراكة بين "جوميا باي" و"كونتكت كريدي تك" لإطلاق خدمات مالية جديدة في مجال الخدمات المالية في مصر.`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed%20Samir%20Kamel.jpg",
        sections: [
          `Jumia, the leading e-commerce platform in Africa, and Contact Creditech, the consumer financing arm of Contact Financial Holding, announced today a collaboration to launch a new group of financial services for consumers in Egypt.`,
          `This partnership will enable Jumia to offer different payment methods to simplify the buying process and create a variety of financial solutions for the customers of both companies.`,
          `This is why Contact Creditech has worked on several axes to keep pace with recent changes, and these axes include strategic partners at all levels in various fields to allow the use of digital credit assessment based on artificial intelligence technology and linking the multiple systems of success partners to achieve the best customer experience.`,
          `"At Contact Creditech, we always strive to find financial solutions that are in line with customer needs and, at the same time, give the best customer experience. Through this partnership, we will provide all Jumia customers with financial solutions aimed at enabling customers to complete the purchase process with ease using the latest technological solutions in the field of consumer financing, which will help increase purchasing power," said  Mr. Ahmed Samir Kamel, Managing Director of Contact Creditech.`,
          `"We are proud of our leadership in the Egyptian market, as Jumia and JumiaPay are always striving to provide unprecedented services to their customers, whether existing or new and through our strategic partnership with Contact Creditech, we will help our customers to have a new and improved purchase experience, especially since Contact financing services will be available to all Jumia customers, regardless if they are current customers of Contact or not, indicating that Jumia always seeks to meet all the needs of its customers through one platform and that it is necessary to continuously develop the services available to ensure a unique experience for customers, which brings us to our agreement today with Connect," Mr. Abdellatif Olama, Vice President of JumiaPay, Egypt.`,
          `"I am proud today of this partnership with Jumia and JumiaPay as a partner in success, especially since we share the same goal, which is that the customer always comes first. Through this partnership, the needs of the platform users will be met, whether they deal with the banking system or not. What sets Contact apart is the fast response to all the customer's financial needs and providing services in simple ways, which contributes to facilitating the workflow and serving the customer to the fullest extent, and this comes from our extensive experience in the field of financing in Egypt, which began more than 20 years ago," Mr. Saeed Zater, CEO of Contact Financial Holding.`,
          `About Jumia

          We believe that technology has the potential to transform everyday life in Africa, for the better. We built Jumia to help consumers access millions of goods and services conveniently and at the best prices while opening up a new way for sellers to reach consumers and grow their businesses.
           
          Jumia is a leading e-commerce platform in Africa. Our marketplace is supported by our proprietary logistics business, Jumia Logistics, and our digital payment and fintech platform, JumiaPay. Jumia Logistics enables the seamless delivery of millions of packages while JumiaPay facilitates online payments and the distribution of a broad range of digital and financial services.
          `,
          `About Contact

          Contact Financial Holding (Egyptian stock code CNFN,CA) is a leading group in providing non-bank financial solutions and services, managing a group of companies that provides a full range of financing and insurance products and services. Contact adopts an innovative approach to ensure the provision of its services of the highest quality in the fields of finance and insurance, with the easiest process to reach a wide segment of the market.
          Through Contact credit, Contact offers various financing programs to finance the purchase of new and used cars and vehicles, durable goods financing programs, in addition to the home finance and finishing programs through Contact Mortgage and trade finance programs through its sister companies Contact Leasing and Contact Factoring. Contact also provides insurance services through Sarwa Insurance and Sarwa Life Insurance. The company is also the first and largest issuer of securitization bonds in various fields in the Egyptian market. Contact Financial Holding Company LLC. Is licensed and regulated by the Financial Regulatory Authority.
          `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 27,
        date: "07/18/2023",
        city: "Cairo",
        title: `"Al Safy Group" and "Contact" offer free insurance for "Mobilaty" devices for the first time in Egypt`,
        urlTitle: `"الصافي" و"كونتكت" يقدمان التأمين المجاني لأجهزة "موبايلاتي" ضد الغرق والكسر والحرق`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/defult-news-image.png",
        sections: [
          `Contact Insurance Brokerage, a subsidiary of Contact Financial Group, and Mobilaty, a subsidiary of Al Safy Group for Import, Export, and Commercial Agencies, signed a partnership agreement, the first of its kind in the Egyptian market, to provide free insurance for the devices and phones of "Mobilaty" customers for a period of one year, including comprehensive coverage for external damages such as breaking, fire, and drowning.`,
          `Under the agreement, a free insurance policy will be activated for a full year for each buyer of phones and electronic devices from Mobilaty's 20 branches across Egypt, in addition to Mobilaty's online shopping platform, which provides a wide range of products, as smartphones, electronics, and accessories from the world's best-selling brands.`,
          `Contact Insurance Brokerage took the lead in handling all insurance matters for Mobilaty's customers, in addition to insuring the assets and properties of Al-Safi Group and its projects in the fields of distribution, trade, electronics, retail, food and beverage, telecommunications and logistics, oil and gas, and real estate development.`,
          `Khaled Fahmy, Executive director of Mobilaty, a subsidiary of Al Safy Group, revealed that this agreement and the unique services it provides to Mobilaty customers assures the company's keenness to provide the best solutions and services to its customers, in a way that supports sales to lead the market undisputedly, and this comes in line with the company's expansions and its intention to open two new branches to reach 22 branches by the end of 2023.`,
          `For her part, Nihal Break, Chairman and Managing Director of Contact Insurance Brokerage, expressed her happiness with this partnership, which she considers a successful opportunity for all parties, as she said: "The cooperation with Al-Safi Group came within the framework of Contact Insurance Brokerage's continuous plan to expand its business and services in the Egyptian market, and its keenness to deal with companies in all fields. The company seeks to help its customers obtain insurance coverage related to all topics and affairs of their lives, while Al Safi Group seeks to reaffirm its commitment to its customers and provide them with the best and unprecedented services."`,
          `For her part, Reem Safy, CEO of Al Safy Group, said that the cooperation with Contact Insurance Brokerage, one of the leading and distinguished companies in the insurance sector, reflects the group's keenness to provide the best services to its customers, especially after-sales services, where the exclusive provision of free insurance for Mobilaty's customer devices, to become the best choice in Egypt at all.`,
          `She stressed that the strength and confidence enjoyed by "Contact" will be reflected in the products and devices of "Mobilaty" to become the first in the Egyptian market, and "Al Safy Group” was also keen to benefit from the insurance services of "Contact" insurance on the assets and properties of Al Safy Group to ensure sustainable growth and expansion with confidence and safety for the various projects of Al Safy Group.`,
          `Said Zater, CEO of Contact Financial Group, said: " This partnership reflects the distinguished services provided by the group to its customers, since our first day in the Egyptian market, we have been committed to an ambitious strategy whose first goal is our customers, and this strategy is reflected in our unique products, as well as on the expansions that we target to be the closest to our customers, which enabled us to build a huge customer base that benefits from our services and products through our various sales channels, and most importantly give them reassurance. And tranquility, especially knowing that there is a protection and safety net for their products free of charge for a year."`,
          `About Al Safy Group

         Established in 2020, Mobilaty, a subsidiary of Al Safy Group, is a leading multi-brand chain of stores through 20 outlets across the country, in addition to its online platform that offers a wide range of home appliances, mobile phones, laptops, games, accessories, IoT equipment, and smart home technologies to meet all the technological needs of all customers. 
         Al Safy Group was founded in 1985 with a national vision to become an industrial leader in managing diversified investment portfolios in Egypt. The group has achieved a widespread scope of comprehensive coverage of its customers in more than 5 countries including Egypt, China, the United Arab Emirates, the USA, Sudan, and South Korea. Al-Safi Group has established itself in many diverse sectors over the past years and has sought to achieve attractive investment opportunities and strategic participation to take responsibility today for our customers, partners, and the entire Egyptian society with a national vision that cherishes the love of the homeland and seeks to contribute to social, economic and environmental prosperity as a major contributor to the revitalization and prosperity of the Egyptian economy.
         `,
          `About Contact

         Contact Financial Holding SAE (CNFN.CA) is Egypt's largest non-bank financial services provider, changing how people and businesses access finance and insurance. Operating since 2001, Contact adopts innovative approaches in extending its services, offering quality services with simple procedures, and reaching a wide client base through its various subsidiaries, affiliates, and partners. Contact’s financing division offers market-leading services including consumer financing for new and used passenger and commercial vehicles and an array of consumer durables through Contact Credit, home finishing through Contact Mortgages, as well as commercial finance through Contact Leasing and Contact Factoring. Contact operates in insurance through Sarwa Insurance and Sarwa Life Insurance. Contact also offers an array of corporate financing services including securitization, structured debt, and debt investment management. Contact Financial Holding SAE is authorized and regulated by the Financial Regulatory Authority (FRA).
         `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 11,
        date: "07/1/2023",
        city: "Cairo",
        title: `For the first time in the Consumer Finance & Fintech Market: Contact & Telda come together to offer a unique Lending-as-a-Service to serve all Telda customers`,
        urlTitle: `Fintech لأول مرة في سوق التمويل الاستهلاكي ومجال ال`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Ahmed%20El%20sabbah%20CEO%20Telda.jpg",
        sections: [
          `Contact CreditTech, the digital consumer financing arm of Contact Financial Holding, and Telda announced their recent collaboration to offer a unique Lending-as-a-Service solution to all Telda's customers, which will enable Telda users who are not yet consumer finance customers to apply and instantly obtain their credit limit approval, in addition to the normal approach of enabling finance to Contact customers who are using Telda.`,
          `Lending-as-a-Service solution will enable Telda users to apply and obtain an instant credit limit provided by Contact to finance their purchases made with Telda. Customers can repay it over a tenure of up to 60 months, increasing purchasing power and allowing better control over their expenses.`,
          `In this regard, Ahmed Samir Kamel, Managing Director for Contact CrediTech commented: “The key cornerstone we always rely on is the digital transformation and the use of the latest technologies in deploying our innovative lending solutions. Partnering with Telda is another step toward providing our partners the most advanced financing services. Contact has this unique Lending as a Service model, being the only provider in the market enabling us to ensure the inclusion of the widest customer base across all segments in addition to serving different use cases based on our partners’ needs”. The norm is to avail financing for customers who already have a consumer finance limit/account; however, the beauty of the Contact model ensures that everyone is included. We were able to reach this stage with our extensive experience in the field and because we are always keen to provide all that is innovative and different, we have succeeded in availing the latest financing tools such as LaaS, which created added value for both the customers and our partners”.`,
          `Ahmed Sabbah, CEO of Telda, said: “We are thrilled to partner with Contact to bring the Lending-as-a-Service solution to our customers. This collaboration enables us to provide instant credit options and flexible installment plans, empowering users to enhance their purchasing power and effectively manage expenses. At Telda, we are committed to innovation and delivering valuable financial solutions to our customers, and this partnership aligns perfectly with our mission.”`,
          `Said Zater, CEO of Contact Financial Holding, has expressed his pride in the major steps that Contact continues to achieve one after the other. He said, “We will always use our expertise in the field to fill the gaps and deliver the best products and services consumers need. Through Telda's collaboration, we are expanding our services to Telda’s customer base using the latest technologies in the lending sector. We lead the market and serve our partners with our unique lending-as-a-Service solution, and I am confident our lead to deliver what is new and different is inevitable as our focus is on availing innovative lending solutions that no other player provides and expanding it through our partnerships”.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 15,
        date: "06/14/2023",
        city: "Cairo",
        title: `"Forbes Middle East places Contact Financial Holding on Egypt's Top 50 Listed Companies for the second year in a row`,
        urlTitle: `فوربس الشرق الأوسط تضع كونتكت المالية القابضة على قائمة أقوى 50 شركة في مصر لعام 2023 للعام الثاني علي التوالي`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/15.jpg",
        sections: [
          `Forbes Middle East has selected Contact Financial Holding, the leading non-banking financial services company, among Egypt's Top 50 Listed Companies the year 2023 for the second year in a row, the selection in Forbes lists, known globally for its credibility, reflects the quality of the Contact's business, the uniqueness of its business model and its outstanding achievements in the field of non-banking financial services, as Contact is the largest player in the consumer finance market in Egypt According to the Financial Regulatory Authority, it was also the company that obtained license No. 1 for consumer finance in Egypt.`,
          `Mr. Said Zater, CEO of Contact Financial Holding, said, "We are proud to be ranked among Egypt's Top 50 Listed Companies for two consecutive years, and we look forward to continuing to develop our services and provide efficient and innovative financing solutions to our customers." He added: " Our deep understanding of  our customers’ needs and our access to them by all available means is our way to success, and data analysis and modern technology are the main pillars of this understanding. We have worked hard to expand in Egypt by providing financial solutions that suit all categories in every governorate of Egypt, in addition to the digital transformation efforts that also played a major role in this success, in addition to our focus in working with startups and developing financing solutions that contribute to the growth of these companies in the Egyptian market.`,
          `Forbes Middle East has collected data from the Egyptian Exchange and ranked companies based on their reported sales, assets, and profits for 2022 and market value as of April 30, 2023. Each metric was given equal weight, and companies with the same final scores were given the same rank, Currency exchange rates were taken as of April 30, 2023.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        // id: 10,
        id: 37,
        //random date
        date: "05/01/2023",
        city: "Cairo",
        title: `Orange Egypt signs a cooperation agreement with Contact to provide customers with consumer finance services and exclusive installment offers`,
        urlTitle: `اورنچ مصر توقع اتفاقية تعاون مع شركة كونتكت لتزويد العملاء بخدمات التمويل الاستهلاكي وعروض التقسيط الحصرية`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/defult-news-image.png",
        sections: [
          `Orange Egypt signed a cooperation agreement with Contact Creditech, the digital consumer finance arm of Contact Financial holding, to provide Contact Creditech services and features to Orange customers through the MyOrange mobile app, which provides extended installment programs without down payment or interest with payment facility through the "Orange Cash" wallet. `,
          `The signing of the partnership agreement was witnessed by Eng. Yasser Shaker, CEO of Orange Egypt, Mr. Said Zater, CEO and MD of Contact Financial Group, and several leaders of the two companies.`,
          `The signing ceremony was signed by Mr. Noël Chateau, Chief Transformation & Operational Efficiency Officer, and Mr. Ahmed Samir Kamel, Managing Director of Contact Creditech.`,
          `The partnership allows Orange customers to benefit from the installment services and programs provided by Contact Creditech with up to 60 months repayment tenure and exclusive interest-free and non-down offers over a repayment tenure of up to 24 months.`,
          `Moreover, as part of the partnership, the Orange Cash wallet will be added to ContactNow app in cooperation with Contact Pay services to be used to pay Contact installments at exclusive and competitive fees.`,
          `Commenting on the signing, Mr. Noël Chateau, Chief Transformation & Operational Efficiency Officer at Orange Egypt, stressed that the new agreement consolidates the company's efforts to ensure the provision of unprecedented services to its existing and new clients and benefit from the latest digital and technological services in the Egyptian market, and enhances the deployment of digital financial services in Egypt through various cooperations with leading companies such as Contact Creditech, which provides customers with an easy, fast, and attractive shopping experience with the best financial solutions.`,
          `In this context, Mr. Ahmed Samir Kamel, MD of Contact Creditech, expressed his happiness with this fruitful cooperation with one of the largest telecommunications operators in Egypt with the aim of providing the best financing solutions to the Egyptian consumer based on the latest technologies and digital transformation mechanisms, which provides easy, simple, and fast/instant payment methods.`,
          `He added, "The services currently provided are only the beginning of a strategic cooperation between the two companies, with future plans to expand in providing innovative financing programs to include everything new and different to meet the Egyptian consumer needs, as the cooperation targets all Egyptians, whether they are existing Orange or Contact customers or even new customers, and installment programs will be available up to 60 months with exclusive offers between the two companies at the launch of the partnership." `,
          `Mr. Said Zater, CEO and MD of Contact Financial Group, said, "We are proud to contract with Orange as a partner of success and said: "Each entity separately has the best extensive experience in the field in which it specializes, Contact has been the leader among non-banking financial services companies for more than 20 years and is the first consumer finance company in Egypt, and Orange is one of the largest telecommunications operators in Egypt, with the collaboration of the expertise of each entity, we will be able to create advanced financing solutions that are easy and relevant and directed to each customer based on his own needs and I’m totally confident in the teams that we will provide exceptional service to the largest customer base, and this is what Contact always pursued since its founding is to provide the best financing solutions that enable customers to fulfill their need and wants, despite the current economic situation, We have a strong belief in our ability to utilize our strength in this field to overcome any obstacles that may occur in the market.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 3,
        date: "09/21/2023",
        city: "Cairo",
        title: `Contact, the Best Digital Transformation Fintech in Egypt in 2022`,
        urlTitle: `"كونتكت" توقع اتفاقية جديدة لدفع الفواتير من خلال "إي-خالص"`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Contact%20and%20E%20khales.jpg",
        sections: [
          `In its endeavor to realize the financial inclusion of all Egyptian consumer segments, Contact did opt for a geographical expansion, and is now operating in 25 governorates through a network of 60 branches, covering many subregions and rural areas. Contact has been the Fastest Growing Finance Company in Egypt in 2021, according to the specialized British magazine: The Global Economics. This expansion aims to fulfill the needs of the majority of Egyptian consumers in the different regions, where Contact is providing a fair and efficient consumer finance service based on a thorough study of the demographic and social context, as well as the consumer behavior in each region.`,
          `According to Contact vision based on pioneership, and continuous and sustainable development, Contact has succeeded – in parallel with its geographical expansion – to manage a substantial digital transformation of its services, and has been selected as the Best Digital Transformation Fintech in Egypt in 2022 by The Global Economics.  Contact has built on its long and successful credit history and developed an Artificial Intelligence engine for instant credit assessment of individual customers. Contact aims to realize efficiency and provide a fair-credit at the same time, in the domain of Fintech.`,
          `Contact Model is an omnichannel model, using digital and non-digital channels in order to reach every Egyptian citizen. Contact applies customer education in the domain of digital transformation, incentivizing customers of all channels to use Contact mobile app, and hence contributing to the digital transformation of the whole society, in parallel with its contribution to the financial inclusion of the whole population, in a perspective of sustainable development.`,
          `Glad to receive this award, Said Zater - Contact Financial Group CEO and MD of the Holding Company - said: “This award of the Best Digital Transformation Fintech in Egypt in 2022, provided by The Global Economics, is an acknowledgment of Contact outstanding achievement in terms of expansion and service diversification, creative product development, merchant network development, and fruitful strategic alliances.” `,
          `It is noteworthy that Contact Financial Holding has been selected one of the strongest 50 business institutions in Egypt in 2022 by Forbes Middle East.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 40,
        date: "02/12/2024",
        city: "Cairo",
        title: `Strategic Partnership between Contact and Al Ahly Momkn to Enhance Payment Services in Egypt`,
        urlTitle: `شراكة استراتيجية بين كونتكت والأهلي ممكن لتحسين خدمات الدفع في مصر`,
        subTitle: ``,
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/AhlyMomken%20photo.jpg",
        sections: [
          `Today, Contact Pay, a leading company in electronic payment solutions and a subsidiary of Contact Group, a prominent player in the non-banking financial services sector, announced the signing of a strategic partnership agreement with "Al Ahly Momkn," a key player in the field of e-payment solutions and financial services, to facilitate payment operations for Contact's customers in Egypt by providing the option to pay Contact installments through Al Ahly Momkn's multiple payment channels. The agreement was signed by Eng. Ahmed Abdel Hakim, the Managing Director of Contact Pay, on behalf of Contact, while Eng. Ahmed Enayet, the Managing Director and CEO of Al Ahly Momkn, signed on behalf of their company. `,
          `This partnership reflects a shared vision between the two companies to facilitate payment operations for customers through Al Ahly Momkn's branches and points of sale located throughout Egypt, with more than 90,000 points of sale and over 5 million users. It is worth noting that Contact has a geographical presence in most Egyptian governorates, providing its services with comprehensive coverage and professionalism in both urban and rural areas. This partnership represents a strategic integration between Contact Pay as a specialized company in payment solutions and Al Ahly Momkn as a major provider of electronic payment solutions and points of sale across Egypt, particularly in rural areas, enhancing the ease and speed of access for customers to Contact's services through Al Ahly Momkn's extensive coverage. `,
          `In his statement regarding the agreement, Eng. Ahmed Abdel Hakim, the Managing Director of Contact Pay, affirmed, "We are excited about this strategic partnership with Al Ahly Momkn, which comes in line with our commitment to providing payment services with the highest standards of security and efficiency. This agreement opens up new horizons for customers to conduct their financial transactions smoothly and easily nationwide." `,
          `On his part, Mr. Mohab Gamal,  Deputy CEO of  Al Ahly Momkn, stated, "This partnership reflects our dedication to providing seamless and innovative payment experiences. We are confident that this step will enhance our customers' ability to access Contact's services more easily and quickly through our branches and POSs spread across the country." `,
          `Furthermore, Eng. Ahmed Enayet, the Managing Director and CEO of Al Ahly Momkn added that the collaboration with Contact complements their expansion strategy and provides diversified services, reinforcing secure, easy, and fast payment services for consumers in the Egyptian market. `,
          `In conclusion, Mr. Said Zater, the CEO of Contact Financial Holding, expressed his vision for the future, stating, "This step aligns with our vision to achieve a cashless financial society, as we continuously strive to provide innovative and sustainable solutions to fulfill our customers’ needs. We believe in integrating financial services with modern payment technologies to better serve our customers." `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 41,
        date: "02/27/2024",
        city: "Cairo",
        title: `Contact Financial Holding’s CEO participates in the 8th Capital Markets Annual Summit`,
        urlTitle: `«رئيس كونتكت المالية القابضة» يشارك في فعاليات النسخة الثامنة لقمة أسواق المال`,
        subTitle: (
          <>
            <p>
              A unified exchange rate is the solution to consumer finance
              challenges
            </p>
            <p>Consumer finance supports economic growth</p>
          </>
        ),
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Capital%20MArkets%20photo.jpg",
        sections: [
          `Mr. Said Zater, CEO of Contact Financial Holding and Chairman of the Egyptian Federation of Consumer Finance, participated in the 8th edition of the Capital Markets Annual Summit, held under the theme of "Structural Reforms - Sustaining Development", which is held under the auspices of Ministry of Finance, Ministry of Planning and Economic Development, the Financial Regulatory Authority, and other entities. The summit discussed the structural reforms being undertaken by the Egyptian government across all economic sectors in terms of investment and sustainability. `,
          `During the second panel titled "Corporations and the Challenges of Managing Cost of Financing ," Said Zater stated: "Consumer finance is one of the most important economic sectors in Egypt, as it touches the lives of  everybody, contributes to stimulating consumption, and supports economic growth. It is the future we will  all be living. The issuance of the consumer finance law in 2020 has opened up serious opportunities for companies to enter and play a significant role, especially in light of crises such as COVID-19 and exchange rate fluctuations." He added: "Consumer finance differs from other types of financing such as leasing, for example, as it is not possible to impose a floating interest rate on the customer.” `,
          `Zater also discussed consumer behavior, where customers may use consumer finance to purchase goods, they may not urgently need for fear of price increases in the future, or for savings purposes due to inflation, leading to difficulties in paying the required installments. `,
          `Zater said that "Contact" started in 2001 as the first finance company in Egypt, and started issuing securitization bonds in 2005 as the first issuer of securitization bonds in Egypt, and later issued sukuk, until it became one of the largest issuers of bonds and sukuk in Egypt. He added that the company needs significant liquidity to finance its operations, so it resorts to all financing tools, as reliance solely on banks is not feasible, so we are looking for the financing needs to reach the required liquidity. `,
          `Regarding Ras El Hekma deal, Zater anticipated that exchange rate stability would attract investors, adding that efforts should be made to stabilize the exchange rate and employ resources to maintain a healthy market that enables companies to view the Egyptian economy optimistically. `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 42,
        date: "01/23/2024",
        city: "Cairo",
        title: `Contact's Green Financing Expansion: Unveiling the Electric Car Financing Program`,
        urlTitle: `كونتكت تتوسع في التمويل الأخضر: إطلاق برنامج تمويل السيارات الكهربائية`,
        subTitle: (
          <>
            <p>Empowering the Future: Contact's Financing for Electric Cars</p>
            <p>
              Driving Change: Contact's Support for Clean Energy Transition and
              Sustainability
            </p>
          </>
        ),
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/electric%20Cars%20Photo.jpeg",
        sections: [
          `Contact Financial Holding, the leading non-banking financial services provider, has announced the launch of an innovative new financing program aimed at financing electric vehicles. This new product comes as part of the company's commitment to environmental sustainability and supporting the transition to a greener future as the world is currently experiencing enormous environmental challenges because of the effects of air pollution and climate change, and among the main solutions to these challenges is to encourage the use of electric vehicles. Electric vehicles are a sustainable and clean alternative to traditional fuel-powered cars. They are powered entirely by sustainable electric power, reducing carbon dioxide emissions and air pollution.  `,
          `The use of electric vehicles contributes to achieving environmental sustainability goals and reducing negative environmental impact. `,
          `The new product features a wide range of features designed specifically to meet the needs of customers and facilitate the process of acquiring electric cars, with a down payment starting from 20%, optional insurance, repayment periods of up to 5 years, and the possibility of full or partial early repayment starting from the first month without early settlement fees. Contact is also currently studying the possibility of financing electric trucks and electric motorcycles, considering the global shift towards environment awareness, and reducing environmental hazards, as electric transportation is an innovative and promising solution in reducing carbon emissions and improving air quality in cities. `,
          `Mr. Tamer Samir, Managing Director of Contact Credit, said: "We are proud to launch our new electric vehicle financing product. Where this product can play a crucial role in improving the customer experience, this product is a future investment and an opportunity to attract more customers. Financing electric vehicles is also a good opportunity to grow and expand in the automotive market. `,
          `Samir also stressed that we are keen to meet the needs of customers and provide them with suitable financial solutions to facilitate the financing process. We are committed to providing excellent customer experience and meeting their expectations and needs, providing comprehensive information about electric vehicles and their features, and ensuring outstanding and effective after-sales support. `,
          `Mr. Said Zater, CEO of Contact Financial Holding, added, "We believe in the environmental and economic value of sustainability. Through the Electric Vehicle Finance Product, we seek to empower individuals and communities to realize their vision of a sustainable and clean future. We recognize that sustainability is not just the responsibility of individuals, but also the responsibility of companies. Therefore, we are proudly announcing that we have adopted a range of initiatives aimed at supporting environmental sustainability and launched the Electric Vehicle Finance product alongside the Green Finance product that we launched at the end of 2022. also, we at Contact provide a variety of financial options that suit all customers’ needs, and this is done through Contact branches spread throughout Egypt, we do our best to develop comprehensive financial solutions while maintaining flexibility and convenience. `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 43,
        date: "03/14/2024",
        city: "Cairo",
        title: `  "CONTACT" AND "OPAY" COLLABORATE TO PROVIDE INNOVATIVE PAYMENT SOLUTIONS AND ENHANCE CUSTOMER EXPERIENCE`,
        urlTitle: `"كونتكت " و"OPay" تتعاونان لتقديم حلول دفع مبتكرة وتحسين تجربة العملاء`,
        subTitle: (
          <>
            <p>
              "CONTACT" AND "OPAY" COLLABORATE TO OFFER COMPREHENSIVE DIGITAL
              PAYMENT SOLUTIONS.
            </p>
            <p>
              "CONTACT" EXPANDS ITS PRESENCE IN THE EGYPTIAN MARKET THROUGH A
              PARTNERSHIP WITH OPAY.
            </p>
          </>
        ),
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/OPay%20photo.jpg",
        sections: [
          `Contact Pay, a leading payment solutions provider and subsidiary of Contact Financial Holding, a prominent non-banking financial services company, has recently signed a strategic cooperation agreement with OPay, a leading fintech solutions and electronic payments company. This agreement aims to enhance cooperation between the two entities, offering comprehensive digital payment solutions to cater to diverse customer needs and significantly enhance their overall experience. `,
          `Under this agreement, OPay will serve as a new acquisition arm for Contact, expanding its physical presence and customer reach throughout Egypt. This partnership will enable Contact to leverage OPay's extensive network of over 90,000 POSs and payment kiosks spread across the country. `,
          `Amr Gohar, Head of Business Development at OPay, commented on the agreement, stating: "Partnering with Contact Pay is a strategic move to enhance digital payment experiences for both consumers and businesses. This partnership underscores OPay's commitment to driving fintech innovation in Egypt and collaborating with all stakeholders in the financial sector." OPay offers a range of innovative digital payment solutions, including contactless payment, online payment, and AI-powered digital wallet services. These solutions will enable Contact customers to enjoy a seamless, easy, and secure payment experience, eliminating the need for cash or physical bank cards. `,
          `Ahmed Abdel Hakim, Managing Director of Contact Pay, expressed his satisfaction with the strategic partnership, noting: "We are delighted to partner with OPay, which will significantly enhance our payment services, improve customer experiences, and streamline payment collection by introducing a new payment method to our portfolio." He emphasized Contact's commitment to customer convenience and simplifying financial transactions. `,
          `Mahmoud Khedr, CEO of OPay, also expressed enthusiasm for the partnership, highlighting its importance in advancing digital payments in Egypt. "OPay is dedicated to providing cutting-edge technologies and solutions in fintech. This partnership reflects our commitment to delivering innovative payment experiences." He emphasized the potential for innovation and growth in the digital payment sector through combined expertise and efforts. `,
          `Lastly, Said Zater, CEO of Contact Financial Holding, remarked: "This partnership is a strategic step towards realizing our vision of enhancing electronic payment experiences in Egypt. We aim to provide innovative payment solutions that align with customer expectations, strengthening our position in the non-banking financial services sector and promoting financial technology in the Egyptian market." `,
          ``,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 44,
        date: "04/7/2024",
        city: "Cairo",
        title: `Contact Financial Holding Recognized as a top FinTech firm in the Middle East`,
        urlTitle: `الابتكار المالي يقود "كونتكت" للتميز في "أقوى 50 شركة تكنولوجيا مالية في الشرق الأوسط لعام 2024`,
        subTitle: "",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/Fintech.jpg",
        sections: [
          `Contact Financial Holding has achieved a significant milestone by being named among the " The Middle East's Fintech 50" by Forbes Middle East Magazine. This recognition highlights the company's prominent role in providing innovative and unconventional consumer and corporate finance solutions. `,
          `Since its establishment in 2001, Contact Financial Holding has become a leader in the Egyptian market. Its branch network has grown to include 75 branches and 13 points of sale, expanding its reach to a wider customer base. The company's commitment to innovation is evident in the launch of the ContactNow mobile application. This AI-powered app allows for instant credit approvals and efficient financial transactions. By the end of December 2023, the number of registered users surpassed 600,000, with a surge of 296,000 new users in 2023 alone. The app has also received over 271,000 applications for instant funding, reflecting the strong trust it enjoys among customers. `,
          `Contact Financial Holding also established "Contact Pay," the electronic payments arm operating under its umbrella. Contact Pay is a pioneer in delivering cutting-edge digital payment solutions, showcasing Contact's dedication to innovation and enhancing financial services. This initiative aligns with Contact's vision to become the premier provider of innovative digital financial services. The company is steadfast in its pursuit to offer seamless and secure payment experiences, effectively rendering cash and physical cards obsolete, and propelling financial transactions towards a fully integrated digital future. `,
          `Commenting on the news, Mr. Said Zater, CEO of Contact Financial Holding, said, "Innovation is at the core of our success, and a commitment to excellence defines our company. Being ranked among the top 50 fintech companies in the Middle East for 2024 is not just an achievement, it's a testament to the trust of our customers and the dedication of our team. We are building the future of fintech, and every step we take brings us closer to achieving our vision of empowering individuals and businesses through innovative and reliable financial solutions." `,
          `This recognition by Forbes Middle East is not the first for Contact. For two consecutive years, Contact has been ranked among the 50 listed companies in the Egyptian Stock Exchange. Additionally, Contact was honored as a sustainability leader in the Middle East, and Mr. Said Zater, CEO, was recognized as one of the best CEOs in the Middle East for 2023. `,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 45,
        date: "06/29/2024",
        city: "Cairo",
        title: `JumiaPay Egypt and Contact expand their partnership`,
        urlTitle: `"جوميا" و "كونتكت " تعلنان عن توسيع الشراكة الاستراتيجية`,
        subTitle: (
          <>
            <p>Collaboration to facilitate online shopping for Egyptians</p>
          </>
        ),
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/9d6d94a7-263d-4689-ae3f-7062f4f8ff40.jfif",
        sections: [
          `Jumia, the leading e-commerce platform in  Egypt , announced the expansion of the strategic partnership between JumiaPay and Contact CrediTech, the digital consumer finance arm of Contact Financial Holding, aimed to deliver enhanced services to their respective customers.`,
          `This expansion builds upon the success of their partnership, which began in January 2023 to encompass the provision of various payment methods to facilitate the online purchasing process and create diverse financial solutions for Jumia and Contact customers.`,
          `Contact, in general, and Contact CrediTech, in particular, place great importance on digital transformation in line with the Egyptian government's vision for 2030 and the pursuit of expanding the base of digital services offered to the Egyptian market while utilizing the latest technologies in the financial technology field.`,
          `Abdellatif Olama, CEO of Jumia Egypt, expressed his utmost delight in the continued collaboration between the two companies for the benefit of their customers. He emphasized that this step is part of JumiaPay and Contact's commitment to providing the best online shopping experience for consumers in the market, reinforcing cooperation between the two companies as strategic partners working together to achieve common goals and better meet customer needs.`,
          `Olama further added, "We look forward to a bright future of success and development in this fruitful partnership between the two companies."`,
          `Omar El Fiky, MD of Contact CrediTech, stated that through expanding the scope of their partnership and offering fee-free, interest-free, and down payment-free options, they aim to achieve further benefits for their joint customers. He expressed great enthusiasm for the future of this partnership and looks forward to achieving new successes together while providing exceptional shopping experiences that meet the aspirations and needs of customers in the market.`,
          `Said Zater, CEO of Contact Financial Holding mentioned, "At Contact, we highly value the close cooperation and strategic partnership we have with Jumia. This partnership is not just a business agreement; it represents a shared vision to enhance the online shopping experience for our customers. We believe in the importance of providing innovative and convenient financial solutions to customers, and this requires strong strategic partnerships with entities like Jumia, which have extensive experience in e-commerce and technology."`,
          `As part of Jumia's celebration of its 12th anniversary in the Egyptian market, from May 27th to June 9th of this year, customers can make purchases and shop through Jumia, with the option to finance their purchases through Contact without administrative fees, interest, or down payments for a period of up to 6 months. Customers can take advantage of this opportunity to shop with complete flexibility and convenience.`,
        ],
        bodyImages: ["", "", ""],
      },
      {
        id: 46,
        date: "05/12/2024",
        city: "Cairo",
        title: `Contact and Damen collaborate to provide innovative payment solutions and improve customer experience`,
        urlTitle: `كونتكت وضامن تتعاونان لتقديم حلول دفع مبتكرة وتحسين تجربة العملاء`,
        subTitle: "",
        img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/3c2928b6-0291-4681-9994-a74d01f503cc.jfif",
        sections: [
          `Contact Pay, a leading electronic payment provider and subsidiary of Contact Financial Holding, a non-banking financial services pioneer, announced a strategic partnership with Damen, an Egyptian fintech startup specializing in innovative payment solutions. This collaboration aims to strengthen their offerings, providing comprehensive digital payment solutions that simplify the customer experience. `,
          `This partnership aims to combine the extensive expertise of both companies to provide a comprehensive digital payment ecosystem that meets the diverse needs of customers and significantly simplifies their experience.`,
          `By combining expertise, the partnership will create a robust digital payment ecosystem that caters to diverse customer needs. Damen will act as Contact's on-the-ground collection arm, expanding its geographic reach and customer base across Egypt. Contact Pay benefits from Damen's extensive network of over 100,000 points of sale and payment kiosks, significantly enhancing their collection channels.`,
          `Eng. Ahmed Abdel Hakim, Managing Director of Contact Pay, said: " Partnering with Damen is a major step forward. We're confident this collaboration will significantly enhance our payment services, simplifying collections and offering more diverse payment options for our customers. We believe this strengthens Contact Pay's position as a leader in innovative payment solutions."`,
          `For his part, Sameh ElMallah, CEO of Damen, expressed his enthusiasm for this partnership, stating: " We're thrilled to partner with Contact, the leading player in Egypt's electronic payment landscape. This collaboration allows us to leverage Contact's experience to expand our services and reach a wider customer base. We're confident this partnership will propel financial technology advancements in Egypt and contribute to financial inclusion."`,
          `Mr. Said Zater, CEO of Contact Financial Holding, also commented on the agreement, saying: " This strategic partnership with Damen is a crucial step towards achieving our vision of elevating Egypt's electronic payment experience and offering innovative solutions. We're confident our cooperation with Damen will solidify our leadership in non-banking financial services and benefit the entire Egyptian market by strengthening the fintech scene."`,
        ],
        bodyImages: ["", "", ""],
      },
    ],
  },
  errorPage: {
    title: "Page not found",
    Home: "Back to main Page",
  },
  internetConnectionPage: {
    title: "Sorry!!",
    subTitle: "No internet connection. Check your connection and try again",
    Home: "Reload",
  },
  paymentSucsess: {
    title: "Payment has been completed successfully",
    Home: "Back to main Page",
    Sucsess: "Sucsess",
  },
  paymentFail: {
    title: "",
    Home: "Try Agin   ",
    error: "Error",
  },
  blogs: {
    title: "Blogs",
    minutes: " minutes reading",
    read_more: "Read more",
    share: "Share",
    blogs_list: [
      {
        id: 1,
        //  date: mm/dd/yyyy
        date: "04/03/2023",
        title:
          "Do not compromise your dream wedding: The joy is doubled with Contact!",
        urlTitle: `متتنازلش عن فرح أحلامك: الفرحة بتتضاعف مع كونتكت!`,

        subTitleA: `Weddings are an all-year-round event that takes planning and many expenses to fulfill your vision, from the dress and tuxedo to the venue, catering, and DJ.
          However, most couples don’t have enough savings to finance their wedding, so they resolve to use their credit cards or take out bank loans to cover their wedding expenses.
          `,
        subTitleB: `Weddings are an all-year-round event that takes planning and many expenses to fulfill your vision, from the dress and tuxedo to the venue, catering, and DJ.
        However, most couples don’t have enough savings to finance their wedding, so they resolve to use their credit cards or take out bank loans to cover their wedding expenses.
          `,
        img: "/blogs/6.png",
        sections: [
          {
            head: `Enjoy your dream wedding with Contact`,
            body: `At Contact, we know that your wedding day is one of the most important days of your life, therefore we are committed to helping you make it happen without any financial stress.`,
          },
          {
            head: `Benefits of financing your wedding with Contact`,
            body: (
              <>
                <p>
                  With Contact, bring your dream wedding into reality as we
                  provide you with:
                </p>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    Convenient installment plans: We offer a variety of
                    installment plans to fit your budget. You can choose a plan
                    that fits your monthly payments and early settlement terms.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Easy process: The application process is quick and easy. You
                    can apply online or in person at one of our branches.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Competitive interest rates: We offer competitive interest
                    rates on our wedding financing
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Reliable customer service: Our team can answer your
                    questions and help you through the financing process.
                  </li>
                  <li style={{ marginBottom: "5px" }}>20% down payment</li>
                  <li style={{ marginBottom: "5px" }}>
                    We have a financing budget of up to 1 million EGP so you can
                    have the wedding of your dreams.
                  </li>
                </ul>
              </>
            ),
          },
          {
            head: `Do you need a bank account or credit score to finance your wedding with Contact? `,
            body: `No, you don’t need a bank account or a credit score to qualify for financing your wedding with us.`,
          },
          {
            head: `What documents do you need to qualify for financing your wedding with Contact?`,
            body: (
              <>
                <p>
                  To apply for wedding financing with Contact, you will need to
                  provide the following information:
                </p>
                <ul>
                  <li style={{ marginBottom: "5px" }}>Your full name</li>
                  <li style={{ marginBottom: "5px" }}>Your date of birth</li>
                  <li style={{ marginBottom: "5px" }}>
                    Your national ID number
                  </li>
                  <li style={{ marginBottom: "5px" }}>Proof of income</li>
                  <li style={{ marginBottom: "5px" }}>
                    Financing amount needed
                  </li>
                  <li style={{ marginBottom: "5px" }}>Your desired plan</li>
                </ul>
                <p>
                  Once you submit your application, our team will review it and
                  get back to you. If we approve your application, we will send
                  you a financing contract. Once you sign the contract, you can
                  start using your funds.
                </p>
              </>
            ),
          },
          {
            head: `Here are some tips for financing your wedding:`,
            body: (
              <>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    Start planning early. The sooner you start to plan, the more
                    time you will have to save money and find the best financing
                    options.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Set a budget and stick to it. Having a realistic budget for
                    your wedding is essential so you spend your money wisely.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Consider all of your financing options. There are many
                    different ways to finance a wedding, so be sure to compare
                    your options before you choose one.
                  </li>
                </ul>
              </>
            ),
          },
          {
            head: `Calculate your monthly installments with Contact`,
            body: (
              <p>
                We took the guesswork out of the game, and now you can calculate
                your monthly installments using our {""}
                <a href="/products/events" target="blank">
                  calculator tool
                </a>
                .
              </p>
            ),
          },
          // {
          //   head: `About Contact `,
          //   body: `As Egypt’s leading  financial solutions provider, Contact Financial Holding offers various services, including asset management, auto credit, insurance, consumer and corporate financing. We are the largest player in Egypt’s nascent debt capital markets, helping companies access funding, manage risk, improve their financial standing, and increase liquidity through our expertise in structuring, arranging, and underwriting debt transactions.
          //   Our ability to deploy funds and generate liquidity enables us to create innovative financial solutions for our clients. We focus on underdeveloped financial segments to promote financial inclusion. Our offerings include consumer- and corporate-focused products and services in addition to many white-labeled brands for major Egyptian corporations.
          //   Our success is driven by our team of over 2,200 talented employees. We invest in our people to remain at the forefront of the industry, recognizing that our growth as a company is linked to their development.
          //   `,
          // },
          {
            head: `Contact Us Today`,
            body: (
              <p>
                If you want to learn more about wedding financing with Contact,
                don’t hesitate to contact us today. We would be happy to answer
                your questions and help you {""}
                <a href="/contact-us/" target="blank">
                  get started
                </a>
                .
              </p>
            ),
          },
        ],
      },
      {
        id: 2,
        //  date: mm/dd/yyyy
        date: "04/03/2023",
        title:
          "Your new bike, scooter, or motorcycle is closer than you think.",
        urlTitle: "الموتوسيكل، السكوتر، أو دراجتك النارية أقرب مما تتصور!",
        subTitleA: `Are you ready to hit the open road? Contact can help you to buy any motorcycle  you like  with an easy and convenient financing program. We offer the largest financing amount available, so you can get the bike you want without breaking the bank. Apply today and start riding tomorrow!`,
        subTitleB: `Are you ready to hit the open road? Contact can help you to buy any motorcycle  you like  with an easy and convenient financing program. We offer the largest financing amount available, so you can get the bike you want without breaking the bank. Apply today and start riding tomorrow!`,
        img: "/blogs/8.png",
        sections: [
          {
            head: `Motorcycles in Egypt `,
            body: (
              <>
                <p>
                  The popularity of motorcycles in Egypt is increasing . They
                  are oٍne of the fastest and most affordable modes of
                  transportation available.
                </p>
                <p>
                  From scooters and motorcycles to bicycles and mountain bikes,
                  it’s a crowd favorite if it has two wheels. Egypt attracted
                  numerous brands into its booming market, with brands like
                  Honda and Harley Davidson providing their lines of cool and
                  practical motorcycles.
                </p>
                <p>
                  Another reason Egyptians prefer motorcycles and scooters to
                  cars is their price and price difference; motorcycles are more
                  affordable and require less fuel and maintenance than cars,
                  making them appealing to the younger generation.
                </p>
              </>
            ),
          },
          {
            head: `Should I buy my new bike in cash or in installments? `,
            body: (
              <>
                <p>
                  So, did you find your 2-wheeler, 3 or 4 wheels of choice, and
                  you don’t know if you should pay for it in cash or
                  installments?{" "}
                </p>
                <p>
                  Each has pros and cons; however, paying in cash will put your
                  savings in a deficit and could hinder your financial stability
                  until you can replenish your savings again. However, paying in
                  installments won’t deplete your savings, as only a fraction of
                  the total price is taken from your account each month. Leaving
                  out one point, which is the interest rate, we covered that for
                  you—by offering competitive rates and minimal fees we made
                  your choice easier!
                </p>
              </>
            ),
          },
          {
            head: `Benefits of financing your motorcycle with Contact`,
            body: (
              <>
                <p>
                  With Contact financing your motorcycle is easy. Here are some
                  of the benefits of using Contact’s motorcycle financing
                  program:
                </p>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    Easy approval: Contact’s approval process is quick and easy.
                    You can get approved for a loan in as little as 24 hours.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Largest financing amount: Contact offers the largest
                    financing amount available for motorcycles of 2 Million EGP.
                    This means you can get the motorcycle you want, even if it’s
                    a high-end model.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Flexible terms: Contact offers flexible financing terms, so
                    you can choose a payment plan that fits your budget.
                  </li>
                  <li style={{ marginBottom: "5px" }}>20% down payment</li>
                  <li style={{ marginBottom: "5px" }}>
                    You can also get insurance for your motorcycle.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Our financing plans are valid for all types of motorcycles
                    (two, three, or four wheels), whether new, used, or
                    imported.
                  </li>
                </ul>
              </>
            ),
          },
          {
            head: `Do you need a bank account or certain credit score to finance your motorcycle with Contact? `,
            body: `No, you don’t need a bank account or a certain credit score to qualify for financing your motorcycle with Contact. `,
          },
          {
            head: `What documents do you need to qualify for financing your motorcycle with Contact?`,
            body: (
              <>
                <ul>
                  <li style={{ marginBottom: "5px" }}>Your full name</li>
                  <li style={{ marginBottom: "5px" }}>Your date of birth</li>
                  <li style={{ marginBottom: "5px" }}>
                    Your national ID number
                  </li>
                  <li style={{ marginBottom: "5px" }}>Proof of income</li>
                  <li style={{ marginBottom: "5px" }}>
                    Financing amount needed
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Your desired installment plan
                  </li>
                </ul>
                <p>
                  Once you submit your application, we will review it and get
                  back to you with a decision. If we approve your application,
                  we will send you a financing contract. Once you sign the
                  contract you can start your journey and buy your dream
                  motorcycle!
                </p>
              </>
            ),
          },
          {
            head: `Calculate your monthly installments with Contact`,
            body: (
              <p>
                We took the guesswork out of the game, and now you can calculate
                your monthly installments using our {""}
                <a
                  href="https://contact.eg/products/motorcycles"
                  target="blank"
                >
                  calculator tool
                </a>
                .
              </p>
            ),
          },
          ,
          //           {
          //             head: `About Contact `,
          //             body: `As Egypt’s leading  financial solutions provider, Contact Financial Holding offers various services, including asset management, auto credit, insurance, consumer and corporate financing. We are the largest player in Egypt’s nascent debt capital markets, helping companies access funding, manage risk, improve their financial standing, and increase liquidity through our expertise in structuring, arranging, and underwriting debt transactions.
          // Our ability to deploy funds and generate liquidity enables us to create innovative financial solutions for our clients. We focus on underdeveloped financial segments to promote financial inclusion. Our offerings include consumer- and corporate-focused products and services in addition to many white-labeled brands for major Egyptian corporations.
          // Our success is driven by our team of over 2,200 talented employees. We invest in our people to remain at the forefront of the industry, recognizing that our growth as a company is linked to their development.
          //             `,
          //           }
          {
            head: `Contact Us Today`,
            body: (
              <p>
                If you want to learn more about Motorcycles financing with
                Contact, don’t hesitate to contact us today. We would be happy
                to answer your questions and help you {""}
                <a href="/contact-us" target="blank">
                  get started
                </a>
                .
              </p>
            ),
          },
        ],
      },
      {
        id: 3,
        //  date: mm/dd/yyyy
        date: "08/16/2023",
        title: `Contact Education Financing Program: A Brighter Future for All`,
        urlTitle: `تمويل التعليم مع كونتكت: مستقبل أفضل للجميع`,
        subTitleA:
          "At Contact , we believe that education is the key to a brighter future. That's why we offer an education financing program that allows everyone to afford the education they need to achieve their dreams",

        subTitleB: (
          <p>
            At{" "}
            <a href="/" target="blank">
              Contact
            </a>
            , we believe that education is the cornerstone for a brighter
            future. That's why we offer an
            <strong> education financing program</strong> that allows everyone
            to afford the best education to achieve their goals.
          </p>
        ),
        img: "/blogs/1.png",
        sections: [
          {
            head: ``,
            body: `Our program covers all stages of local and international educational programs within Egypt, up to 1 million EGP. Whether you're looking to finance your child's school or university education, or your own postgraduate studies, we can help to achieve your goal. We offer various flexible payment plans that fit your budget, with up to 12 months installments for schools and universities, 36 months installments for masters programs, and 60 months installments for Ph.D. programs.`,
          },
          {
            head: `We also offer a variety of other benefits, including:`,
            body: (
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  No down payment required
                </li>
                <li style={{ marginBottom: "5px" }}>
                  No early settlement penalties
                </li>
                <li style={{ marginBottom: "5px" }}>
                  Easy online application process
                </li>
                <li style={{ marginBottom: "5px" }}>Fast approval</li>
              </ul>
            ),
          },
          {
            head: `Here are some additional details about the program:`,
            body: (
              <>
                <p>
                  The program is open to all Egyptian citizens, regardless of
                  their credit history.
                </p>

                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    There is no age limit for applicants.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    The program covers all types of educational institutions,
                    including public schools, private schools, universities, and
                    colleges.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    The program also covers international educational
                    institutions, within Egypt.
                  </li>
                </ul>
              </>
            ),
          },
          {
            head: ``,
            body: (
              <p>
                To apply for the program, fill out{" "}
                <a href="/products/education" target="blank">
                  the request form
                </a>
                , and a Contact representative will contact you to discuss your
                eligibility and to answer any questions you may have.
              </p>
            ),
          },
          {
            head: ``,
            body: `After receiving the credit approval, you can choose from various payment plans that fit your budget. You can also choose to make a down payment if desired. Once you have selected a payment plan, you can directly finance the tuition fees`,
          },
          {
            head: ``,
            body: (
              <p>
                <strong>Contact Education Financing</strong> is the perfect way
                to realize your educational dreams. With flexible payment plans
                and no down payment,
                <strong> Contact</strong> can help you afford the education you
                need to achieve your dreams.
              </p>
            ),
          },
          {
            head: ``,
            body: (
              <p>
                <a href="/products/education" target="blank">
                  Apply today{" "}
                </a>
                and start your journey to a brighter future.
              </p>
            ),
          },
        ],
      },
      {
        id: 4,
        //  date: mm/dd/yyyy
        date: "08/15/2023",
        title: "Purchase your Dream Car Today with Contact Auto Financing",
        urlTitle:
          "اشتري عربيتك اللي بتحلم بيها دلوقتي مع تمويل السيارات من كونتكت",
        subTitleA: `
        Purchasing a dream car is on everyone’s bucket list, whether this car is a lavish one or more on the practical side. Yet purchasing a car can be challenging, especially if your financial situation doesn’t allow you to shell out that amount of money in one go! 
Contact will make your dreams come true.

            `,
        subTitleB: `
            Purchasing a dream car is on everyone’s bucket list, whether this car is a lavish one or more on the practical side. Yet purchasing a car can be challenging, especially if your financial situation doesn’t allow you to shell out that amount of money in one go! 
    Contact will make your dreams come true.
            `,
        img: "/blogs/11.png",
        sections: [
          {
            head: `Finance your car with Contact Auto. `,
            body: (
              <>
                <p>
                  <span style={{ fontWeight: "bold" }}>Contact </span>is the
                  leading provider of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    auto financing in Egypt
                  </span>
                  . With many showrooms across Egypt,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact </span> offers
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    financing solutions{" "}
                  </span>
                  for
                  <span style={{ fontWeight: "bold" }}> new, used, </span>
                  electric or fuel powered engines.
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Contact Auto </span>
                  financing solutions are designed to be flexible and
                  convenient, with installment plans for up to 5 years. You can
                  easily get the car you want, even if you don’t have the total
                  cash amount upfront
                </p>
                <p>
                  In addition to our
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    flexible financing terms
                  </span>{" "}
                  , <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Contact Auto
                  </span>{" "}
                  offers many payment plans, convenient to everybody in Egypt
                  between the ages of 21-70, with the ability to finance up to
                  5,000,000 EGP. Contact offers you complete car registration
                  services that include licensing, with or without insurance.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Contact Auto provides a variety of other benefits, including:
                </p>
                <ul style={{ marginLeft: "5px" }}>
                  <li style={{ marginBottom: "5px" }}>
                    Flexible monthly installments
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Easy application process
                  </li>
                  <li style={{ marginBottom: "5px" }}>Fast approval</li>
                </ul>
                <p>
                  If you’re looking for a reliable and affordable way to
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    finance your next car
                  </span>
                  , then
                  <span style={{ fontWeight: "bold" }}> Contact Auto</span> is
                  the perfect solution.
                </p>
              </>
            ),
          },
          {
            head: `Contact Auto Financing plans`,
            body: (
              <>
                <p>
                  <strong> Electric Vehicles:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>20% down payment</li>
                    <li style={{ marginBottom: "5px" }}>
                      Insurance not required
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Payment plans up to 5 years
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Full or partial prepayment after 1 year
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Economy:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>20% down payment</li>
                    <li style={{ marginBottom: "5px" }}>insurance required</li>
                    <li style={{ marginBottom: "5px" }}>
                      Payment plans up to 3 years
                    </li>
                  </ul>
                </p>

                <p>
                  <strong>Economy Plus:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li>20% down payment</li>
                    <li>insurance required</li>
                    <li>Payment plans up to 5 years</li>
                    <li>Full or partial prepayment after 1 year</li>
                  </ul>
                </p>
                <p>
                  <strong>Flexible:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>20% down payment</li>
                    <li style={{ marginBottom: "5px" }}>insurance required</li>
                    <li style={{ marginBottom: "5px" }}>
                      Payment plans from 3 to 5 years
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Full or partial prepayment option after 1 year
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Partial deferral for down-payment
                    </li>
                  </ul>
                </p>

                <p>
                  <strong>Flexible Plus:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>20% down payment</li>
                    <li style={{ marginBottom: "5px" }}>
                      insurance not required
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Payment plans up to 5 years
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Full or partial prepayment option
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Partial deferral for down-payment
                    </li>
                  </ul>
                </p>

                <p>
                  <strong>Corporate Packages: </strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>10% down payment</li>
                    <li style={{ marginBottom: "5px" }}>insurance required</li>
                    <li style={{ marginBottom: "5px" }}>
                      Payment plans from 2 to 5 years
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      Full or partial prepayment option after 1 year
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Used Cars Packages:</strong>
                  <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <li style={{ marginBottom: "5px" }}>20% down payment</li>
                    <li style={{ marginBottom: "5px" }}>
                      Insurance not required{" "}
                    </li>

                    <li style={{ marginBottom: "5px" }}>
                      Payment plans up to 5 years{" "}
                    </li>
                  </ul>
                </p>
              </>
            ),
          },
          {
            head: `Do you need a bank account or credit score to finance your car with Contact? `,
            body: `No, you don’t need a bank account or a credit score to qualify for financing your car with us.  `,
          },
          {
            head: `What documents do you need to qualify for financing your car with Contact?`,
            body: (
              <>
                <p>
                  To apply for Auto financing with Contact, you will need to
                  provide the following information:
                </p>
                <ul style={{ marginLeft: "5px", marginTop: "5px" }}>
                  <li style={{ marginBottom: "5px" }}>Your full name</li>
                  <li style={{ marginBottom: "5px" }}>Your date of birth</li>
                  <li style={{ marginBottom: "5px" }}>
                    Your national ID number
                  </li>
                  <li style={{ marginBottom: "5px" }}>Your driving license</li>
                  <li style={{ marginBottom: "5px" }}>Proof of income</li>
                  <li style={{ marginBottom: "5px" }}>
                    Financing amount needed
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Your desired installment plan
                  </li>
                </ul>
                <p>
                  Once you submit your application, we will review it and send
                  you a financing contract in case of approval. Once you sign
                  the contract, you can start using your funds and buy your
                  dream car!
                </p>
              </>
            ),
          },
          {
            head: `Calculate your monthly installments with Contact`,
            body: (
              <p>
                We took the guesswork out of the game, and now you can calculate
                your monthly installments using our{" "}
                <a href="/products/auto" target="blank">
                  calculator tool.
                </a>
              </p>
            ),
          },
          //         {
          //           head: `About Contact  `,
          //           body: `As Egypt’s leading financial solutions provider, Contact offers various services, including asset management , insurance, consumer and corporate financing.
          // We are the largest player in Egypt’s nascent debt capital markets, helping companies access funding, manage risk, improve their financial standing, and increase liquidity through our expertise in structuring, arranging, and underwriting debt transactions.
          // Our ability to deploy funds and generate liquidity enables us to create innovative financial solutions for our clients. We focus on underdeveloped financial segments to promote financial inclusion. Our offerings include consumer- and corporate-focused products and services in addition to white-labeled solutions for major Egyptian corporations.
          // Our success is driven by our team of over 2,200 talented employees. We invest in our people to remain at the forefront of the industry, recognizing that our growth as a company is linked to their development.
          //   `,
          //         },
          {
            head: `Contact Us Today  `,
            body: (
              <p>
                If you want to learn more about Auto financing with Contact,
                don’t hesitate to contact us today. We would be happy to answer
                your questions and help you{" "}
                <a href="/contact-us/" target="blank">
                  get started
                </a>
                .
              </p>
            ),
          },
        ],
      },
      {
        id: 5,
        //  date: mm/dd/yyyy
        date: "08/16/2023",
        title: "Contact Pay—Payments Made Easy",

        urlTitle: `كونتكت باي مدفوعاتك بقت أسهل!`,
        subTitleB: (
          <>
            <p>
              <strong>Contact Pay</strong> is a new
              <strong> payment solution</strong> provided by Contact. It makes
              it easy and secure to pay your mobile, electricity, gas, water
              bills, and many other services through the ContactNow
              <strong> mobile application</strong> or Contact branches all over
              Egypt.
            </p>
          </>
        ),
        subTitleA: `Contact Pay is a new payment solution provided by Contact. It makes it easy and secure to pay your mobile, electricity, gas, water bills, and many other services through the ContactNow mobile application or Contact branches all over Egypt. `,
        img: "/blogs/10.png",
        sections: [
          {
            head: `You can use Contact Pay Service to pay `,
            body: (
              <>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    Schools and universities' tuition fees
                  </li>
                  <li style={{ marginBottom: "5px" }}>Electricity bills</li>
                  <li style={{ marginBottom: "5px" }}>Gas bills</li>
                  <li style={{ marginBottom: "5px" }}>Water bills</li>

                  <li style={{ marginBottom: "5px" }}>Landline bills</li>
                  <li style={{ marginBottom: "5px" }}>Mobile bills</li>
                  <li style={{ marginBottom: "5px" }}>Internet subscription</li>
                  <li style={{ marginBottom: "5px" }}>
                    Transportation tickets
                  </li>
                  <li style={{ marginBottom: "5px" }}>TV subscriptions</li>
                  <li style={{ marginBottom: "5px" }}>
                    Insurance installments
                  </li>
                  <li style={{ marginBottom: "5px" }}>Donations and NGOs</li>
                  <li style={{ marginBottom: "5px" }}>Club Memberships</li>
                </ul>
              </>
            ),
          },
          {
            head: `Here are some of the benefits of using Contact Pay:`,
            body: (
              <>
                <ul>
                  <li style={{ marginBottom: "5px" }}>
                    It's fast and convenient to use through the Contact Now
                    mobile application or Contact branches all over Egypt.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    It's secure. Contact's state-of-the-art security measures
                    protect your payments.
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Accepted by many entities . So you can make all your
                    payments in one place!
                  </li>
                </ul>
              </>
            ),
          },

          {
            head: ``,
            body: `Contact Pay is the perfect solution for you if you're looking for a simple, secure, and convenient way for all your payments.`,
          },
          {
            head: (
              <p>
                <a href="/contact-us/" target="blank">
                  Contact us{" "}
                </a>
                or visit one of our branches and start enjoying the benefits of
                Contact Pay!
              </p>
            ),
            body: ``,
          },
        ],
      },
      {
        id: 6,
        //  date: mm/dd/yyyy
        date: "08/01/2024",
        title:
          "Switching to Electric Car? We are Simplifying the Financing Process for Your New Electric Cars in Egypt",

        urlTitle: `بتفكر تشتري عربية كهربائية؟ 
عندنا أسهل تمويل لعربيتك الكهربائية الجديدة في مصر
`,
        subTitleA: `As the world shifts towards more sustainable living, electric vehicles (EVs) are becoming an increasingly popular choice among consumers in Egypt. However, the process of purchasing an electric car can be daunting, especially when it comes to financing. At Contact, we’re here to simplify that process of financing your electric vehicle purchase in Egypt, offering tailored financing solutions that make owning an electric vehicle more accessible than ever.`,
        subTitleB: (
          <>
            <p>
              As the world shifts towards more sustainable living,
              <strong>
                {" "}
                electric vehicles (EVs) are becoming an increasingly popular
                choice among consumers in Egypt.{" "}
              </strong>
              However, the process of purchasing an electric car can be
              daunting, especially when it comes to financing. At{" "}
              <strong> Contact,</strong> we’re here to simplify that process of{" "}
              <strong>
                financing your electric vehicle purchase in Egypt{" "}
              </strong>
              offering tailored financing solutions that make owning an electric
              vehicle more accessible than ever.
            </p>
          </>
        ),
        img: "/blogs/3.png",
        sections: [
          {
            head: `Contact’s Auto Financing: Tailored for Electric Vehicles`,
            body: (
              <>
                <p>
                  Our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    auto financing program
                  </span>{" "}
                  is designed with the unique needs of electric vehicle buyers
                  in mind. We understand that switching to an EV is not just
                  about buying a car—it’s about investing in a more sustainable
                  future. That’s why{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> offers
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    financing solutions
                  </span>{" "}
                  that cater specifically to the needs of EV owners. With{" "}
                  <span style={{ fontWeight: "bold" }}>Contact,</span> you can
                  finance your electric car with ease, knowing that you’re
                  getting the{" "}
                  <span style={{ fontWeight: "bold" }}>
                    best financing services in Egypt
                  </span>{" "}
                  tailored to your eco-friendly lifestyle.
                </p>
              </>
            ),
          },
          {
            head: `Flexible Payment Plans: Drive Your Dream Electric Car`,
            body: (
              <>
                <p>
                  One of the key features of our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    auto financing program
                  </span>{" "}
                  is the flexibility it offers. Whether you’re purchasing a
                  brand-new electric car or a pre-owned car,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> provides
                  payment plans that make it easier to own the car you need.
                  With{" "}
                  <span style={{ fontWeight: "bold" }}>
                    payment plans up to 5 years
                  </span>{" "}
                  and financing amounts{" "}
                  <span style={{ fontWeight: "bold" }}>
                    up to 7 million EGP
                  </span>
                  , you can drive your electric vehicle with as little as a{" "}
                  <span style={{ fontWeight: "bold" }}>20% down payment</span>.
                  Plus, our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    installment calculator{" "}
                  </span>
                  allows you to see exactly what your monthly payments will be,
                  so there are no surprises.
                </p>
              </>
            ),
          },
          {
            head: `Benefits of Financing with Contact: A Seamless Experience`,
            body: (
              <>
                <p>
                  At <span style={{ fontWeight: "bold" }}>Contact</span>, we
                  pride ourselves on making the financing process as smooth and
                  straightforward as possible. From the moment you decide to
                  purchase an electric car to the day you drive it off the lot,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> is there
                  to support you every step of the way. Our dedicated team is
                  ready to help you navigate the financing options available and
                  choose the one that best suits your needs.
                </p>
              </>
            ),
          },
          {
            head: ``,
            body: (
              <>
                <p>
                  If you’ve been considering switching to an electric vehicle
                  but have been hesitant due to the financing process, now is
                  the time to take the plunge. With{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s specialized financing solutions,{" "}
                  </span>
                  owning{" "}
                  <span style={{ fontWeight: "bold" }}>
                    an electric car in Egypt
                  </span>{" "}
                  has never been easier. Explore your options today and drive
                  away in the electric vehicle of your dreams with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact.</span>
                </p>
                <p>
                  Go to{" "}
                  <a
                    href="https://contact.eg/products/auto"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://contact.eg/products/auto
                  </a>{" "}
                  and calculate your installments now.
                </p>
              </>
            ),
          },
        ],
      },
      {
        id: 7,
        //  date: mm/dd/yyyy
        date: "08/01/2024",
        title:
          "Academic Year 2024-2025 is Around the Corner: Here’s How to Finance the Educational Fees with Contact and Pay with Ease",

        urlTitle: `السنة الدراسية 2024/2025 قربت تبدأ: 
إزاي تموّل مصاريف التعليم مع كونتكت بسهولة
`,
        subTitleA: `As the new academic year approaches, parents across Egypt are preparing to enroll their children in schools and universities. With the rising cost of education, many families are looking for ways to manage these expenses without straining their finances. That’s where Contact comes in. Our Education Financing solutions are designed to make your children educational fees easier and more manageable.`,
        subTitleB: (
          <>
            <p>
              As the new academic year approaches,{" "}
              <span style={{ fontWeight: "bold" }}>parents</span> across
              <span style={{ fontWeight: "bold" }}> Egypt</span> are preparing
              to <span style={{ fontWeight: "bold" }}>enroll </span>
              their{" "}
              <span style={{ fontWeight: "bold" }}>
                children in schools and universities.
              </span>{" "}
              With the rising cost of education, many families are looking for
              ways to manage these expenses without straining their finances.
              That’s where
              <span style={{ fontWeight: "bold" }}> Contact</span> comes in. Our{" "}
              <span style={{ fontWeight: "bold" }}>Education Financing </span>
              solutions are designed to make your children educational fees
              easier and more manageable.
            </p>
          </>
        ),
        img: "/blogs/7.png",
        sections: [
          {
            head: `Education Financing with Contact: Affordable Payment Plans`,
            body: (
              <>
                <p>
                  At <span style={{ fontWeight: "bold" }}>Contact,</span> we
                  understand that providing your child with a{" "}
                  <span style={{ fontWeight: "bold" }}>quality education</span>{" "}
                  is one of the most important investments you’ll ever make.
                  That’s why we offer{" "}
                  <span style={{ fontWeight: "bold" }}>
                    exclusive education financing options
                  </span>{" "}
                  with
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    payment plans up to 12 months
                  </span>{" "}
                  for schools, universities, and even professional courses.
                  Whether you need to cover tuition fees, registration costs, or
                  other educational expenses,
                  <span style={{ fontWeight: "bold" }}> Contact</span> has you
                  covered.
                </p>
              </>
            ),
          },
          {
            head: `Financing Up to 1 Million EGP: No Down-Payment Required`,
            body: (
              <>
                <p>
                  One of the standout features of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s education financing
                  </span>{" "}
                  is the ability to finance up to
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    1 million EGP
                  </span>{" "}
                  with{" "}
                  <span style={{ fontWeight: "bold" }}>
                    no down-payment required
                  </span>
                  . This means you can secure the funds you need for your
                  child’s education without worrying about upfront costs.
                  Whether you’re enrolling your child for the first time or
                  managing{" "}
                  <span style={{ fontWeight: "bold" }}>
                    multiple educational expenses
                  </span>
                  ,<span style={{ fontWeight: "bold" }}> Contact</span> offers
                  the flexibility and support you need.
                </p>
              </>
            ),
          },

          {
            head: `Ease the Financial Burden: Pay Over Time`,
            body: (
              <>
                <p>
                  For many parents, the thought of paying a large sum of money
                  upfront can be overwhelming.
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Contact’s education financing
                  </span>{" "}
                  takes the stress out of paying tuition fees by offering
                  installment plans that allow you to pay over time. With
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    payment plans up to 12 months for schools and universities,
                    and up to 3 years for Masters, and 5 years for the PhD
                    programs,
                  </span>{" "}
                  you can choose a plan that fits your budget, making it easier
                  to manage the cost of your child’s education.
                </p>
              </>
            ),
          },
          {
            head: `Calculator Available on the Website: Plan Your Payments`,
            body: (
              <>
                <p>
                  To help you plan your payments,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> offers an
                  installment calculator on our website. This tool allows you to
                  see exactly what your monthly payments will be, so there are
                  no surprises. With just a few clicks, you can determine the
                  best financing option for your needs and make informed
                  decisions about your child’s education.
                </p>
              </>
            ),
          },
          {
            head: `Benefits for Parents: Focus on Your Child’s Future `,
            body: (
              <>
                <p>
                  By choosing{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s education financing,
                  </span>{" "}
                  you’re not just making a smart financial decision—you’re
                  investing in your child’s future. Our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    education financing solutions{" "}
                  </span>
                  are designed to give you the flexibility you need to manage
                  your finances while ensuring that your children get the
                  education they deserve. With{" "}
                  <span style={{ fontWeight: "bold" }}>Contact,</span> you can
                  focus on what matters most—your child’s academic success.
                </p>
              </>
            ),
          },
          {
            head: ``,
            body: (
              <>
                <p>
                  Now is the time to explore your options for financing your
                  child’s education. With
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Contact’s education financing solutions,{" "}
                  </span>
                  you can secure up to{" "}
                  <span style={{ fontWeight: "bold" }}>1 million EGP</span> with
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    no down-payment required
                  </span>
                  , and pay tuition fees with ease and confidence. Don’t let
                  financial concerns affect the your child’s education—partner
                  with <span style={{ fontWeight: "bold" }}>Contact</span> today
                  and ensure a bright future for your family.
                </p>
                <p>
                  Go to{" "}
                  <a
                    href="https://contact.eg/products/education"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://contact.eg/products/education
                  </a>{" "}
                  and discover more possibilities with Contact!
                </p>
              </>
            ),
          },
        ],
      },
      {
        id: 8,
        //  date: mm/dd/yyyy
        date: "08/01/2024",
        title:
          "Empowering Your Financial Journey with Contact: The Power of Business Solutions with Contact",

        urlTitle: `شريك قوي في قراراتك المالية: 
قوة حلول الأعمال مع كونتكت
`,
        subTitleA: `In today’s competitive business landscape, having the right financial partner for your business can make all the difference. At Contact, we understand the unique challenges that businesses in Egypt face and offer tailored solutions to help you not just survive, but thrive. 
Partnering with Contact opens up a world of possibilities, offering more than just financial support; we empower your business to reach new heights. Here is how:
`,
        subTitleB: (
          <>
            <p>
              In today’s competitive business landscape, having the right{" "}
              <span style={{ fontWeight: "bold" }}>
                financial partner for your business{" "}
              </span>
              can make all the difference. At{" "}
              <span style={{ fontWeight: "bold" }}>Contact</span>, we understand
              the unique challenges that{" "}
              <span style={{ fontWeight: "bold" }}>businesses in Egypt</span>{" "}
              face and offer tailored solutions to help you not just survive,
              but thrive.
            </p>
            <p>
              Partnering with{" "}
              <span style={{ fontWeight: "bold" }}>Contact</span> opens up a
              world of possibilities, offering more than just financial support;
              we empower your business to reach new heights. Here is how:
            </p>
          </>
        ),
        img: "/blogs/4.png",
        sections: [
          {
            head: `Factoring Services: Boost Your Cash Flow`,
            body: (
              <>
                <p>
                  One of the key services we offer is{" "}
                  <span style={{ fontWeight: "bold" }}>factoring</span>, a
                  financial program from{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> that can
                  significantly
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    improve your company’s cash flow
                  </span>
                  . By converting your receivables into immediate cash,{" "}
                  <span style={{ fontWeight: "bold" }}>
                    factoring
                  </span> with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact </span>
                  ensures that you have the working capital you need to keep
                  your business running smoothly. Whether you’re looking to
                  manage seasonal fluctuations or free up funds for expansion,
                  our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    factoring services{" "}
                  </span>
                  are <span style={{ fontWeight: "bold" }}>available for</span>{" "}
                  almost all{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Egyptian businesses
                  </span>
                  , and provide the flexibility and support your business needs.
                </p>
              </>
            ),
          },
          {
            head: `Leasing Services: Flexibility for Your Business Growth`,
            body: (
              <>
                <p>
                  Another powerful solution we offer is{" "}
                  <span style={{ fontWeight: "bold" }}>leasing</span>. Whether
                  you need new equipment, vehicles, or other assets,{" "}
                  <span style={{ fontWeight: "bold" }}>leasing</span> with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact </span>
                  allows you to acquire what you need without the hefty upfront
                  costs. Our{" "}
                  <span style={{ fontWeight: "bold" }}>
                    leasing options
                  </span>{" "}
                  are designed to fit the specific needs of your business,
                  enabling you to stay competitive and agile in your industry.
                  With <span style={{ fontWeight: "bold" }}>Contact</span>, you
                  can be assured that you’re getting the{" "}
                  <span style={{ fontWeight: "bold" }}>
                    best financing services in Egypt
                  </span>{" "}
                  tailored to your business goals.
                </p>
              </>
            ),
          },

          {
            head: `Insurance Services: Protecting Your Business and Employees`,
            body: (
              <>
                <p>
                  At <span style={{ fontWeight: "bold" }}>Contact</span>, we
                  also understand the importance of safeguarding your business
                  and its most valuable assets—your employees. Our comprehensive{" "}
                  <span style={{ fontWeight: "bold" }}>
                    insurance solutions
                  </span>{" "}
                  cover a wide range of needs, from{" "}
                  <span style={{ fontWeight: "bold" }}>business insurance</span>{" "}
                  to
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    employee insurance
                  </span>
                  . Whether you’re looking to protect your property, vehicles,
                  or employees,
                  <span style={{ fontWeight: "bold" }}> Contact</span> offers
                  the coverage you need to ensure peace of mind and business
                  continuity.
                </p>
              </>
            ),
          },
          {
            head: `Investment Opportunities: Grow Your Business with Contact`,
            body: (
              <>
                <p>
                  Beyond financing and insurance,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> also
                  provides{" "}
                  <span style={{ fontWeight: "bold" }}>
                    investment opportunities
                  </span>{" "}
                  that can help your business explore new avenues for growth.
                  Whether you’re looking to invest in new projects or expand
                  your operations,{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> offers
                  tailored investment services that align with your long-term
                  goals.
                </p>
              </>
            ),
          },
          {
            head: ``,
            body: (
              <>
                <p>
                  Partnering with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> means
                  gaining access to a comprehensive suite of financial services
                  designed to empower your business. From{" "}
                  <span style={{ fontWeight: "bold" }}>factoring </span>
                  and <span style={{ fontWeight: "bold" }}>
                    leasing
                  </span> to{" "}
                  <span style={{ fontWeight: "bold" }}>insurance</span> and
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    investment opportunities
                  </span>
                  , <span style={{ fontWeight: "bold" }}>Contact</span> offers
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    the best business and retail financing and insurance
                    solutions in Egypt.
                  </span>
                  Don’t just take our word for it—experience the difference with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact </span>
                  and unlock the full potential of your business.
                </p>
                <p>
                  Call us now on 16177 or visit one of our branches to know more
                  about business possibilities with Contact.
                </p>
              </>
            ),
          },
        ],
      },
      {
        id: 9,
        //  date: mm/dd/yyyy
        date: "08/01/2024",
        title:
          "How Contact's Payment and Installment Plans Can Expand Your Customer Base (Business for Merchants)",

        urlTitle: `إزاي خطط الدفع والتقسيط من كونتكت ممكن توسع قاعدة عملائك وتكبر تجارتك (للتجار وأصحاب المحلات)`,
        subTitleA: `As a merchant or a shop in the Egyptian market, your goal is to attract as many customers as possible and increase sales. But in today’s market, competition is fierce, and standing out requires more than just offering great products. That’s where Contact comes in. By partnering with Contact and accepting it as a payment method by adding your retail business as a merchant with Contact, you can significantly expand your customer base and boost your sales.`,
        subTitleB: (
          <>
            <p>
              As a merchant or a shop in the Egyptian market, your goal is to
              attract as many customers as possible and increase sales. But in
              today’s market, competition is fierce, and standing out requires
              more than just offering great products. That’s where{" "}
              <span style={{ fontWeight: "bold" }}>Contact</span> comes in. By
              partnering with{" "}
              <span style={{ fontWeight: "bold" }}>Contact </span>
              and accepting it as a payment method by{" "}
              <span style={{ fontWeight: "bold" }}>
                adding your retail business as a merchant with Contact,
              </span>{" "}
              you can significantly{" "}
              <span style={{ fontWeight: "bold" }}>
                expand your customer base and boost your sales
              </span>
              .
            </p>
          </>
        ),
        img: "/blogs/2.png",
        sections: [
          {
            head: `Unparalleled Nationwide Reach: Tap into a Vast Customer Network`,
            body: (
              <>
                <p>
                  One of the biggest advantages of partnering with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> is gaining
                  access to our extensive{" "}
                  <span style={{ fontWeight: "bold" }}>
                    nationwide customer base
                  </span>
                  . As{" "}
                  <span style={{ fontWeight: "bold" }}>
                    the leading consumer finance company in Egypt
                  </span>
                  ,<span style={{ fontWeight: "bold" }}> Contact</span> has
                  built a vast
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    network of customers and buyers in Egypt
                  </span>{" "}
                  who trust us to provide flexible and convenient payment
                  options. When you join{" "}
                  <span style={{ fontWeight: "bold" }}>Contact’s</span> merchant
                  network, you’re not just adding a new payment method—you’re
                  opening your doors to a whole new segment of customers who
                  prefer to shop where they can use{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span>.
                </p>
              </>
            ),
          },
          {
            head: `Flexible Installment Plans: Easy Purchasing Experience`,
            body: (
              <>
                <p>
                  Today’s consumers value flexibility, especially when it comes
                  to making large purchases. By offering{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s installment plans
                  </span>{" "}
                  at your store, you make it easier for customers to buy what
                  they want, when they want it with{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s convenient Buy Now Pay Later program
                  </span>
                  . Our{" "}
                  <span style={{ fontWeight: "bold" }}>installment plans</span>{" "}
                  allow customers to spread out payments over time, making your
                  products more accessible and appealing. This flexibility not
                  only attracts more customers but also encourages them to spend
                  more, knowing they can pay over a long time.
                </p>
              </>
            ),
          },
          {
            head: `Merchant Benefits: Grow Your Sales with Contact`,
            body: (
              <>
                <p>
                  Joining <span style={{ fontWeight: "bold" }}>Contact’s</span>{" "}
                  merchant network comes with a host of benefits. Beyond
                  increasing your customer base, partnering with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> can
                  enhance customer loyalty and satisfaction. Our{" "}
                  <span style={{ fontWeight: "bold" }}>payment solutions</span>{" "}
                  are designed to meet the needs of both customers and
                  merchants, ensuring a smooth transaction process and a
                  positive shopping experience.
                </p>
              </>
            ),
          },
          {
            head: ``,
            body: (
              <>
                <p>
                  Incorporating{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s payment and installment plans
                  </span>{" "}
                  into your business is more than just a smart move—it’s a
                  strategic decision that can drive growth and success. By
                  tapping into{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Contact’s nationwide reach
                  </span>{" "}
                  and offering{" "}
                  <span style={{ fontWeight: "bold" }}>
                    flexible payment options
                  </span>
                  , you’re positioning your business to attract more customers
                  and boost sales. Don’t miss out on the opportunity to expand
                  your customer base—partner with{" "}
                  <span style={{ fontWeight: "bold" }}>Contact</span> today.
                </p>
                <p>
                  Go to{" "}
                  <a
                    href="https://contact.eg/partnership/contact-now-merchant"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://contact.eg/partnership/contact-now-merchant
                  </a>{" "}
                  and sign up in a few taps!
                </p>
              </>
            ),
          },
        ],
      },
      {
        id: 10,
        //  date: mm/dd/yyyy
        date: "09/01/2024",
        title: "Hajj and Umrah: A Sacred Dream for Every Muslim in Egypt",

        urlTitle: `حقق حلمك في الحج والعمرة مع كونتكت`,
        subTitleA: `For Muslims in Egypt, performing Hajj or Umrah is not just a religious obligation—it’s a lifelong dream and a profound spiritual journey. However, the financial costs associated with this sacred experience can often be a barrier for many. Recognizing this need, Contact, a leading consumer finance company in Egypt, has introduced Manasik, a Shariah-compliant financing solution specifically designed to make your pilgrimage both accessible and affordable.`,
        subTitleB: (
          <>
            <p>
              For Muslims in Egypt, performing <strong>Hajj</strong> or{" "}
              <strong>Umrah</strong> is not just a religious obligation—it’s a
              lifelong dream and a profound spiritual journey. However, the
              financial costs associated with this sacred experience can often
              be a barrier for many.
            </p>
            <p>
              Recognizing this need, <strong>Contact</strong>, a{" "}
              <strong>leading consumer finance company in Egypt</strong>, has
              introduced <strong>Manasik</strong>, a{" "}
              <strong>Shariah-compliant financing solution</strong> specifically
              designed to make your pilgrimage both{" "}
              <strong>accessible and affordable</strong>.
            </p>
          </>
        ),
        img: "/blogs/Manasik-Blog.png",
        sections: [
          {
            head: `Manasik: Your Path to the Holy Land`,
            body: (
              <>
                <p>
                  With <strong>Manasik</strong>, Contact offers the{" "}
                  <strong>
                    best financing options for Hajj and Umrah in Egypt
                  </strong>
                  , enabling you to repay the costs{" "}
                  <strong>in easy and flexible installments</strong>. Whether
                  it’s your first journey or a return to the sacred sites,{" "}
                  <strong>Contact</strong> ensures your financial concerns won’t
                  hold you back.
                </p>
              </>
            ),
          },
          {
            head: `Why Choose Manasik by Contact?`,
            body: (
              <>
                <p>
                  With <strong>Manasik</strong>, your journey to the{" "}
                  <strong>Holy Land</strong> is within reach. Explore the
                  exclusive benefits designed to make your pilgrimage smooth,
                  stress-free, and financially accessible:
                </p>
                <ol>
                  <li>
                    <strong>Flexible Payment Plans: </strong>
                    <span>
                      Pay over <strong>up to 5 years for Hajj</strong> or{" "}
                      <strong>12 months for Umrah</strong>, tailored to suit
                      your financial needs.
                    </span>
                  </li>
                  <li>
                    <strong>High Financing Limit: </strong>
                    <span>
                      Get funding of up to <strong>3 million EGP</strong>,
                      ensuring comprehensive support for your travel and
                      accommodation needs.
                    </span>
                  </li>
                  <li>
                    <strong>Affordable Down Payment: </strong>
                    <span>
                      Start your sacred journey with a down payment as low as{" "}
                      <strong>20%</strong>, making it accessible for all.
                    </span>
                  </li>
                  <li>
                    <strong>Shariah-Compliant Transactions: </strong>
                    <span>
                      Rest assured that all financing solutions align with{" "}
                      <strong>Islamic principles</strong>.
                    </span>
                  </li>
                  <li>
                    <strong>Speed and Convenience: </strong>
                    <span>
                      Enjoy simple and fast application procedures, so you can
                      focus on preparing for your pilgrimage.
                    </span>
                  </li>
                  <li>
                    <strong>Installment Calculator: </strong>
                    <span>
                      Use our <strong>online installment calculator</strong> to
                      plan your payments with clarity and ease, ensuring full
                      control over your budget.
                    </span>
                  </li>
                </ol>
              </>
            ),
          },
          {
            head: `Start Your Spiritual Journey Today`,
            body: (
              <>
                <p>
                  Don’t let financial worries delay your sacred dream. With
                  Contact’s <strong>Manasik</strong>, you can perform Hajj or
                  Umrah with ease, peace of mind, and full spiritual focus.
                  Begin planning your journey today and take the first step
                  towards fulfilling this profound pillar of faith.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },

  careers: {
    apply: "Apply",
    section: "Section",
    jobBrief: "Job Brief",
    requirements: "Requirements",
    notHiring: "Not Hiring Right Now",
    jobs: [
      {
        id: 1,
        title: "AP Accountant",
        section: "Accounting",
        jobBrief: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>
                Manage all accounting transactions
              </li>
              <li style={{ marginBottom: "5px" }}>Prepare budget forecasts</li>
              <li style={{ marginBottom: "5px" }}>
                Publish financial statements in time
              </li>
              <li style={{ marginBottom: "5px" }}>
                Reconcile accounts payable{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Ensure timely bank payments
              </li>
              <li style={{ marginBottom: "5px" }}>
                Compute taxes and prepare tax returns
              </li>
              <li style={{ marginBottom: "5px" }}>
                Manage balance sheets and profit/loss statements{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Report on the company’s financial health and liquidity{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Audit financial transactions and documents{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Reinforce financial data confidentiality and conduct database
                backups when necessary{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Comply with financial policies and regulations{" "}
              </li>
            </ul>
          </>
        ),
        requirements: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>
                Work experience as an AP Accountant from 2 to 5 years{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Excellent knowledge of accounting regulations and procedures,
                including the Generally Accepted Accounting Principles (GAAP)
              </li>
              <li style={{ marginBottom: "5px" }}>
                Advanced MS Excel skills including Vlookups and pivot tables
              </li>
              <li style={{ marginBottom: "5px" }}>
                Experience with general ledger functions
              </li>
              <li style={{ marginBottom: "5px" }}>
                Strong attention to detail and good analytical skills
              </li>
              <li style={{ marginBottom: "5px" }}>
                BSc in Accounting, Finance or relevant degree
              </li>
              <li style={{ marginBottom: "5px" }}>
                Additional certification (CPA or CMA) is a plus{" "}
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 2,
        title: "AR Accountant",
        section: "Accounting",
        jobBrief: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>
                Manage all accounting transactions
              </li>
              <li style={{ marginBottom: "5px" }}>Prepare budget forecasts</li>
              <li style={{ marginBottom: "5px" }}>
                Publish financial statements in time
              </li>
              <li style={{ marginBottom: "5px" }}>
                Reconcile accounts receivables{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Ensure timely bank payments
              </li>
              <li style={{ marginBottom: "5px" }}>
                Compute taxes and prepare tax returns
              </li>
              <li style={{ marginBottom: "5px" }}>
                Manage balance sheets and profit/loss statements{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Report on the company’s financial health and liquidity{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Audit financial transactions and documents{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Reinforce financial data confidentiality and conduct database
                backups when necessary{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Comply with financial policies and regulations{" "}
              </li>
            </ul>
          </>
        ),
        requirements: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>
                Work experience as an AR Accountant from 2 to 5 years{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Excellent knowledge of accounting regulations and procedures,
                including the Generally Accepted Accounting Principles (GAAP)
              </li>
              <li style={{ marginBottom: "5px" }}>
                Advanced MS Excel skills including Vlookups and pivot tables
              </li>
              <li style={{ marginBottom: "5px" }}>
                Experience with general ledger functions
              </li>
              <li style={{ marginBottom: "5px" }}>
                Strong attention to detail and good analytical skills
              </li>
              <li style={{ marginBottom: "5px" }}>
                BSc in Accounting, Finance or relevant degree
              </li>
              <li style={{ marginBottom: "5px" }}>
                Additional certification (CPA or CMA) is a plus{" "}
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 3,
        title: "Sales Representative",
        section: "Sales",
        jobBrief: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>
                Develop and maintain relationships with product vendors and
                suppliers{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Demonstrate different installment programs and packages to
                clients
              </li>
              <li style={{ marginBottom: "5px" }}>
                Provide support to clients from application submission until the
                sale is closed{" "}
              </li>
              <li style={{ marginBottom: "5px" }}>
                Ensure smooth customer experience through coordination with
                relevant departments{" "}
              </li>
            </ul>
          </>
        ),
        requirements: (
          <>
            <ul>
              <li style={{ marginBottom: "5px" }}>Bachelor's degree </li>
              <li style={{ marginBottom: "5px" }}>
                0 - 3 years of experience in sales
              </li>
              <li style={{ marginBottom: "5px" }}>
                Very high communication skills
              </li>
              <li style={{ marginBottom: "5px" }}>
                Ability to work under pressure
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  contact_uae: {
    banner: {
      title: (
        <>
          <b>
            Go Beyond The Borders With Contact!
            <br />
          </b>
          <b>Buy In Egypt, And Pay From UAE</b>
        </>
      ),
      description: (
        <>
          <p>
            Contact has been at the forefront of transforming consumer finance
            in Egypt. By introducing innovative "buy now, pay later" solutions,
            we have empowered countless Egyptians to achieve their dreams and
            manage their finances more effectively. As the nation's leading
            provider of customized financial products for almost 25 years now,
            Contact continues to set the standard for excellence and innovation
            in the industry.
          </p>
          <p>
            We are extending our unparalleled commitment to Egyptians residing
            in the United Arab Emirates. With Contact, you can seamlessly
            purchase anything in Egypt and pay from the UAE, leveraging our
            comprehensive and diversified financing and insurance portfolio.
            Enjoy the convenience and flexibility of managing your financial
            needs across borders with the trusted support of Contact.
          </p>
        </>
      ),
    },
    second_section: {
      title: "We Finance Almost Everything You Might Need!",
      description:
        "Contact offers a wide range of financing and insurance services to cover all your needs in Egypt, including:",
      list: [
        "Auto Financing",
        "Motorcycle Financing",
        "Truck Financing",
        "Home Furniture Financing",
        "Home Interior Financing",
        "Green Finance",
        "Maintenance Financing",
        "Manasik",
        "Wedding and Event Financing",
        "Club Memberships",
        "Education Financing",
        "Shopping",
        "Insurance Services",
        "Watercraft Financing",
      ],
    },
    third_section: {
      title: "Contact Is Near To You In UAE!",
      description:
        "At Contact, we understand the unique needs of Egyptians living in the UAE and are dedicated to providing them with seamless access to our top-notch financial and insurance services. Our goal is to make managing your finances as convenient and stress-free as possible, whether you're in Egypt or the UAE. Here's what you can expect when you choose Contact:",
      list: [
        "Diverse Financing and Insurance Solutions: Enjoy the same wide range of services available in the Egyptian market.",
        "From The Convenience Of Your Home: No need to travel back to Egypt. Apply for our financing or insurance products from the comfort of your home in the UAE.",
        "Speed of Service: Benefit from simple, fast procedures with minimal paperwork.",
        "Flexible Payment Options: Spread the cost of your purchases with our flexible payment plans, giving you more control over your finances.",
        "Dedicated Customer Support: Our committed team of experts provides professional support and guidance throughout your financing and insurance journey, whether you are in Egypt or the UAE.",
      ],
    },
    form: {
      title: "How To Apply?",
      subTitle1:
        "Fill out the form and one of our representatives will contact you",
      subTitle2:
        "Apply now and experience a new era of financial solutions with Contact!",
      name: "Full Name",
      email: "Email Address",
      phone_number: "Phone Number",
      product: "Product",
      product_list_name: "Choose product",
      product_list: [
        { id: 1, product_name: "Auto", value: "auto" },
        { id: 2, product_name: "Trucks", value: "trucks" },
        { id: 3, product_name: "Shopping", value: "shopping" },
        { id: 4, product_name: "Education", value: "education" },
        { id: 5, product_name: "Clubs", value: "clubs" },
        { id: 6, product_name: "Maintenance", value: "maintaince" },
        { id: 7, product_name: "Motorcycles", value: "21" },
        { id: 8, product_name: "Wedding & Events", value: "wedding" },
        { id: 9, product_name: "Mortgage", value: "mortgage" },
        { id: 10, product_name: "Green finance", value: "greenFinance" },
        { id: 11, product_name: "Home furniture", value: "19" },
        { id: 12, product_name: "Home Interior", value: "homes" },
        { id: 13, product_name: "Watercraft", value: "20" },
        { id: 14, product_name: "Manasik", value: "manasik" },
        {
          id: 15,
          product_name: "Insurance Brokerage",
          value: "brokerage",
        },

        ,
      ],
      message: "Message",
      submit: "Send",
    },
    last_section: {
      contact_us: "Contact us",
      list: [
        {
          title: "Telephone",
          description: "Call us for immediate assistance!",
          info: (
            <>
              <a
                href="tel:+971 54 700 4400"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                +971 54 700 4400
              </a>
              <a
                href="tel:+971 50 865 3388"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                +971 50 865 3388{" "}
              </a>
            </>
          ),
          img: "/phone icon UAE.png",
        },
        {
          title: "E-mail",
          description: "Send your inquiry via e-mail for support!",
          info: "Info@contact.eg",
          img: "/email icon UAE.png",
        },

        {
          title: "Location",
          description: "Visit our branch in UAE",
          info: (
            <a
              target="_blank"
              href="https://maps.app.goo.gl/MKMNyBTxV74EuJ2BA?g_st=ic"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              Damac XL Tower Business Bay – Office 905
            </a>
          ),
          img: "/location icon UAE.png",
        },
      ],
    },
  },
  Campaign: {
    Title1: `Thank you for reaching out with Contact!`,
    Title2: `Please fill the form and one of our representatives will contact you shortly`,
    financing_title: "Buy your BMW now with the lowest interest rate in Egypt",
    financing_pg: (
      <>
        <p>
          0% Admin Fees | 4.4% Interest Rate| 3 Years Full Service| 5 years
          Warranty
        </p>
        <p>Apply Now to get your financial loan</p>
      </>
    ),
  },
  // ***********
  navbar_investment: "Investment",
  investment_dropdown: [
    {
      url: "/investment/cash-management",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/cash-mangement.png",
      title: "Cash Management",
    },
    {
      url: "/investment/income-trading",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/income-trading.png",
      title: "Income Trading",
    },
    {
      url: "/investment/investment-fund",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/investment-fund.png",
      title: "Investment Fund",
    },
  ],
  navbar_partnership: "Become a Partner",
  partner_dropdown: [
    {
      url: "/partnership/contact-now-merchant",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/partnership.png",
      title: "ContactNow Merchant",
    },
    {
      url: "/partnership/medical-supplies",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/partnership.png",
      title: "Medical Supplier",
    },
    {
      url: "/partnership/vehicles-and-transportation-vendor",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/partnership.png",
      title: "Vehicles & Transportation Vendor",
    },
    {
      url: "/partnership/other-entities",
      color: colors.ratty,
      img: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/partnership.png",
      title: "Other Entities",
    },
  ],
  business: {
    logo: "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact.png",
    cover_title: "Your Financial Partner",
    cover_body:
      "In the world full of opportunities, Contact plays a vital role into turning your dreams into reality and your bridge to access the capital you need. As a trusted financing partner, we offer a variety of financing options tailored to fit your specific goals and financial aspiration.",
    btn1: "Request service",
    btn2: "Join Us",
    sponsor_title: "Our Partners",
    sponsors: [
      "/bussiness-brands/1.png",
      "/bussiness-brands/2.png",
      "/bussiness-brands/3.png",
      "/bussiness-brands/4.png",
      "/bussiness-brands/5.png",
      "/bussiness-brands/6.png",
      "/bussiness-brands/7.png",
      "/bussiness-brands/8.png",
      "/bussiness-brands/9.png",
      "/bussiness-brands/10.png",
      "/bussiness-brands/11.png",
      "/bussiness-brands/12.png",
      "/bussiness-brands/13-en.png", // fawry
      "/bussiness-brands/14.png",
      "/bussiness-brands/15.png",
      "/bussiness-brands/16-en.png", // basata
      "/bussiness-brands/17-en.png", // jumia
      "/bussiness-brands/18.png", // raya
      "/bussiness-brands/19.png", //Orange
      "/bussiness-brands/20.png", //Vodafone
    ],
    plans_title: "Quis autem vel eum iure reprehenderit qui",
    plans_subtitle: "Tagline",
    plans_description:
      "diam laoreet consectetur libero, elit sollicitudin. felis, ac ipsum In nisi id ullamcorper felis, efficitur. faucibus elit. orci in Ut urna. massa Cras orci",
    btn11: "More",
    btn22: "Follow",
    card_title: "Quis autem vel eum iure reprehenderit qui",
    card_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros.",
    testimonial_title: "Success stories from our partners",
    testimonial_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    reviews_business: [
      {
        avatar: "/business/hossam-avatar.jpg",
        name: "Mr. Hossam Ezz",
        role: "Chief Commercial Officer-Enterprise, Fawry",
        // rating => description
        rating:
          "We are very pleased with our partnership with Contact, which has extended over the past years and has been crowned with many successes, this step represents a consolidation of cooperation between the two sides with the aim of enhancing the values of financial inclusion and advancing the wheel of digital transformation",
      },
      {
        avatar: "/business/adham-avatar.jpg",
        name: "Mr. Adham Hosny",
        role: "Chief Product & Strategy Officer, Carzami",
        // rating => description
        rating:
          "Our remarkable partner, “Contact”, has played a significant role in our success by providing prompt approvals and innovative collaborations. We are privileged to have them as our partners, and we take immense pride in our association with them.",
      },
      {
        avatar: "/business/mahmoud.jpg",
        name: "Mr. Mahmoud El Said",
        role: "Title: Co-Founder & CEO, Wasla Browser",
        // rating => description
        rating:
          "We believe our partnership with “Contact” allows us to continuously expand our offering and introduce new ways to help consumers save money, pay securely, and borrow flexibly",
      },
    ],
    news_title: "Our News",
    news_description: "Main News",
    btn111: "View all",
    accordion: [
      {
        title:
          "How does Contact help businesses overcome financial challenges?",
        description:
          "We empower businesses by providing financing solutions for essential assets and immediate cash flow, we provide you with a wide range of key financial solutions through our Leasing programs, Factoring services, LaaS, and more!",
      },
      {
        title:
          "How does Contact help Individuals to secure their financial & health future, and help to secure corporate employees’ health and future?",
        description:
          "We empower both individuals and businesses to safeguard their health and financial future by offering diversified solutions, including Individual and Group Medical Insurance Plans, Life Insurance Plans, Investment Plans, Retirement Plans, Protection and Savings Coverage & more!",
      },
      {
        title: "How to apply for our products & services?",
        description:
          "You can submit your request through all our channels: Our nationwide Branches, Website, Mobile App, WhatsApp Chatbot or call center 16177.",
      },
      {
        title: "Why choose a financing company over a bank financing?",
        description:
          "Financing companies offer faster procedures and flexible financing terms along with faster approvals!",
      },
    ],
    faqs: "FAQs",
    faqs_description: "Empowering Your Financial Journey!",
  },
  //* sustainability
  sus_t1: "Sustainability",
  sus_b1: `At Contact, sustainability is one of our main concerns; we allocate all the available resources to environmental protection, sustainable development, and social solidarity, such as low-income support programs, women empowerment, and enabling people with disabilities. The urge to support sustainability comes from our responsibility towards our community, as most firms and financial corporations prioritize short-term gains over long-term sustainability.
We focus on integrating sustainability in our decision-making processes through various ways such as green finance, green bonds, sustainable investment practices, corporate social responsibility, and following governmental policies that stimulate sustainable development investments, economic efficiency, and environmental protection.`,
  sus_t2: "SDG Alignment",
  sus_st2: "Our 2023-2025 action plan and alignment with the following SDGs:",
  sus_b2: [
    "Good Health and Well-Being",
    "Quality Education",
    "Decent Work and Economic Growth",
    "Reduced Inequalities",
    "Climate Action",
    "Partnerships for the Goals",
  ],
  sus_img2:
    "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/sus.png",
  sus_report: "View our Sustainability Report for the year 2023",
  sarwa_capital: "/our-brands/sarwa.png",
  sarwa_capital2: "/sarwa-2.png",
  sc_title1: "Sarwa Capital",
  sc_body1: `Tapping debt capital markets (DCM) has long been central to Sarwa’s business model and allowed us to diversify our funding base and successfully refinance our operations since inception. Leveraging this deeply rooted experience in the DCM space, we brought to market a range of structured financing services that have driven us to become Egypt’s largest player in the industry. Sarwa has arranged and issued more than EGP 26 billion in debt issuances since 2005, including the very first securitization bond offering of its kind on the domestic market. Sarwa is also the first licensed sukuk issuer in the Egyptian market.

  Our team has a proven track record of structuring, pricing, placing, and issuing transactions in challenging market circumstances, including issuances with an AA+ rating — the highest rating for a non-sovereign entity in Egypt.
  
  In doing so, we call on long experience in the industry and a network of relationships with non-bank investors, professional services firms, rating agencies and other financial institutions. We also work on an ongoing basis with leading custodian banks to whom we can grant daily access to our systems and database, a factor recognized by rating agencies as distinguishing the issuances we bring to market.
  
  While we have experience across a broad range of asset classes — ranging from auto loans to real estate receivables — we are actively working to expand our expertise to further innovative funding structures.`,
  sc_title2: "Important transactions that we are proud of:",
  sc_subtitle2: "Pioneer in introducing new products to the market:",
  sc_body2: [
    "First securitization in 2005",
    "First real estate backed securitization in 2015",
    "First sukuk mudaraba for a financial institution in 2020",
  ],
  // ************
  other_products: "Other Products",
  viewOnGoogleMaps: "Vist on google maps",
  Readmore: "Read more",
  pages_main_tags: {
    home: {
      title: "Contact - Leading Financing Services in Egypt",
      meta_desc:
        "Explore Contact's range of financing solutions, from auto loans to home financing. Discover how our flexible options support your financial needs with reliable services.",
      h1: "Welcome to Contact",
      h2: "Innovative Financial Solutions for Every Need",
    },
    products: {
      auto: {
        title: "Auto Financing - Contact",
        meta_desc:
          "Discover flexible auto financing options with Contact. Get behind the wheel of your dream car with our tailored financing solutions.",
        h1: "Auto Financing",
        h2: "Flexible Payment Plans for Your Vehicle",
      },
      trucks: {
        title: "Truck Financing - Contact",
        meta_desc:
          "Secure truck financing with Contact. Grow your business with our custom, flexible financing solutions for commercial vehicles.",
        h1: "Truck Financing",
        h2: "Drive Your Business Forward",
      },
      homeInterior: {
        title: "Home Interior Financing - Contact",
        meta_desc:
          "Finance your home interior projects with Contact. Flexible payment options to make your home stylish and comfortable.",
        h1: "Home Interior Financing",
        h2: "Transform Your Home with Flexible Financing",
      },
      homeFurniture: {
        title: "Home Furniture Financing - Contact",
        meta_desc:
          "Get easy financing for your home furniture with Contact. Enjoy flexible installment plans and make your dream home a reality today.",
        h1: "Home Furniture Financing",
        h2: "Flexible Payment Plans for Your Home",
      },
      maintainance: {
        title: "Maintenance Financing - Contact",
        meta_desc:
          "Get financing for home and vehicle maintenance with Contact. Flexible options to keep your assets in shape.",
        h1: "Maintenance Financing",
        h2: "Keep Your Assets in Top Condition",
      },
      mortgage: {
        title: "Mortgage Financing - Contact",
        meta_desc:
          "Secure a mortgage with Contact and move into your dream home. We offer tailored and flexible financing solutions for home buyers.",
        h1: "Mortgage Financing",
        h2: "Achieve Homeownership with Our Mortgage Solutions",
      },
      education: {
        title: "Education Financing - Contact",
        meta_desc:
          "Finance your education with Contact. We offer exclusive payment plans to support your academic journey.",
        h1: "Education Financing",
        h2: "Empower Your Future with Education Financing",
      },
      events: {
        title: "Event Financing - Contact",
        meta_desc:
          "Plan your dream event with financing from Contact. We offer flexible payment solutions for weddings, parties, and more.",
        h1: "Event Financing",
        h2: "Create Memorable Events with Flexible Financing",
      },
      clubs: {
        title: "Club Membership Financing - Contact",
        meta_desc:
          "Enjoy exclusive club memberships with financing from Contact. Exclusive payment plans eligible for almost any club membership.",
        h1: "Club Membership Financing",
        h2: "Join Your Favorite Club with Ease",
      },
      greenFinance: {
        title: "Green Finance - Contact | Sustainable Financing Solutions",
        meta_desc:
          "Go green with financing from Contact. We support eco-friendly and agricultural projects with flexible payment options.",
        h1: "Green Finance",
        h2: "Invest in a Sustainable Future",
      },
      watercraft: {
        title: "Watercraft Financing - Contact",
        meta_desc:
          "Get the best financing deals for your watercraft with Contact. Enjoy the freedom of the sea with affordable, and flexible plans.",
        h1: "Watercraft Financing",
        h2: "Set Sail with Affordable Financing",
      },
      motorcycles: {
        title: "Motorcycle Financing - Contact",
        meta_desc:
          "Finance your motorcycle with Contact. Explore the freedom of the road with our flexible payment plans.",
        h1: "Motorcycle Financing",
        h2: "Accelerate Your Dreams with Motorcycle Financing",
      },
      contactNow: {
        title: "Contact Now Financing - Shop Today, Pay Later",
        meta_desc:
          "Enjoy shopping with Contact Now financing. Buy now and pay later with our easy and flexible installment plans.",
        h1: "Contact Now Financing",
        h2: "Shop Now, Pay Later",
      },
      manasik: {
        title:
          "Manasik | Hajj and Umrah Financing | Shariah-Compliant Payment Plans by Contact",
        meta_desc:
          "Perform Hajj or Umrah with ease using Contact’s Shariah-compliant financing solutions. Flexible installment plans, quick approvals, and a journey of a lifetime await you.",
        h1: "Shariah-Compliant Hajj and Umrah Financing by Contact",
        h2: "Simple Steps to Apply for Hajj or Umrah Financing",
        h3: "Benefits and Conditions of Hajj and Umrah Financing",
      },
      insurance: {
        title: "Insurance Solutions - Contact | Protect What Matters",
        meta_desc:
          "Explore comprehensive insurance plans with Contact. We provide coverage for life, health, and assets.",
      },
      factoring: {
        title: "تخصيم من كونتكت | حلول مالية مبتكرة",
        meta_desc:
          "وفر فلوس وسيولة لاحتياجاتك واحتياجات مشروعك واستفيد من خدمة التخصيم من كونتكت، الحل اللي بيـسـمـحلك تستبدل فواتير مبيعاتك وتاخد فلوسها فـوراً بدون انتظار",
      },
      leasing: {
        title: "تأجير تمويلي من كونتكت | حلول مرنة وميسرة",
        meta_desc:
          "كونتكت هي شركة رائدة في تقديم خدمات التأجير التمويلي لمختلف القطاعات في مصر. خطط سداد متنوعة مصممة لتناسب احتياجاتك.",
      },
    },
    epyament: {
      title: "E-Payment Solutions - Contact | Convenient & Secure Payments",
      meta_desc:
        "Simplify your transactions with Contact's E-Payment solutions. Fast, secure, and convenient payment options for all your needs.",
      h1: "E-Payment Solutions",
      h2: "Convenient & Secure Payment Options",
    },
    investment: {
      title: "Investment Opportunities - Contact | Grow Your Wealth",
      meta_desc:
        "Contact Kol Yom offers a simple, secure, and rewarding way to grow your money daily.",
      h1: "Investment Opportunities",
      h2: "Your Everyday Investment Partner",
    },
    sarwaInsurance: {
      title: "Sarwa Insurance - Contact | Comprehensive Coverage Solutions",
      meta_desc:
        "Protect what matters with Sarwa Insurance. We offer comprehensive, insurance plans tailored to your needs.",
      h1: "Sarwa Insurance",
      h2: "Comprehensive Coverage for Peace of Mind",
    },
    blogs: {
      title: "Financial Tips & Insights - Contact Blog",
      meta_desc:
        "Stay updated with the latest tips, insights, and news on the Contact blog. Learn how to manage your finances better with flexible solutions.",
      h1: "Contact Blog",
      h2: "Latest Financial Insights and Tips",
    },
    contactUs: {
      title: "Contact Us - Get in Touch with Contact",
      meta_desc:
        "Reach out to Contact for any inquiries, support, or information. We're here to help you with all your financing needs.",
      h1: "Get in Touch with Us",
      h2: "We're Here to Assist You",
    },
    login: {
      title: "Login - Access Your Contact Account",
      meta_desc:
        "Log in to your Contact account to manage your services, view statements, and more. Secure, easy access to your financing information.",
      h1: "Login to Your Account",
      h2: "Manage Your Services Online",
    },
    sustainability: {
      title: "Sustainability at Contact - Our Commitment to a Better Future",
      meta_desc:
        "Learn about Contact's commitment to sustainability and how we're supporting a better future.",
      h1: "Sustainability at Contact",
      h2: "Our Commitment to a Greener Tomorrow",
    },
    sarwaLife: {
      title: "Sarwa Life Insurance - Contact | Secure Your Loved Ones",
      meta_desc:
        "Protect your family's future with Sarwa Life Insurance. Get peace of mind with our reliable insurance plans.",
      h1: "Sarwa Life Insurance",
      h2: "Life Insurance That Cares for Your Loved Ones",
    },
    referralProgram: {
      title: "Referral Program - Contact | Earn Rewards by Referring Friends",
      meta_desc:
        "Join Contact's Referral Program and earn rewards by referring friends and family to our services. Simple, easy, and rewarding!",
      h1: "Referral Program",
      h2: "Share the Benefits, Earn Rewards",
    },
    rewards: {
      title: "Rewards Program - Contact | Enjoy Exclusive Benefits",
      meta_desc:
        "Discover Contact's Rewards Program and enjoy exclusive benefits and discounts. Loyalty pays off with Contact's exclusive options!",
      h1: "Rewards Program",
      h2: "Exclusive Benefits for Loyal Customers",
    },
    ourBranches: {
      title: "Our Branches - Contact | Find a Branch Near You",
      meta_desc:
        "Locate the nearest Contact branch and explore our services. We are always close to serve you better with branches allover Egypt.",
      h1: "Our Branches",
      h2: "Find a Contact Branch Near You",
    },
    productsPage: {
      title: "All Products - Contact | Explore Our Full Range of Services",
      meta_desc:
        "Discover the full range of products and services offered by Contact. The best financing services and insurance plans in Egypt, find what you need.",
      h1: "All Products",
      h2: "Explore Our Comprehensive Range of Services",
    },
    companies: {
      title: "Corporate Solutions - Contact | Empower Your Business",
      meta_desc:
        "Discover corporate solutions tailored to your business needs with Contact. Financing, leasing, and more to help your company grow.",
      h1: "Corporate Solutions",
      h2: "Customized Solutions for Your Business",
    },
    partnership: {
      contactNowMerchant: {
        title: "Partner with Contact - Contact Now Merchant Solutions",
        meta_desc:
          "Become a Contact Now Merchant Partner and boost your business with flexible financing options for your customers.",
        h1: "Contact Now Merchant Partnership",
        h2: "Grow Your Business with Contact Now",
      },
    },
    media: {
      title: "Media Center - Contact | Latest News & Updates",
      meta_desc:
        "Stay informed with the latest news, press releases, and updates from Contact's Media Center.",
      h1: "Media Center",
      h2: "Latest News & Updates",
    },
    careers: {
      title: "Careers at Contact Financial – Join Our Growing Team in Egypt",
      meta_desc:
        " Explore career opportunities at Contact Financial, Egypt's leading consumer financing company. Join our expanding team of over 2,500 employees and contribute to our success. Apply now to advance your career with us.",
      h1: "Careers",
      h2: "If you would like to join us.",
    },

    contactUae: {
      title: "Contact-Uae",
      meta_desc: " Buy in egypt & pay from emirates.",
      h1: "ontact - Emirates",
      h2: "Buy in Egypt, and pay from UAE.",
    },
    productsBusiness: {
      factoring: {
        title: "تخصيم من كونتكت | حلول مالية مبتكرة",
        meta_desc:
          "وفر فلوس وسيولة لاحتياجاتك واحتياجات مشروعك واستفيد من خدمة التخصيم من كونتكت، الحل اللي بيـسـمـحلك تستبدل فواتير مبيعاتك وتاخد فلوسها فـوراً بدون انتظار",
        h1: "كن شريك كونتكت",
        h2: "برنامج كونتكت للمتاجر",
      },
      leasing: {
        title: "تأجير تمويلي من كونتكت | حلول مرنة وميسرة",
        meta_desc:
          "كونتكت هي شركة رائدة في تقديم خدمات التأجير التمويلي لمختلف القطاعات في مصر. خطط سداد متنوعة مصممة لتناسب احتياجاتك.",
        h1: "",
        h2: "",
      },
    },
    whyContact: {
      title: "Why Contact",
      meta_desc: "We provide Innovative Financing & Insurance Solutions",
      h1: "welcome to why contact",
      h2: "Innovative Financing & Insurance Solutions",
      navigation_bar: {
        sections: [
          { id: 1, title: "Expertise and Trust", href: "Expertise&Trust" },
          {
            id: 2,
            title: "One Stop Shop",
            href: "One-Stop-Shop",
          },
          {
            id: 3,
            title: "Digital Convenience",
            href: "Financing_Insurance_Products",
          },

          { id: 4, title: "Empowers Your Ambition!", href: "Ambition" },
        ],
        contact_us: "Contact Us",
      },
      header: {
        title: (
          <p>
            We Provide{" "}
            <strong style={{ color: colors.orange }}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  color: colors.orange,
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    border: `3px dashed ${colors.orange}`,
                    borderImageSource: `repeating-linear-gradient(55deg, ${colors.orange} 5px 20px, transparent 10px 30px)`,
                    borderImageSlice: 2,
                    borderRadius: "8px",
                    transform: "skew( 0deg, -1deg )",
                  },
                }}
              >
                INNOVATIVE FINANCING
              </Box>{" "}
              & Insurance
            </strong>{" "}
            Solutions
          </p>
        ),
        btn: "Let's Explore",
        video:
          "https://contact-app-prod.s3.us-east-2.amazonaws.com/contact.eg/Purple+and+Orange+Minimalist+Clean+Animated+Business+Advertising+Mobile+Video.mp4",
      },
      expertise: {
        title: "Expertise & Trust:",
        text: "For over 20  years, Contact has been Egypt’s leading consumer finance company, providing tailored financial solutions designed to support you at every stage of life. Whether you need financing for life’s big moments, comprehensive insurance coverage, or seamless electronic payment services, we deliver fast, secure, and flexible options. Backed by decades of expertise in finance and insurance, we are committed to empowering your financial journey with reliability and innovation.",
        btn: "Discover our products",
        statistic: [
          { id: "1", sufTheous: " K", text: "Customers served", count: 250000 },
          { id: "2", sufTheous: " ", text: "Branches nationwide", count: 78 },
          {
            id: "3",
            sufTheous: " K",
            text: "Largest Merchants’ Network in Egypt",
            count: 18000,
          },
        ],
      },
      services: {
        mainTitle: "One-Stop Shop",
        title: (
          <p>
            <strong>Contact</strong> offers{" "}
            <strong>financing, insurance, and online payments services </strong>{" "}
            in one place, ensuring a seamless and hassle-free experience for
            customers.
          </p>
        ),
        ourServices: [
          {
            id: 1,
            img: "/why-contact/contact-services-en.png",
            text: "Contact",
            color: colors.blue,
          },
          {
            id: 2,
            img: "/why-contact/sarwa-life.png",
            text: "Sarwa Life",
            color: colors.lemon_green,
          },
          {
            id: 3,
            img: "/why-contact/sarwa-insurance.png",
            text: "Sarwa Insurance",
            color: colors.blue,
          },
          {
            id: 4,
            img: "/why-contact/contact-insurance-en.png",
            text: " Contact Insurance Brokerage",
            color: colors.blue,
          },
          {
            id: 5,
            img: "/why-contact/payment.png",
            text: "Bill Payments",
            color: colors.orange,
          },
        ],
      },
      financingInsuranceProducts: {
        title: (
          <p>
            <strong>
              Digital Convenience & Accessibility at Your Fingertip
            </strong>
          </p>
        ),
        products: [
          {
            id: 1,
            img: "/why-contact/mockup-block-1.png",
            title: "Our Website Contact.eg",
            text: "We offer you a different and advanced experience of financial and insurance solutions and services for individuals and companies, whether from your mobile, laptop, or tablet, so that we are always your first choice for financing and insurance.",
            services: [
              {
                id: 1,
                img: "/why-contact/online-services.svg",
                text: "Through our website, you can find all the information you need about our products and financing services",
              },
              {
                id: 2,
                img: "/why-contact/installment.svg",
                text: "You can calculate your installments quickly and easily",
              },
              {
                id: 3,
                img: "/why-contact/save-money.svg",
                text: "You can apply for financing and insurance requests from the comfort of your location",
              },
              {
                id: 4,
                img: "/why-contact/repayment-periods.svg",
                text: "You can track your installments, products, and points through your personal account",
              },
            ],
          },
          {
            id: 2,
            img: "/why-contact/mockup-block-2.png",
            title: "Contact Mobile Application",
            text: "“Contact Now” mobile application allows you to access all the information you need about our financing and insurance products, apply for financing requests from your location, and benefit from exclusive installment offers customized just for you.",
            services: [
              {
                id: 1,
                img: "/why-contact/online-services.svg",
                text: "Instant approval for the credit limit",
              },
              {
                id: 2,
                img: "/why-contact/credit-limits.svg",
                text: "Request a credit limit increase",
              },
              {
                id: 3,
                img: "/why-contact/bills.svg",
                text: "Pay your bills and installments",
              },
              {
                id: 4,
                img: "/why-contact/merchants.svg",
                text: "Explore our merchants’ network",
              },
            ],
          },
          {
            id: 3,
            img: "/why-contact/mockup-block-3.png",
            title: "Contact Insurance Brokerage Mobile Application",
            text: "Contact Insurance Brokerage mobile app is your go-to solution for all insurance needs. It simplifies choosing the right insurance plans—whether personal, family, or commercial—while offering a range of services to enhance your daily life and overall well-being.",
            services: [
              {
                id: 1,
                img: "/why-contact/repayment-periods.svg",
                text: "Submit insurance claims",
              },
              {
                id: 2,
                img: "/why-contact/medical-consultations.svg",
                text: "Get Medical consultations",
              },
              {
                id: 3,
                img: "/why-contact/physical-health.svg",
                text: "Tips that matter to your physical health and Medication reminder",
              },
              {
                id: 4,
                img: "/why-contact/save-money.svg",
                text: "Request insurance services for yourself, your family, or your business",
              },
            ],
          },
          {
            id: 4,
            img: "/why-contact/mockup-block-4.png",
            title: "Contact WhatsApp Chatbot",
            text: "Introducing our WhatsApp Chatbot – your smart assistant for all your consumer finance needs! Whether you're seeking information, exploring options, or looking for quick support with your financial & insurance services, our chatbot is here to help, 24/7. It's fast, easy, and available at your fingertips – simply send us a message on WhatsApp to get started!",

            services: [
              {
                id: 1,
                img: "/why-contact/online-services.svg",
                text: "Quick replies",
              },
              {
                id: 2,
                img: "/why-contact/availability.svg",
                text: "24/7 availability",
              },
              {
                id: 3,
                img: "/why-contact/repayment-periods.svg",
                text: "You can track your installments, products, and points",
              },
              {
                id: 4,
                img: "/why-contact/save-money.svg",
                text: "You can apply for financing and insurance services from the comfort of your location",
              },
            ],
          },
        ],
      },
      ambition: {
        bgImage: "/why-contact/contatct-ambition.png",
        logo: "/why-contact/contact-logo-en.png",
        title: "We Empowers Your Ambition!",
        description:
          "Contact understands your ambitions and helps you achieve your dreams, bringing you closer to provide innovative solutions that suit your needs, not just financing... but a part of your daily life!",
        btn: "Contact us",
      },
    },
  },

  Erroruploadingfile: "Error uploading file try again",
  ErrorNoPhoneNumFile: "Please insert your phone number first",
  connectionError:
    "Network issue detected. Please check your connection and try again.",
};
