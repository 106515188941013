// lib/firebase-config.js
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.NEXT_PUBLIC_FB_APIKEY,
//   authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
//   projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
//   storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
//   messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
//   appId: process.env.NEXT_PUBLIC_FB_APP_ID,
//   measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENTID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyDpTWswpvKEkEXC3KMeWbqLMFwC9JsYmN8",
  authDomain: "contact-financial-holding.firebaseapp.com",
  projectId: "contact-financial-holding",
  storageBucket: "contact-financial-holding.firebasestorage.app",
  messagingSenderId: "709209551172",
  appId: "1:709209551172:web:ad046151876c37a36a2711",
  measurementId: "G-XT0XVRFD9Y",
};

let app;
let messaging;
let analytics;

if (typeof window !== "undefined") {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
  analytics = getAnalytics(app);
}

export { messaging, onMessage, getToken, analytics };
