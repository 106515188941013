import React from "react";
import { styles } from "./flying_card";
import { Box } from "@mui/material";
import { colors } from "../../utils/const";
import Link from "next/link";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import Service from "../Service.jsx";
import { arFont, enFont } from "@/styles/font";
import { isCompany } from "@/utils/generalUrils";
import Image from "next/image";
export default function FlyingCard({
  setAnchorEl,
  setArrDirC,
  type,
  setIsOPenA,
  setIsOPenB,
  setArrDirA,
  setArrDirB,
  setArrDirD,
  setArrDirE,
  setIsOPenC,
  setIsOPenD,
  setIsOPenE,
  isOpen,
}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  return (
    <>
      {type == "a" ? (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "5px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            width: "250px",
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Box
            component="img"
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
            alt="drop down line"
            width="100%"
            height="5px"
            loading="lazy"
          />
          <Box
            sx={{
              p: 2,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              component={Link}
              href="/about"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.about_us}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/contact-us"
              target="_self"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.contact_us}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/media"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.media}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/careers"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.careers}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/contact-uae"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.contact_uae}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/our-brands"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.our_brand}
            </Box>
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href="/why-contact"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.why_contact}
            </Box>
            <Box sx={styles.line}></Box>

            <Box
              component={Link}
              href="/sustainability"
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.sustainability}
            </Box>
            
            <Box sx={styles.line}></Box>
            <Box
              component={Link}
              href={
                t === en
                  ? "https://investorrelations.contact.eg/"
                  : "https://investorrelations.contact.eg/ar"
              }
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={styles.tab}
              style={{}}
            >
              {t.general.menu.investor_relations}
            </Box>
          </Box>
        </Box>
      ) : type == "b" ? (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Image
            src="/line.png"
            alt="drop down line"
            width={110}
            height={5}
            style={{ width: "110%", height: "5px" }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                overflow: "auto",
                p: !isCompany() ? 2 : 1,
                pb: 0,
              }}
            >
              {t.home_page.services.A.filter((item) => {
                if (isCompany()) {
                  return item.id == "10" || item.id == "11";
                } else {
                  return item.id !== "10" && item.id !== "11";
                }
              }).map(({ color, title, description, img, url }, index) => (
                <Service
                  url={url}
                  key={index}
                  color={color}
                  title={title}
                  description={description}
                  img={img}
                  setIsOPenA={setIsOPenA}
                  setArrDirA={setArrDirA}
                  setAnchorEl={setAnchorEl}
                  flag
                  isCompany={isCompany()}
                />
              ))}
            </Box>
            <Box
              sx={{
                padding: isCompany() ? "" : "16px 50px 16px 16px",
              }}
            >
              {t.home_page.services.B.filter((item) => {
                if (isCompany()) {
                  return item.id == "10" || item.id == "11";
                } else {
                  return item.id !== "10" && item.id !== "11";
                }
              }).map(({ color, title, description, img, url }, index) => (
                <Service
                  url={url}
                  key={index}
                  color={color}
                  title={title}
                  description={description}
                  img={img}
                  setIsOPenA={setIsOPenA}
                  setArrDirA={setArrDirA}
                  setAnchorEl={setAnchorEl}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ) : type == "x" ? (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            width: type == "a" ? "300px" : "250px",
            height: type == "a" ? "250px" : "",
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Image
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
            alt="drop down line"
            width={100}
            height={5}
            style={{ width: "100%", height: "5px" }}
          />
          <Box
            sx={{
              p: 2,
              pb: 0,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {t.investment_dropdown.map(({ color, title, img, url }, index) => (
              <Service
                url={url}
                key={index}
                color={color}
                title={title}
                img={img}
                setArrDirD={setArrDirD}
                setIsOPenD={setIsOPenD}
                setAnchorEl={setAnchorEl}
              />
            ))}
          </Box>
        </Box>
      ) : type == "y" ? (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            // borderRadius: "5px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            width: type == "a" ? "300px" : "250px",
            height: type == "a" ? "250px" : "",
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Image
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
            alt="drop down line"
            width={100}
            height={5}
            style={{ width: "100%", height: "5px" }}
          />
          <Box
            sx={{
              p: 2,
              pb: 0,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {t.partner_dropdown.map(({ color, title, img, url }, index) => (
              <Service
                url={url}
                key={index}
                color={color}
                title={title}
                img={img}
                setArrDirE={setArrDirE}
                setIsOPenE={setIsOPenE}
                setAnchorEl={setAnchorEl}
              />
            ))}
          </Box>
        </Box>
      ) : type == "c" ? (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            // borderRadius: "5px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            width: type == "a" ? "300px" : "250px",
            height: type == "a" ? "250px" : "",
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Image
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
            alt="drop down line"
            width={100}
            height={5}
            style={{ width: "100%", height: "5px" }}
          />
          <Box
            sx={{
              p: 2,
              pb: 0,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {t.secondary_nav.insurance_services_dropdown.map(
              ({ color, title, img, url }, index) => (
                <Service
                  url={url}
                  key={index}
                  color={color}
                  title={title}
                  img={img}
                  setArrDirC={setArrDirC}
                  setIsOPenC={setIsOPenC}
                  setAnchorEl={setAnchorEl}
                />
              )
            )}
          </Box>
        </Box>
      ) : (
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            bgcolor: colors.white,
            transition: "1s",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          style={{
            width: "300px",
            overflow: "hidden",
          }}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          <Image
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/line.png"
            alt="drop down line"
            width={100}
            height={5}
            style={{ width: "100%", height: "5px" }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                overflow: "auto",
                p: 1,
                pb: 0,
              }}
            >
              {t.home_page.services.C.map(
                ({ color, title, description, img, url }, index) => (
                  <Service
                    url={url}
                    key={index}
                    color={color}
                    title={title}
                    description={description}
                    img={img}
                    setIsOPenB={setIsOPenB}
                    setArrDirB={setArrDirB}
                    setAnchorEl={setAnchorEl}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
