import React from "react";
import { Box } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
export default function Service({
  img,
  title,
  description,
  color,
  setIsOPenA,
  setIsOPenB,
  setArrDirA,
  setArrDirB,
  setArrDirC,
  setArrDirD,
  setArrDirE,
  setIsOPenC,
  setAnchorEl,
  url,
  setState,
}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  return (
    <Box>
      <Box
        onClick={() => {
          if (setState) {
            setState((prev) => !prev);
          }
          if (setAnchorEl) {
            setAnchorEl(null);
          }
          if (setArrDirC) {
            setArrDirC("down");
          }
          if (setArrDirA) {
            setArrDirA("down");
          }
          if (setArrDirB) {
            setArrDirB("down");
          }
          if (setArrDirD) {
            setArrDirD("down");
          }
          if (setArrDirE) {
            setArrDirE("down");
          }
        }}
        component={Link}
        href={url}
        sx={{
          fontSize: "11px",
          maxWidth: { xs: "90px", md: "170px", lg: "380px" },
          minWidth: { xs: "90px", md: "0px" },
          maxHeight: { xs: "75px", md: "50px" },
          display: "flex",
          flexDirection: "column",
          textAlign: {
            xs: "center",
            md: "initial",
          },
          justifyContent: "flex-start",
          alignItems: { xs: "center", md: "flex-start" },
          borderRadius: "5px",
          gap: { xs: 1, sm: 0 },
          mt:
            img === "/services/bro-en.svg" || img === "/services/bro-ar.svg"
              ? { xs: 1.6, md: 0 }
              : 1.5,
          mb: { xs: 0, md: 4, lg: 3.4 },
          pr: { md: locale === "en" ? 3 : 2 },
          pl: { md: locale === "en" ? 2 : 3 },
          transition: "1s",
          textDecoration: "none",
          "&:hover": {
            pr: 2,
            pl: 3,
            pr: locale === "en" ? 2 : 3,
            pl: locale === "en" ? 3 : 2,
            textDecoration: "none",
          },
        }}
      >
        <img
          src={img}
          loading="lazy"
          style={{
            width:
              img === "/images/life-logo-en.svg" ||
              img === "/images/insurance-logo-en.svg" ||
              img === "/images/life-logo-ar.svg" ||
              img === "/images/insurance-logo-ar.svg"
                ? "30px"
                : img === "/services/bro-en.svg" ||
                  img === "/services/bro-ar.svg"
                ? "45px"
                : "30px",
          }}
        />
        <Box
          sx={{
            color: "#2d317f",
            fontWeight: "bold",
            fontSize: { md: "14px" },
            pt: 0.5,
          }}
        >
          {title}
        </Box>
      </Box>
    </Box>
  );
}
