export const isCompany = () => {
  if (typeof window !== "undefined") {
    const pathname = window.location.pathname;

    const companiesUrlSubstrings = [
      "companies",
      "factoring",
      "leasing",
      "cash-management",
      "income-trading",
      "investment-fund",
      "partnership",
      "buisness-investment",
    ];
    const isCompanyUrl = companiesUrlSubstrings.some((substring) =>
      pathname.includes(substring)
    );

    if (isCompanyUrl) {
      localStorage.setItem("flag", isCompanyUrl);
      return true;
    }

    const sharedSubstrings = [
      "about",
      "contact-us",
      "media",
      "blogs",
      "careers",
      "our-brands",
      "insurance",
      "sarwa-life",
      "sarwa-insurance",
    ];
    const isSharedUrl = sharedSubstrings.some((substring) =>
      pathname.includes(substring)
    );

    if (isSharedUrl) {
      return eval(localStorage.getItem("flag"));
    }

    localStorage.setItem("flag", false);
    return false;
  }
};
export const truncateString = (str, maxLength, suffix = "...") => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength - suffix.length) + suffix;
};

export const userAgent = (isoUrl, androidUrl, webUrl) => {
  if (typeof window !== "undefined") {
    function isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    function isAndroid() {
      return /Android/i.test(navigator.userAgent);
    }

    if (isIOS()) {
      // return "iso";
      return isoUrl;
    } else if (isAndroid()) {
      // return "android";
      return androidUrl;
    } else {
      // return "web";
      return androidUrl;
    }
  }
};

export const renderString = (string, locale) => {
  const findSubstringIndex = (string, substring) => {
    return string.toLowerCase().indexOf(substring.toLowerCase());
  };
  const substring = locale === "en" ? "Kol Yom" : "كل يوم";
  const index = findSubstringIndex(string, substring);
  if (index !== -1) {
    return (
      <div>
        {string.substring(0, index)}
        <span style={{ color: "rgb(241, 130, 34)" }}>
          {string.substring(index, index + substring.length)}
        </span>
        {string.substring(index + substring.length)}
      </div>
    );
  } else {
    return <div>{string}</div>;
  }
};

export const isImgUrl = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
};
