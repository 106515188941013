import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import store from "../Redux/store";
import { Provider } from "react-redux";
import "@/styles/globals.css";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { appWithTranslation } from "next-i18next";
import { useTranslation } from "next-i18next";
import Popover from "@mui/material/Popover";
import AOS from "aos";
import "aos/dist/aos.css";
import Head from "next/head";
import { userAction } from "@/Redux/store";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { arFont, enFont } from "@/styles/font";
import { navigation } from "../utils/const";
const Footer = dynamic(() => import("../components/Footer.jsx"));
const Navbar = dynamic(() => import("../components/Navbar.jsx"));
const UserNavbar = dynamic(() => import("@/components/UserNavbar/UserNavbar"));
import FlyingCard from "@/components/mini-components/FlyingCard";
import Script from "next/script";

import {
  messaging,
  getToken,
  onMessage,
  analytics,
} from "../lib/firebase-config";
import { logEvent } from "firebase/analytics";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const [isOnline, setIsOnline] = useState(() => {
    if (typeof window !== "undefined") {
      return navigator.onLine;
    }
    return true;
  });

  const updateStatus = () => {
    setTimeout(() => {
      setIsOnline(navigator.onLine);
    }, 50);
  };

  useEffect(() => {
    window.addEventListener("online", updateStatus);
    window.addEventListener("offline", updateStatus);

    updateStatus();

    return () => {
      window.removeEventListener("online", updateStatus);
      window.removeEventListener("offline", updateStatus);
    };
  }, []);

  useEffect(() => {
    // Store the last visited page only
    const currentPage = window.location.href;
    const lastVisitedPage = localStorage.getItem("lastVisitedPage");

    if (!lastVisitedPage || !currentPage.includes("no-internet-connection")) {
      localStorage.setItem("lastVisitedPage", currentPage);
    }

    // Redirect to the no-internet-connection page if offline
    if (!isOnline) {
      router.push("/no-internet-connection");
    }
  }, [isOnline, router]);

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && "serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          // Request permission and get token
          const requestPermission = async () => {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
              try {
                const token = await getToken(messaging, {
                  vapidKey: messaging.vapidKey,
                  serviceWorkerRegistration: registration,
                });
                // Send the token to your server and store it for later use
              } catch (error) {}
            } else {
            }
          };

          requestPermission();
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      const date = new Date().toLocaleDateString();
      // Log notification open
      logEvent(analytics, `${payload.notification?.title} ${date} `, {
        notification_id: payload.data?.notification_id || "unknown_id",
        title: payload.notification?.title,
        body: payload.notification?.body,
      });
    });
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: "inherit",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            "@media (min-width:1200px)": {
              fontSize: "16px !important",
            },
            fontWeight: "600 !important",
          },
        },
      },
    },
  });

  const { locale } = router;
  // Update the locale state dynamically based on on router change
  store.dispatch({ type: "lang/update", payload: locale });
  const t = locale === "en" ? en : ar;
  const [arrDirA, setArrDirA] = useState("down");
  const [arrDirB, setArrDirB] = useState("down");
  const [arrDirC, setArrDirC] = useState("down");
  const [arrDirD, setArrDirD] = useState("down");
  const [arrDirE, setArrDirE] = useState("down");
  const [isOpenA, setIsOPenA] = useState(false);
  const [isOpenB, setIsOPenB] = useState(false);
  const [isOpenC, setIsOPenC] = useState(false);
  const [isOpenD, setIsOPenD] = useState(false);
  const [isOpenE, setIsOPenE] = useState(false);

  const [type, setType] = useState("a");
  const [state, setState] = React.useState(false);
  const [leftOrRightValue, setLeftOrRightValue] = useState(-500);
  const [anchorEl, setAnchorEl] = useState(null);

  const matches = useMediaQuery("(max-width:900px)");

  const profPaths =
    router.pathname === "/contract" ||
    router.pathname === "/profile" ||
    router.pathname === "/customerservices";
  const [Login, SetLogin] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 50,
    });
    if (localStorage.getItem("UT_UI")) {
      SetLogin(true);
    } else {
      SetLogin(false);
    }
    localStorage.setItem("bro-to", process.env.NEXT_PUBLIC_BRO_TOKEN);
  }, []);

  const LogOut = () => {
    localStorage.removeItem("UT_UI");
    SetLogin(false);
    store.dispatch(userAction.update({ key: "name", val: "" }));
    store.dispatch(userAction.update({ key: "avatar", val: "" }));
    store.dispatch(userAction.update({ key: "nationalId", val: "" }));
    store.dispatch(userAction.update({ key: "wallet", val: "" }));
  };

  navigation.router = useRouter();

  // useEffect(() => {
  //   if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/firebase-messaging-sw.js")
  //       .then((registration) => {
  //         console.log(
  //           "Service Worker registered with scope:",
  //           registration,
  //           registration.scope
  //         );

  //         // Request permission and get token
  //         const requestPermission = async () => {
  //           const permission = await Notification.requestPermission();
  //           if (permission === "granted") {
  //             try {
  //               const token = await getToken(messaging, {
  //                 vapidKey: messaging.vapidKey,
  //                 serviceWorkerRegistration: registration,
  //               });
  //               console.log("Token:", token);
  //               // Send the token to your server and store it for later use
  //             } catch (error) {
  //               console.error("Error retrieving token:", error);
  //             }
  //           } else {
  //             console.log("Notification permission not granted.");
  //           }
  //         };

  //         requestPermission();
  //       })
  //       .catch((error) => {
  //         console.error("Service Worker registration failed:", error);
  //       });
  //   }
  // }, []);

  // useEffect(() => {
  //   // Optional: Set up onMessage listener for foreground messages
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // Handle the incoming message
  //     // Display the notification or update UI
  //   });
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.svg" />
        </Head>
        <Script
          id="beusable-rum-client"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w, d, a){
                w._beusablerumclient_ = {
                load : function(src){
                var b = d.createElement("script");
                b.src = src; b.async=true; b.type = "text/javascript";
                d.getElementsByTagName("head")[0].appendChild(b);
              }
           };w._beusablerumclient_.load(a + "?url=" + encodeURIComponent(d.URL));
          })(window, document, "//rum.beusable.net/load/b250210e000113u547")`,
        }}
        />
        <Script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-keft8LTm9xYj4IsSIcpRKuGIkouPEOIsFumR+B9L+Eow5xIBDQo58Ka2L9/4gCxE"
          crossOrigin="anonymous"
          strategy="afterInteractive"
        />
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=AW-16652136146"
          strategy="afterInteractive"
        />
        <Script
          id="google-ads-setup"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16652136146');
            `,
          }}
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-TNY34G5WP8');
            `,
          }}
        />
        <Script
          id="hotjar-setup"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3368320,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        <Box
          dir={locale === "en" ? "ltr" : "rtl"}
          className={locale == "en" ? enFont.className : arFont.className}
        >
          {!profPaths ? (
            <Navbar
              arrDirA={arrDirA}
              arrDirB={arrDirB}
              arrDirC={arrDirC}
              arrDirD={arrDirD}
              arrDirE={arrDirE}
              setArrDirA={setArrDirA}
              setArrDirB={setArrDirB}
              setArrDirC={setArrDirC}
              setArrDirD={setArrDirD}
              setArrDirE={setArrDirE}
              leftOrRightValue={leftOrRightValue}
              setLeftOrRightValue={setLeftOrRightValue}
              setIsOPenA={setIsOPenA}
              setIsOPenB={setIsOPenB}
              setIsOPenC={setIsOPenC}
              setIsOPenD={setIsOPenD}
              setIsOPenE={setIsOPenE}
              state={state}
              setState={setState}
              setType={setType}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              Login={Login}
              SetLogin={SetLogin}
              type={type}
              LogOut={LogOut}
            />
          ) : (
            <UserNavbar
              arrDirA={arrDirA}
              setArrDirA={setArrDirA}
              leftOrRightValue={leftOrRightValue}
              setLeftOrRightValue={setLeftOrRightValue}
              setIsOPenA={setIsOPenA}
              state={state}
              setState={setState}
              setType={setType}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              Login={Login}
              SetLogin={SetLogin}
              LogOut={LogOut}
            />
          )}
          {!matches && (
            <>
              <Popover
                sx={{ mt: 1, zIndex: 100000000000000 }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: locale === "en" ? "left" : "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: locale === "en" ? "left" : "right",
                }}
              >
                <FlyingCard
                  setState={setState}
                  setAnchorEl={setAnchorEl}
                  type="a"
                  setIsOPenA={setIsOPenA}
                  setIsOPenB={setIsOPenB}
                  setIsOPenC={setIsOPenC}
                  setIsOPenD={setIsOPenD}
                  setIsOPenE={setIsOPenE}
                  setArrDirA={setArrDirA}
                  setArrDirB={setArrDirB}
                  setArrDirC={setArrDirC}
                  setArrDirD={setArrDirD}
                  setArrDirE={setArrDirE}
                />
              </Popover>
            </>
          )}
          <Component {...pageProps} />
          <Footer />
        </Box>
      </Provider>
    </ThemeProvider>
  );
};

export default appWithTranslation(App);
