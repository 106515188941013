import { createSlice, configureStore } from "@reduxjs/toolkit";

const mainTabSlice = createSlice({
  name: "mainTab",
  initialState: { mainTab: "افراد" },
  reducers: {
    update(state, action) {
      state.mainTab = action.payload;
    },
  },
});
const secTabSlice = createSlice({
  name: "secTab",
  initialState: { secTab: "A" },
  reducers: {
    update(state, action) {
      state.secTab = action.payload;
    },
  },
});

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    leftOrRightVal: -500,
  },
  reducers: {
    updateMenu: (state, action) => {
      state.leftOrRightVal = action.payload;
    },
  },
});

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: null,
  },
  reducers: {
    updateProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

const gifSlice = createSlice({
  name: "gif",
  initialState: { gif: "hi" },
  reducers: {
    update(state, action) {
      state.gif = action.payload;
    },
  },
});

const phoneSlice = createSlice({
  name: "phone",
  initialState: { phone: "" },
  reducers: {
    update(state, action) {
      state.phone = action.payload;
    },
  },
});
const UserDataSlice = createSlice({
  name: "user",
  initialState: {
    activationStatus: "",
    avatar: "",
    email: "",
    id: "",
    isEligible: "",
    name: "",
    nationalId: "",
    refContact: "",
    secondPhone: "",
    wallet: "",
  },
  reducers: {
    update: (state, action) => {
      state[action.payload.key] = action.payload.val;
    },
  },
});
const ContractSlice = createSlice({
  name: "contract",
  initialState: {
    Details: [],
  },
  reducers: {
    update: (state, action) => {
      state[action.payload.key] = action.payload.val;
    },
  },
});

const ContractDetailsSlice = createSlice({
  name: "contractDetails",
  initialState: {
    paymobCreditCardFees: "",
    nextInstallmentAmount: "",
    feesRate: "",
    feesFlat: "",
    feesCollected: "",
  },
  reducers: {
    update: (state, action) => {
      state[action.payload.key] = action.payload.val;
    },
  },
});

const UserGeoDataSlice = createSlice({
  name: "geo",
  initialState: {
    data: {},
  },
  reducers: {
    update: (state, action) => {
      state.data = action.payload;
    },
  },
});

const localeSlice = createSlice({
  name: "lang",
  initialState: {
    locale: "",
  },
  reducers: {
    update: (state, action) => {
      state.locale = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    mainTab: mainTabSlice.reducer,
    secTab: secTabSlice.reducer,
    menu: menuSlice.reducer,
    products: productsSlice.reducer,
    gif: gifSlice.reducer,
    phone: phoneSlice.reducer,
    user: UserDataSlice.reducer,
    contract: ContractSlice.reducer,
    contractDetails: ContractDetailsSlice.reducer,
    userGeo: UserGeoDataSlice.reducer,
    lang: localeSlice.reducer,
  },
});

export const mainTabActions = mainTabSlice.actions;
export const secTabActions = secTabSlice.actions;
export const { updateMenu } = menuSlice.actions;
export const { updateProducts } = productsSlice.actions;
export const gifActions = gifSlice.actions;
export const phoneActions = phoneSlice.actions;
export const userAction = UserDataSlice.actions;
export const ContractAction = ContractSlice.actions;
export const contractDetailsAction = ContractDetailsSlice.actions;
export const userGeoAction = UserGeoDataSlice.actions;
export const localAction = localeSlice.actions;
export default store;
